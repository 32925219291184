import { faMagnifyingGlass, faRotate, faXmark, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import base64 from "base-64";
import { QRCodeCanvas } from "qrcode.react";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeWalletPage, checkSelectType, check_Error, } from "../../../store";
import Page from "../../Page";
import { UTCTimestamp, padZero, } from "../../common/function";
import "../../css/wallet.css";
import IMG from "../../img";

function OKX_Deposit({ darkname, walletData, fastCoin }) {
    const { t } = useTranslation();

    let dispatch = useDispatch();

    const [isToggled1, setIsToggled1] = useState(false);
    const [search, setSearch] = useState(fastCoin);
    const [selectSearch, setSelectSearch] = useState(fastCoin);
    const [selectCoin, SetSelectCoin] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [modalbtn, setModalbtn] = useState(false);
    const [currency, setCurrency] = useState([]);
    const [dhistory, setDhistory] = useState([]);
    const [tab, setTab] = useState(false);
    const [resetHistory, setResetHistory] = useState(false);
    const [selectnetwork, setSelectnetwork] = useState([]);
    const [okxuid, setOkxuid] = useState("");

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        let arr = selectCoin.filter((item) => item.chain === event.target.value);
        setSelectnetwork(arr);
    };

    const toggleButton1 = () => {
        // setIsToggled1(!isToggled1);
    };

    const onChangeSearch = (e) => {
        setSearch(e.target.value);
    };

    let BS_filterSymbol = [];
    if (walletData) {
        BS_filterSymbol = walletData.filter((p) => {
            return (
                p.symbol.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            );
        });
    }

    useEffect(() => {
        const objString = localStorage.getItem("token");
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            DepositLink(token);
            DepositCurrency(token);
        }
    }, [selectSearch]);

    useEffect(() => {
        const objString = localStorage.getItem("token");
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            DepositHistory(token);
        }
    }, [resetHistory]);

    const DepositLink = async (token) => {
        if (selectSearch && selectSearch.length > 0) {
            await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/okx/deposit-address`, {
                params: {
                    ccy: selectSearch,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                let tmp = response.data.data;
                if (tmp) {
                    SetSelectCoin(tmp);
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 136, "msg": error }));
            });
        }
    };

    const DepositCurrency = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/okx/currencies`, {
            params: {
                ccy: selectSearch,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            let tmp = response.data.data;
            if (tmp) {
                setCurrency(tmp);
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 137, "msg": error }));
        });
    };

    const DepositHistory = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/okx/deposit-history`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            let tmp = response.data.data;
            if (tmp) {
                setDhistory(tmp);
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 138, "msg": error }));
        });
    };

    useEffect(() => {
        const objString = localStorage.getItem("token");
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(
                token.indexOf(".") + 1,
                token.lastIndexOf(".")
            );
            let dec = JSON.parse(base64.decode(payload));

            setOkxuid(dec.okxUid);
        }
    }, []);

    const [page, setPage] = useState(1);
    let filter_dhistory = dhistory.slice((page - 1) * 10, page * 10);

    return (
        <div className="history-right">
            <div className="history-right-top">
                <div className="history-right-top-1">{t("header_31")}</div>
                <p className="history-right-top-2">{t("header_32")}</p>
            </div>
            <div className="wallet-right-form">
                <div className="wallet-right-form-1">
                    <div className="wallet-right-form-1-box">
                        <div className="wallet-right-form-1-box-1">
                            <p>{t("wallet_10")}</p>
                            <div className={`wallet-right-form-1-box-1-label ${isToggled1 ? "toggled" : ""} ${darkname}`} onClick={toggleButton1}>
                                <div className="wallet-right-form-1-box-1-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToggled1
                        ?
                        <div className="wallet-right-form-3">
                            <div className="wallet-right-form-3-1">{t("wallet_10")}</div>
                            <div className="wallet-right-form-3-2">
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_20")}</p>
                                    <p className="wallet-right-form-3-2-3">
                                        1. {t("wallet_21")}
                                        <br />
                                        2. {t("wallet_22")}
                                        <br />
                                        3. {t("wallet_23")}
                                        <br />
                                        4. {t("wallet_24")}
                                    </p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_26")}</p>
                                    <p className="wallet-right-form-3-2-3">{t("wallet_27")}</p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_28")}</p>
                                    <p className="wallet-right-form-3-2-3">{t("wallet_29")}</p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_30")}</p>
                                    <p className="wallet-right-form-3-2-3">
                                        {t("wallet_31")} <br />
                                        1. {t("wallet_32")}
                                        <br />
                                        2. {t("wallet_33")}
                                        <br />
                                        <br />
                                        {t("wallet_34")}
                                        <br />- {t("wallet_35")}
                                        <br />- {t("wallet_36")}
                                        <br />- {t("wallet_37")}
                                    </p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_38")}</p>
                                    <p className="wallet-right-form-3-2-3">{t("wallet_39")}</p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_40")}</p>
                                    <p className="wallet-right-form-3-2-3">{t("wallet_41")}</p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_42")}</p>
                                    <p className="wallet-right-form-3-2-3">{t("wallet_43")}</p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_44")}</p>
                                    <p className="wallet-right-form-3-2-3">{t("wallet_45")}</p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_46")}</p>
                                    <p className="wallet-right-form-3-2-3">{t("wallet_47")}</p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_48")}</p>
                                    <p className="wallet-right-form-3-2-3">{t("wallet_49")}</p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className="wallet-right-form-3-2-2">{t("wallet_50")}</p>
                                    <p className="wallet-right-form-3-2-3">{t("wallet_51")}</p>
                                    <div className="wallet-right-form-3-2-4">
                                        <div className="wallet-right-form-3-2-5">
                                            {t("wallet_25")}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className="wallet-right-form-4-1">{t("wallet_52")}</div>
                    <div className="wallet-right-form-4-2">
                        <div className={`wallet-right-form-4-3 ${darkname}`} onClick={() => setTab((e) => !e)}>{t("exchange_1")}</div>
                        {
                            tab
                                ?
                                <>
                                    <div className="header-modal" onClick={() => setTab(false)}></div>
                                    <div className={`wallet-right-form-4-3-box ${darkname}`}>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(1)); dispatch(changeWalletPage(1)); setTab(false); }}>{t("exchange_0")}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(0)); dispatch(changeWalletPage(0)); setTab(false); }}>{t("exchange_1")}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(2)); dispatch(changeWalletPage(2)); setTab(false); }}>{t('exchange_2')}</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>
                </div>

                <p className="wallet-right-form-5">
                    {t("wallet_239")}
                    <br />
                    {t("wallet_240")} <span style={{ fontWeight: "bold" }}>{okxuid}</span>
                </p>

                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className="wallet-right-form-4-1">{t("wallet_53")}</div>
                    <div className="wallet-right-form-4-2" onClick={() => { setModalbtn(true); setSearch(""); }}>
                        <input type="text" className={`wallet-right-form-4-3 ${darkname}`} placeholder={`${t("wallet_54")}`} value={search} onChange={onChangeSearch} style={{ textTransform: "uppercase" }} />
                    </div>
                    {
                        modalbtn
                            ?
                            <div className="wallet-right-bottom1-modal">
                                <div className="wallet-right-bottom1-modal-1">
                                    <div className={`wallet-right-bottom1-modal-1-1 ${darkname}`}>
                                        <div className="wallet-right-bottom1-modal-1-1-tit">
                                            {t("wallet_55")}
                                            <FontAwesomeIcon icon={faXmark} onClick={() => { setSearch(""); setModalbtn(false) }} />
                                        </div>
                                        <div className={`wallet-right-bottom1-modal-1-1-wrap ${darkname}`}>
                                            <div className={`wallet-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                <input placeholder={`${t("wallet_75")}`} onChange={onChangeSearch} value={search} style={{ textTransform: "uppercase" }} />
                                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                            </div>
                                            <div className="wallet-right-bottom1-modal-1-2">
                                                {
                                                    BS_filterSymbol.map(function (a, i) {
                                                        let imgcheck = true;
                                                        if (IMG && !IMG.includes(a.symbol)) {
                                                            imgcheck = false;
                                                        }

                                                        return (
                                                            <div className="wallet-right-bottom1-modal-1-2-1" onClick={() => { setSelectSearch(a.symbol); setSearch(a.symbol); setModalbtn(false); }} key={i}>
                                                                <div className="wallet-right-bottom1-modal-1-2-2">
                                                                    <div className="wallet-right-bottom1-modal-1-2-img">
                                                                        <img src={imgcheck ? `/img/coin/${a.symbol}.png` : `/img/coin/origin.png`} /></div>
                                                                    <div className="wallet-right-bottom1-modal-1-2-name">
                                                                        <p>{a.symbol}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                    {/* <br />
                    <span style={{ fontSize: "14px" }}>Easy Quick Choice</span>
                    <div className='wallet-right-form-4-4'>
                        <div className='wallet-right-form-4-5'>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("USDT"); setSelectSearch("USDT") }}>USDT</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("BTC"); setSelectSearch("BTC") }}>BTC</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("ETH"); setSelectSearch("ETH") }}>ETH</div>
                        </div>
                        <div className='wallet-right-form-4-5'>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("XRP"); setSelectSearch("XRP") }}>XRP</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("TRX"); setSelectSearch("TRX") }}>TRX</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("EOS"); setSelectSearch("EOS") }}>EOS</div>
                        </div>
                    </div> */}
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className="wallet-right-form-4-1">{t("wallet_57")}</div>
                    <div className="wallet-right-form-4-2">
                        <div className={`wallet-right-form-4-3-1 ${darkname}`}>
                            <select value={selectedOption} onChange={handleSelectChange}>
                                <option value="">{t("wallet_58")}</option>
                                {
                                    selectCoin.map((option, index) => (
                                        <option key={index} value={option.chain}>
                                            {option.chain}
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <p className="wallet-right-form-5">
                    {t("wallet_59")}
                    <br />
                    {t("wallet_60")}
                </p>
                <div className="wallet-right-form-6">
                    <div className={`wallet-right-form-4 ${darkname}`}>
                        <div className="wallet-right-form-4-1">{t("wallet_61")}</div>
                        {
                            selectnetwork && selectnetwork.length > 0
                                ?
                                <>
                                    <div className="wallet-right-form-4-7">
                                        <div className="wallet-right-form-4-7-1">
                                            <QRCodeCanvas value={selectnetwork[0].addr} />
                                        </div>
                                    </div>
                                    <div className="wallet-right-form-4-8">
                                        <div>{selectnetwork[0].addr}</div>
                                        <CopyToClipboard text={selectnetwork[0].addr}>
                                            <text className="wallet-right-form-4-8-1" onClick={() => alert(`${t("wallet_62")}`)}>{t("wallet_62")}</text>
                                        </CopyToClipboard>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="wallet-right-form-4-7">
                                        <div className="wallet-right-form-4-7-1">
                                            <div style={{ height: "128px" }}></div>
                                        </div>
                                    </div>
                                    <div className="wallet-right-form-4-8">
                                        <div className="wallet-right-form-4-8-1">{t("wallet_62")}</div>
                                    </div>
                                </>
                        }
                    </div>
                    <div className={`wallet-right-form-4 ${darkname}`}>
                        <div className="wallet-right-form-4-1">{t("wallet_63")}</div>
                        {
                            selectnetwork && selectnetwork.length > 0
                                ?
                                selectnetwork[0].tag
                                    ?
                                    <>
                                        <div className="wallet-right-form-4-7">
                                            <div className="wallet-right-form-4-7-1">
                                                <QRCodeCanvas value={selectnetwork[0].tag} />
                                            </div>
                                        </div>
                                        <div className="wallet-right-form-4-8">
                                            <div>{selectnetwork[0].tag}</div>
                                            <CopyToClipboard text={selectnetwork[0].tag}>
                                                <text className="wallet-right-form-4-8-1" onClick={() => alert(`${t("wallet_62")}`)}>{t("wallet_62")}</text>
                                            </CopyToClipboard>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="wallet-right-form-4-7-no">
                                            <div className="wallet-right-form-4-7-1-no">
                                                <div>{t("wallet_64")}</div>
                                            </div>
                                        </div>
                                        <div className="wallet-right-form-4-8">
                                            <div className="wallet-right-form-4-8-1">{t("wallet_62")}</div>
                                        </div>
                                    </>

                                :
                                <>
                                    <div className="wallet-right-form-4-7-no">
                                        <div className="wallet-right-form-4-7-1-no">
                                            <div>{t("wallet_64")}</div>
                                        </div>
                                    </div>
                                    <div className="wallet-right-form-4-8">
                                        <div className="wallet-right-form-4-8-1">{t("wallet_62")}</div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`} style={{ display: "flex", alignItems: "center" }}>
                    <div className="wallet-right-form-4-1">{t("wallet_65")}</div>
                    {
                        currency.map(function (a, i) {
                            if (a.chain == selectedOption) {
                                return <div>{a.minDepArrivalConfirm}</div>;
                            }
                        })
                    }
                </div>
                <div className="wallet-right-form-7">
                    <div className="wallet-right-form-7-1">{t("wallet_67")}</div>
                    <div className="wallet-right-form-7-2"><FontAwesomeIcon icon={faRotate} onClick={() => setResetHistory((e) => !e)} />
                    </div>
                    <div className="wallet-right-form-7-3">
                        <div className={`wallet-right-form-7-3-top ${darkname}`}>
                            <div className="wallet-right-form-7-3-top-1">
                                <div className="wallet-right-form-7-3-top-2">{t("wallet_68")}</div>
                                <div className="wallet-right-form-7-3-top-2">{t("wallet_69")}</div>
                            </div>
                            <div className="wallet-right-form-7-3-top-1">
                                <div className="wallet-right-form-7-3-top-2">{t("wallet_70")}</div>
                                <div className="wallet-right-form-7-3-top-2">{t("wallet_71")}</div>
                            </div>
                            <div className="wallet-right-form-7-3-top-3">
                                <div className="wallet-right-form-7-3-top-2">{t("wallet_72")}</div>
                                <div className="wallet-right-form-7-3-top-2">{t("wallet_73")}</div>
                            </div>
                            <div className="wallet-right-form-7-3-top-4 wallet-display-none-800">{t("wallet_74")}</div>
                        </div>
                        <div className="wallet-right-form-7-3-bottom">
                            {
                                dhistory && dhistory.length === 0
                                    ?
                                    <div className="history-right-bottom-4-bottom-1">
                                        <img src="/img/img_nofound.png"></img>
                                        <img src="/img/img_nofound_dark.svg" style={{ display: "none" }}></img>
                                        <p>{t("message_0")}</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            filter_dhistory.map(function (a, i) {
                                                return <DepositItem key={i} a={a} darkname={darkname} />;
                                            })
                                        }
                                        <Page initialData={dhistory} page={page} setPage={setPage} maxFirstPage={10} />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function DepositItem({ a, darkname }) {
    const { t } = useTranslation();
    const [state, setState] = useState("");
    const date = UTCTimestamp(a.ts);
    const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())} ${padZero(date.getHours())}:${padZero(date.getMinutes())}:${padZero(date.getSeconds())}`;

    const [mouseState, setMouseState] = useState(false);
    const [mouseState1, setMouseState1] = useState(false);
    const [mouseState2, setMouseState2] = useState(false);

    const onMouseEnter = () => {
        setMouseState(true);
    };
    const onMouseLeave = () => {
        setMouseState(false);
    };
    const onMouseEnter1 = () => {
        setMouseState1(true);
    };
    const onMouseLeave1 = () => {
        setMouseState1(false);
    };
    const onMouseEnter2 = () => {
        setMouseState2(true);
    };
    const onMouseLeave2 = () => {
        setMouseState2(false);
    };

    useEffect(() => {
        let tmp = a.state;
        if (tmp) {
            if (Number(tmp) === 0) setState("waiting for confirmation");
            else if (Number(tmp) === 1) setState("deposit credited");
            else if (Number(tmp) === 2) setState("Completed");
            else if (Number(tmp) === 8) setState("pending due to temporary deposit suspension on this crypto currency");
            else if (Number(tmp) === 11) setState("match the address blacklist");
            else if (Number(tmp) === 12) setState("account or deposit is frozen");
            else if (Number(tmp) === 13) setState("sub-account deposit interception");
            else if (Number(tmp) === 14) setState("KYC limit");
        }
    }, [a]);

    return (
        <div className={`wallet-right-form-7-3-bottom-1 ${darkname}`}>
            <div className="wallet-right-form-7-3-bottom-2">
                <div className="wallet-right-form-7-3-bottom-3">{formattedDate}</div>
                <div className="wallet-right-form-7-3-bottom-3">{t("exchange_1")}</div>
            </div>
            <div className="wallet-right-form-7-3-bottom-2">
                <div className="wallet-right-form-7-3-bottom-3" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ cursor: "pointer" }}>
                    {a.txId}
                    {
                        mouseState
                            ?
                            <div className={`wallet-right-form-7-3-bottom-3-1 ${darkname}`}>{a.txId}</div>
                            :
                            ""
                    }
                </div>
                <div className="wallet-right-form-7-3-bottom-3">{a.amt}</div>
            </div>
            <div className="wallet-right-form-7-3-bottom-4">
                <div className="wallet-right-form-7-3-bottom-3">{a.ccy}</div>
                <div className="wallet-right-form-7-3-bottom-3" onMouseEnter={onMouseEnter1} onMouseLeave={onMouseLeave1} style={{ cursor: "pointer" }}>
                    {a.chain}
                    {
                        mouseState1
                            ?
                            <div className={`wallet-right-form-7-3-bottom-3-1 ${darkname}`}>{a.chain}</div>
                            :
                            ""
                    }
                </div>
            </div>
            <div className="wallet-right-form-7-3-top-5 wallet-display-none-800" onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2} style={{ cursor: "pointer" }}>
                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", }}>
                    {state}
                </div>
                {
                    mouseState2
                        ?
                        <div className={`wallet-right-form-7-3-bottom-3-1 ${darkname}`}>{state}</div>
                        :
                        ""
                }
            </div>
        </div>
    );
}

export default OKX_Deposit;

import './css/wallet.css'
import axios from 'axios';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Country from './country'
import { useTranslation } from "react-i18next";
import { checkLogin, check_Error } from '../store';

function ForgotPassword_A() {
    const { t } = useTranslation();
    let dispatch = useDispatch();
    const navigate = useNavigate();

    let dark = useSelector((state) => { return state.Darkmode.value });
    
    const darkname = dark ? 'dark' : "";

    const [btn, setBtn] = useState(0)
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('82');
    const [tel, setTel] = useState('')

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
                navigate('/')
            }
        }
        else {
            dispatch(checkLogin(false));
        }
    }, [])

    const onChangeEmail = (e) => {
        const { value } = e.target
        const onlyNumber = value
        setEmail(onlyNumber);
    }

    const onChangePhone = (e) => {
        setPhone(e.target.value);
    }

    const onChangeTel = (e) => {
        const { value } = e.target
        let onlyNumber = value.replace(/[^0-9]/g, '');
        if (onlyNumber && onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
            onlyNumber = onlyNumber.substring(1);
        }
        setTel(onlyNumber);
    }

    const onSubmit = async () => {
        await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/reset/code", {
            params: {
                email: btn === 0 ? email : "",
                countryCode: btn === 0 ? "" : phone,
                mobile: btn === 0 ? "" : tel
            }
        }).then((response) => {
            if (response?.data?.code === 1000) {
                navigate('/forgotPassword_B', {
                    state: {
                        btn: btn,
                        email: btn === 0 ? email : "",
                        phone: btn === 0 ? "" : phone,
                        tel: btn === 0 ? "" : tel
                    }
                })
            }
            else {
                alert(response?.data?.msg)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 152, "msg": error }))
        })
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSubmit()
        }
    };

    const inputRef = useRef(null);
    useEffect(() => {
        document.title = `Bitket│One-Stop Trading Platform - Crypto Trading Universe`;
        inputRef.current.focus();
    }, []);

    return (
        <div className={`otp ${darkname}`}>
            <div className={`otp-1 ${darkname}`}>
                <div className='otp-2'>
                    <div className='otp-h4'>{t('password_1')}</div>
                    <div className='otp-2-wrap'>
                        <p className='otp-2-wrap-p'>
                            <ul className='regist-box-left-ul'>
                                <li className={btn === 0 ? 'current regist-box-left-li' : 'regist-box-left-li'} onClick={() => setBtn(0)}>{t('login_1')}</li>
                                <li className={btn === 1 ? 'current regist-box-left-li' : 'regist-box-left-li'} onClick={() => setBtn(1)}>{t('login_2')}</li>
                            </ul>
                        </p>
                        <br />
                        <div className={`otp-2-3 ${darkname}`}>
                            {
                                btn === 0
                                    ?
                                    <input className='regist-box-left-form-1-input' type='text' placeholder={`${t('login_3')}`} value={email} onChange={onChangeEmail} onKeyDown={handleKeyPress} ref={inputRef} />
                                    :
                                    <>
                                        <div className='regist-box-left-form-country'>
                                            <input type="search" className='regist-box-left-form-country-input' list="list" autoComplete="on" value={phone} onChange={onChangePhone} />
                                            <datalist id="list">
                                                {
                                                    Country.map(function (a, i) {
                                                        return (
                                                            <option value={a.value}>{a.name} ({a.value})</option>
                                                        )
                                                    })
                                                }
                                            </datalist>
                                        </div>
                                        <input className='regist-box-left-form-1-input' type='text' placeholder={`${t('login_4')}`} value={tel} onChange={onChangeTel} onKeyDown={handleKeyPress} ref={inputRef} />
                                    </>
                            }
                        </div>
                    </div>
                    <div className='otp-2-5'>
                        <div className='otp-2-5-btn' onClick={() => onSubmit()}>{t('otp_10')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword_A;
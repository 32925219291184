import '../../css/wallet.css'
import axios from 'axios';
import Page from '../../Page';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faCircleArrowRight, faEllipsis, faXmark, faMagnifyingGlass, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux'
import { check_Error } from '../../../store';
import base64 from 'base-64';
import Message from '../../message/message';
import Wallet_Message from '../../message/wallet_message';
import { SyncLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { findSamllest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "../../common/function"


let coinListReci = ["Bybit", "Binance", "OKX"];

function Bybit_QuickWithdrawal({ darkname, spotBalance, reload, setReload }) {
    

    let Binance_wallet_futures = useSelector((state) => { return state.Binance_wallet_futures });
    let OKX_wallet_usdt = useSelector((state) => { return state.OKX_wallet_usdt; });
    let Bybit_wallet_usdt = useSelector((state) => { return state.Bybit_wallet_usdt });

    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [isToggled1, setIsToggled1] = useState(false);
    const [amount, setAmount] = useState("")
    const [resetHistory, setResetHistory] = useState(false)
    const [selectedSend, setSelectedSend] = useState("Bybit");
    const [selectedReci, setSelectedReci] = useState("Binance");

    const [Bn_selectCoin, SetBn_selectCoin] = useState("")
    const [okx_selectCoin, SetOKX_SelectCoin] = useState("")
    const [By_selectCoin, SetBy_SelectCoin] = useState("")
    const [quickHistory, setQuickHistory] = useState([])

    const [spinner, setSpinner] = useState(false)
    const [message, setMessage] = useState([])

    const [code, setCode] = useState(['', '', '', '', '', '']); // 초기값으로 6개의 빈 문자열을 가진 배열 생성

    let coinList = ["Bybit", "Binance", "OKX"]

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            // 다음 입력란으로 이동
            if (index < code.length - 1 && value) {
                const nextInput = e.target.parentNode.nextSibling.querySelector('input');
                if (nextInput) {
                    nextInput.focus();
                }
            }

            // 이전 입력란으로 이동
            if (index > 0 && !value) {
                const prevInput = e.target.parentNode.previousSibling.querySelector('input');
                if (prevInput) {
                    prevInput.focus();
                }
            }
        }
    };

    const handleSelectSend = (event) => {
        setSelectedSend(event.target.value);
        coinListReci = coinList.filter((item) => item != event.target.value)
        setSelectedReci(coinListReci[0]);
    };

    const handleSelectReci = (event) => {
        setSelectedReci(event.target.value);
    };

    const toggleButton1 = () => {
        // setIsToggled1(!isToggled1);
    };

    const onChangeAmount = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, "")
        setAmount(onlyNumber)
    }

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(token.indexOf('.') + 1, token.lastIndexOf('.'));
            let dec = base64.decode(payload);

            Bn_DepositLink(token)
            OKX_DepositLink(token)
            By_DepositLink(token)
        }
    }, [reload])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            QuickWithdrawalHistory(token)
        }
    }, [reload, resetHistory])

    const Bn_DepositLink = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/bn/address`, {
            params: {
                coin: "USDT",
                network: "TRX",
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response?.data?.data
            if (tmp) {
                SetBn_selectCoin(tmp.address)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 87, "msg": error }))
        })
    }

    const OKX_DepositLink = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/wallet/okx/deposit-address`, {
            params: {
                ccy: "USDT"
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response?.data?.data
            if (tmp && tmp[1]) {
                SetOKX_SelectCoin(tmp[6].addr)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 88, "msg": error }))
        })
    }

    const By_DepositLink = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1` + '/wallet/bybit/deposit/address', {
            params: {
                coin: "USDT",
                chainType: "TRX"
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            let tmp = response.data
            if (tmp.code === 1000) {
                SetBy_SelectCoin(tmp?.data?.result?.chains.addressDeposit)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 89, "msg": error }))
        })
    }

    const QuickWithdrawalHistory = async (token) => {
        await axios.get(process.env.REACT_APP_API + '/api/v1/wallet/cm/quick-withdrawal', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data
            if (tmp) {
                if (tmp.code >= 1000) {
                    setQuickHistory(tmp.data)
                }
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 90, "msg": error }))
        })
    }

    const CheckGoogleOTP = async () => {
        let objString = localStorage.getItem('token');
        if (objString != null) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setSpinner(true)

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/verify/otp", {
                params: { otp: code.join('') },
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (response?.data?.code > 0) {
                    onQuickWithdraw()
                }
                else {
                    console.log(response.data)
                    alert(response?.data?.msg)
                    setW_confirm(false)
                    setSpinner(false);
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 91, "msg": error }))
            })
        }
    }

    const onQuickWithdraw = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(process.env.REACT_APP_API + `/api/v1/wallet/cm/quick-withdrawal`, {
                amount: amount,
                fromEx: selectedSend == "Binance" ? "bn" : selectedSend == "OKX" ? "okx" : "bybit",
                toEx: selectedReci == "Binance" ? "bn" : selectedReci == "OKX" ? "okx" : "bybit",
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setTimeout(() => {
                        setSpinner(false);
                        setReload(e => !e)
                        setAmount("")
                        setMessage({ type: 0, code: Number(response?.data?.code), msg: `${t('message_20')}` })
                    }, 2000);
                }
                else {
                    setTimeout(() => {
                        console.log(response.data)
                        setSpinner(false);
                        setMessage({ type: 0, code: Number(response?.data?.code), msg: response?.data?.msg })
                    }, 1000);
                }
                setW_confirm(false)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 92, "msg": error }))
            })
        }
    }

    const [w_message, setW_message] = useState([])
    const [buyModal, setBuyModal] = useState(false);
    const [w_confirm, setW_confirm] = useState(false)

    useEffect(() => {
        if (w_confirm) {
            setBuyModal(false)
            CheckGoogleOTP()
        }
    }, [w_confirm])

    const Check_Modal = () => {
        let mes = []
        // mes.push(`From: ${selectedSend}`)
        // mes.push(`To: ${selectedReci}`)
        // mes.push(`Amount: ${amount}`)

        setW_message({ code: 0, msg: mes })
    }

    const [page, setPage] = useState(1)
    let filter_quickHistory = quickHistory.slice((page - 1) * 10, (page) * 10)

    return (
        <div className='history-right'>
            <Message message={message} />
            <Wallet_Message message={w_message} buyModal={buyModal} setBuyModal={setBuyModal} setW_confirm={setW_confirm} />
            {
                spinner
                    ?
                    <div className='dashboard-right-bottom1-modal'>
                        <SyncLoader color={"#F5AC1D"} />
                    </div>
                    :
                    ""
            }
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_41')}</div>
                <p className='history-right-top-2'>{t('header_42')}</p>
            </div>
            <div className='wallet-right-form'>
                <div className='wallet-right-form-1'>
                    <div className='wallet-right-form-1-box'>
                        <div className='wallet-right-form-1-box-1'>
                            <p>{t('wallet_10')}</p>
                            <div className={`wallet-right-form-1-box-1-label ${isToggled1 ? 'toggled' : ''} ${darkname}`} onClick={toggleButton1}>
                                <div className="wallet-right-form-1-box-1-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToggled1
                        ?
                        <div className='wallet-right-form-3'>
                            <div className='wallet-right-form-3-1'>{t('wallet_10')}</div>
                            <div className='wallet-right-form-3-2'>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_197')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_198')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_199')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_200')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_201')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_202')}<br />
                                        {t('wallet_203')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_204')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_205')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_206')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} style={{ cursor: "auto" }} >
                            USDT
                        </div>
                    </div>
                </div>
                <div className={`wallet-right-form-10 ${darkname}`}>
                    <div className='wallet-right-form-10-1'>
                        <p className='wallet-right-form-10-1-1' style={{ justifyContent: "flex-start" }}>
                            <img src='img/coin/USDT.png' />{t('wallet_207')}
                        </p>
                        <p className='wallet-right-form-10-1-1' style={{ justifyContent: "end" }}>
                            <img src='img/coin/USDT.png' />{t('wallet_208')}
                        </p>
                    </div>
                    <div className='wallet-right-form-10-1'>
                        <p className='wallet-right-form-10-2'>{t('wallet_131')}<br />
                            {
                                selectedSend == "Binance"
                                    ?
                                    <span>{Binance_wallet_futures && Binance_wallet_futures[0] ? changeNum(RoundDown(Binance_wallet_futures[0].availableBalance, 6)) : "0.00000"}</span>
                                    :
                                    selectedSend == "OKX"
                                        ?
                                        <span>{OKX_wallet_usdt && OKX_wallet_usdt[0] ? changeNum(RoundDown(OKX_wallet_usdt[0].availBal, 6)) : "0.00000"}</span>
                                        :
                                        <span>{Bybit_wallet_usdt && Bybit_wallet_usdt[0] ? changeNum(RoundDown(Bybit_wallet_usdt[0].availableToWithdraw, 6)) : "0.00000"}</span>
                            }
                        </p>
                        <p className='wallet-right-form-10-3'>{t('wallet_131')}<br />
                            {
                                selectedReci == "Binance"
                                    ?
                                    <span>{Binance_wallet_futures && Binance_wallet_futures[0] ? changeNum(RoundDown(Binance_wallet_futures[0].availableBalance, 6)) : "0.00000"}</span>
                                    :
                                    selectedReci == "OKX"
                                        ?
                                        <span>{OKX_wallet_usdt && OKX_wallet_usdt[0] ? changeNum(RoundDown(OKX_wallet_usdt[0].availBal, 6)) : "0.00000"}</span>
                                        :
                                        <span>{Bybit_wallet_usdt && Bybit_wallet_usdt[0] ? changeNum(RoundDown(Bybit_wallet_usdt[0].availableToWithdraw, 6)) : "0.00000"}</span>
                            }
                        </p>
                    </div>
                    <div className='wallet-right-form-10-1' style={{ marginTop: "3px" }}>
                        <select
                            value={selectedSend}
                            onChange={handleSelectSend}
                            className={`wallet-right-form-10-4 ${darkname}`}
                        >
                            {
                                coinList.map((option, index) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))
                            }
                        </select>
                        <div className='wallet-right-form-10-5' style={{ cursor: "auto" }}>
                            <FontAwesomeIcon icon={faCircleArrowRight} />
                        </div>
                        <select
                            value={selectedReci}
                            onChange={handleSelectReci}
                            className={`wallet-right-form-10-4 ${darkname}`}
                        >
                            {
                                coinListReci.map((option, index) => (
                                    option !== selectedSend && (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    )
                                ))
                            }
                        </select>
                    </div>
                    <div className='wallet-right-form-10-1 display-none-600'>
                        <p className='wallet-right-form-10-2'>
                            <span>{selectedSend == "Binance" ? Bn_selectCoin ? Bn_selectCoin : "-" : selectedSend == "OKX" ? okx_selectCoin ? okx_selectCoin : "-" : By_selectCoin ? By_selectCoin : "-"}</span>
                        </p>
                        <p className='wallet-right-form-10-3'>
                            <span>{selectedReci == "Binance" ? Bn_selectCoin ? Bn_selectCoin : "-" : selectedReci == "OKX" ? okx_selectCoin ? okx_selectCoin : "-" : By_selectCoin ? By_selectCoin : "-"}</span>
                        </p>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_209')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`}>
                            <input className='wallet-right-form-4-3-input' value={amount} onChange={onChangeAmount} placeholder='0' />
                            <span className='wallet-right-form-4-3-span'>USDT</span>
                            <div className='wallet-right-form-4-3-btn' onClick={() =>
                                setAmount(
                                    selectedSend == "Binance"
                                        ?
                                        Binance_wallet_futures && Binance_wallet_futures[0] ? changeNum(RoundDown(Binance_wallet_futures[0].availableBalance, 6)) : "0.00000"
                                        :
                                        selectedSend == "OKX"
                                            ?
                                            OKX_wallet_usdt && OKX_wallet_usdt[0] ? changeNum(RoundDown(OKX_wallet_usdt[0].availBal, 6)) : "0.00000"
                                            :
                                            Bybit_wallet_usdt && Bybit_wallet_usdt[0] ? changeNum(RoundDown(Bybit_wallet_usdt[0].availableToWithdraw, 6)) : "0.00000"
                                )}
                            >{t('wallet_108')}</div>
                        </div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className='wallet-right-form-4-1' style={{ fontWeight: "500", opacity: ".5" }}>{t('wallet_210')}</div>
                        <div className='wallet-right-form-4-1-1'>1 USDT</div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div className='wallet-right-form-4-1' style={{ fontWeight: "500", opacity: ".5" }}>{t('wallet_211')}</div>
                        <div className='wallet-right-form-4-1-1' >10 USDT</div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_212')}</div>
                    <div className='wallet-right-form-4-2' style={{ opacity: ".5" }}>
                        {t('wallet_213')}
                    </div>
                    <div className='wallet-right-form-8'>
                        {code.map((digit, index) => (
                            <div className={`wallet-right-form-8-1 ${darkname}`}>
                                <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => handleInputChange(e, index)}
                                    className={`wallet-right-form-8-2 ${darkname}`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='wallet-right-form-9'>
                    <div className={`wallet-right-form-9-1 ${darkname}`} onClick={() => Check_Modal()}>{t('header_41')}</div>
                </div>
                <div className='wallet-right-form-7'>
                    <div className='wallet-right-form-7-1'>{t('wallet_214')}</div>
                    <div className='wallet-right-form-7-2'><FontAwesomeIcon icon={faRotate} onClick={() => setResetHistory(e => !e)} /></div>
                    <div className='wallet-right-form-7-3'>
                        <div className={`wallet-right-form-7-3-top ${darkname}`}>
                            <div className='wallet-right-form-7-3-top-1'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_68')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_69')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-1'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_70')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_71')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-3'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_72')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_73')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-4 wallet-display-none-800'>{t('wallet_74')}</div>
                        </div>
                        <div className='wallet-right-form-7-3-bottom'>
                            {
                                quickHistory && quickHistory.length === 0
                                    ?
                                    <div className='history-right-bottom-4-bottom-1'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>{t('message_0')}</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            filter_quickHistory.map(function (a, i) {
                                                return (
                                                    <QuickWithdrawItem key={i} a={a} darkname={darkname} />
                                                )
                                            })
                                        }
                                        <Page initialData={quickHistory} page={page} setPage={setPage} maxFirstPage={10} />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

function QuickWithdrawItem({ a, darkname }) {
    const [mouseState, setMouseState] = useState(false)

    const onMouseEnter = () => {
        setMouseState(true)
    }
    const onMouseLeave = () => {
        setMouseState(false)
    }

    return (
        <div className={`wallet-right-form-7-3-bottom-1 ${darkname}`}>
            <div className='wallet-right-form-7-3-bottom-2'>
                <div className='wallet-right-form-7-3-bottom-3'>{a.regist_date}</div>
                <div className='wallet-right-form-7-3-bottom-3'>{a.exchange}</div>
            </div>
            <div className='wallet-right-form-7-3-bottom-2'>
                <div className='wallet-right-form-7-3-bottom-3' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ cursor: "pointer" }}>
                    {a.txn_id}
                    {
                        mouseState
                            ?
                            <div className={`wallet-right-form-7-3-bottom-3-1 ${darkname}`}>{a.txn_id}</div>
                            :
                            ""
                    }
                </div>
                <div className='wallet-right-form-7-3-bottom-3'>{a.amount}</div>
            </div>
            <div className='wallet-right-form-7-3-bottom-4'>
                <div className='wallet-right-form-7-3-bottom-3'>{a.coin}</div>
                <div className='wallet-right-form-7-3-bottom-3'>{a.network}</div>
            </div>
            <div className='wallet-right-form-7-3-top-5 wallet-display-none-800'>
                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{a.status}</div>
            </div>
        </div>
    )
}

export default Bybit_QuickWithdrawal;
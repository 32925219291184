import './App.css';
import './Nav/nav.css'
import { Routes, Route, Navigate } from 'react-router-dom'
// Common
import Main1 from './Pages/common/C_main/main1'
import Header from './Nav/header'
import API from './api'
import Footer from './Nav/footer'
import Market from './Pages/market'
import Login from './Pages/login'
import Regist from './Pages/regist'
import Dashboard from './Pages/dashboard'
import OTP from './Pages/otp'
import ForgotPassword_A from './Pages/ForgotPassword_A'
import ForgotPassword_B from './Pages/ForgotPassword_B'
// Binance
import BSpot from './Pages/Binance/Bspot'
import BFutures from './Pages/Binance/Bfutures'
import BHistory from './Pages/Binance/Bhistory'
import BWallet from './Pages/Binance/Bwallet'
// OKX
import Spot from './Pages/OKX/spot'
import Futures from './Pages/OKX/futures'
import History from './Pages/OKX/history'
import Wallet from './Pages/OKX/wallet'
// Bybit
import BySpot from './Pages/Bybit/Byspot';
import ByFutures from './Pages/Bybit/Byfutures'
import ByHistory from './Pages/Bybit/Byhistory'
import ByWallet from './Pages/Bybit/Bywallet';

import ErrorBoundary from './Pages/ErrorBoundary'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { checkGuide } from './store';

let background_time = false
function App() {
  useEffect(() => {
    setTimeout(() => {
      background_time = true
    }, 600000)
  }, [])


  // 백그라운드에서 포그라운드로 전환될 때, 새로고침
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && background_time) {
        window.location.reload()
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  let SelectType = useSelector((state) => { return state.SelectType.value })
  let login = useSelector((state) => { return state.login.value });

  let dispatch = useDispatch();
  const { t } = useTranslation();

  console.error = (message) => {
  };

  useEffect(() => {
    if (login) {
      let objString = localStorage.getItem("guide")
      if (objString) {
        dispatch(checkGuide(true))
      }
      else {
        dispatch(checkGuide(false))
      }
    }
  }, [login])

  return (
    <>
      <div className="App">
        <div style={{ position: "relative" }}>
          <Header />
          <ErrorBoundary>
            <API />
            <Routes>
              <Route path="/" element={<Main1 />} />

              <Route path="/market" element={<Market />} />
              <Route path="/login" element={<Login />} />
              <Route path="/regist" element={<Regist />} />

              <Route path="/spot/okx/:symbol" element={<Spot />} />
              <Route path="/spot/binance/:symbol" element={<BSpot />} />
              <Route path="/spot/bybit/:symbol" element={<BySpot />} />

              <Route path="/futures/okx/:symbol" element={<Futures />} />
              <Route path="/futures/binance/:symbol" element={<BFutures />} />
              <Route path="/futures/bybit/:symbol" element={<ByFutures />} />

              <Route path="/history" element={SelectType === 0 ? <History /> : SelectType === 1 ? <BHistory /> : <ByHistory />} />
              <Route path="/wallet" element={SelectType === 0 ? <Wallet /> : SelectType === 1 ? <BWallet /> : <ByWallet />} />

              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/otp" element={<OTP />} />
              <Route path="/forgotPassword_A" element={<ForgotPassword_A />} />
              <Route path="/forgotPassword_B" element={<ForgotPassword_B />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </ErrorBoundary>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;

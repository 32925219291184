import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faAngleDown, faMoon, faSun, faCircleUser, faWallet, faArrowRightFromBracket, faGlobe } from "@fortawesome/free-solid-svg-icons";
import "./nav.css";
import "./header_animation.css";
import axios from "axios";
import { useState } from "react";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { useDispatch, useSelector } from "react-redux";
import { checkSelectType, checkLogin, checkMode, changeWalletPage, changeLanguages, checkAlert, check_Error, checkGuide, change_By_apiKey } from "../store";
import i18n from "../lang/i18n";
import { useTranslation } from "react-i18next";
import base64 from "base-64";
import Modal from "../Pages/message/modal";
import Namu_Message from "../Pages/message/namu_message";

let intervalId;
let login_cnt = 0;
let lang_arr = ""

function Header() {
    let login = useSelector((state) => { return state.login.value; });
    let dark = useSelector((state) => { return state.Darkmode.value; });
    let Error = useSelector((state) => { return state.Error.value; });
    let Languages = useSelector((state) => { return state.Languages.value })

    const darkname = dark ? "dark" : "";

    let dispatch = useDispatch();
    const navigate = useNavigate();
    const [lan, setLan] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [btn1, setBtn1] = useState(false);
    const [btn2, setBtn2] = useState(false);
    const [btn3, setBtn3] = useState(false);
    const [btn4, setBtn4] = useState(false);
    const [btn5, setBtn5] = useState(false);
    const [btn6, setBtn6] = useState(false);
    const [btn7, setBtn7] = useState(false);

    const [message, setMessage] = useState([])
    const [namu_message, setNamu_Message] = useState([])
    const [buyModal, setBuyModal] = useState(false);
    const [w_confirm, setW_confirm] = useState(false)
    const [res, setRes] = useState(false)

    // 언어변경
    const { t } = useTranslation();
    const onChangeLang = (e, n) => {
        if (e == "ja-JP") {
            document.body.classList.add('font_JP');
        }
        else {
            document.body.classList.remove('font_JP');
        }
        i18n.changeLanguage(e);
        dispatch(changeLanguages(e));
        if (n == 1 && Languages != e) setMessage({ type: "binance", code: 0, msg: "Language changed." })
    };

    if (Languages) {
        lang_arr = Languages.toLowerCase()
        if (lang_arr == "vi-vn") {
            lang_arr = "en-us"
        }
    }

    useEffect(() => {
        const objString = localStorage.getItem("language");
        if (objString) {
            onChangeLang(objString, 0)
        }
        else {
            onChangeLang("en-US", 0)
        }
    }, []);

    useEffect(() => {
        if (Error && Error.bol) {
            if (window.confirm(`[No.${Error.no}]Error, Please Refresh (${Error.msg})`)) {
                window.location.reload(true);
            }
        }
    }, [Error]);

    useEffect(() => {
        let objString = localStorage.getItem("token");
        if (objString) {
            let obj = JSON.parse(objString);
            let token = obj.value;
            let payload = token.substring(
                token.indexOf(".") + 1,
                token.lastIndexOf(".")
            );
            let dec = base64.decode(payload);

            dispatch(change_By_apiKey({ "S_api": JSON.parse(dec).bybitAK, "S_sec": JSON.parse(dec).bybitSK }))
        }
    }, [login])

    useEffect(() => {
        if (login_cnt > 0) {
            let objString = localStorage.getItem("token");
            if (objString) {
                let obj = JSON.parse(objString);
                let token = obj.value;
                let payload = token.substring(
                    token.indexOf(".") + 1,
                    token.lastIndexOf(".")
                );
                let dec = base64.decode(payload);

                let userId = JSON.parse(dec).userId;
                if (userId != 1 && userId != 90) {
                    LoginCheck(token);

                    intervalId = setInterval(() => {
                        LoginCheck(token);
                    }, 1000 * 30);

                    return () => clearInterval(intervalId);
                }
            }
        }
        login_cnt += 1;
    }, [login]);

    const LoginCheck = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/account/user/login/check`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            if (!response.data) {
                alert(`${t("message_21")}`);
                clearInterval(intervalId);
                localStorage.removeItem("token");
                dispatch(checkLogin(false));
                navigate("/login");
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 18 }));
        });
    };

    useEffect(() => {
        AOS.init();
    }, []);

    const onMouseEnter1 = () => {
        setBtn1(true);
    };

    const onMouseLeave1 = () => {
        setBtn1(false);
    };

    const onMouseEnter2 = () => {
        setBtn2(true);
    };

    const onMouseLeave2 = () => {
        setBtn2(false);
    };

    const onMouseEnter3 = () => {
        setBtn3(true);
    };

    const onMouseLeave3 = () => {
        setBtn3(false);
    };

    const onMouseEnter4 = () => {
        setBtn4(true);
    };

    const onMouseLeave4 = () => {
        setBtn4(false);
    };

    const onMouseEnter5 = () => {
        setBtn5(true);
    };

    const onMouseLeave5 = () => {
        setBtn5(false);
    };

    const onMouseEnter6 = () => {
        setBtn6(true);
    };

    const onMouseLeave6 = () => {
        setBtn6(false);
    };

    const onMouseEnter7 = () => {
        setBtn7(true);
    };

    const onMouseLeave7 = () => {
        setBtn7(false);
    };

    const onMouseEnterLan = () => {
        setLan(true);
    };

    const onMouseLeaveLan = () => {
        setLan(false);
    };

    const turnoff = () => {
        setBtn1(false);
        setBtn2(false);
        setBtn3(false);
        setBtn4(false);
        setBtn5(false);
        setBtn6(false);
        setBtn7(false);
        setLan(false);
    };

    const onSelectType = (e) => {
        dispatch(checkSelectType(e));
    };

    useEffect(() => {
        const objString = localStorage.getItem("token");
        if (objString) {
            const obj = JSON.parse(objString);
            if (obj.type === "okx") {
                dispatch(checkSelectType(0));
            }
            else if (obj.type === "binance") {
                dispatch(checkSelectType(1));
            }
            else {
                dispatch(checkSelectType(2));
            }
        }
        const objString1 = localStorage.getItem("mode");
        if (objString1) {
            if (objString1 === "light") {
                dispatch(checkMode(false));
                document.documentElement.setAttribute("color-scheme", "light");

            }
            else {
                dispatch(checkMode(true));
                document.documentElement.setAttribute("color-scheme", "dark");
            }
        }
        else {
            dispatch(checkMode(true));
            document.documentElement.setAttribute("color-scheme", "dark");
        }
    }, [dark]);

    useEffect(() => {
        let objString = localStorage.getItem("alert");
        if (objString) {
            let arr = objString.split(",");
            for (let i = 0; i < arr.length; i++) {
                dispatch(checkAlert({ num: i, value: arr[i] }));
            }
        }
    }, []);

    const Logout = () => {
        setNamu_Message({ type: "binance", code: 0, msg: t('message_40') })
        setBuyModal(true)
        setRes(2)
    };

    useEffect(() => {
        if (w_confirm) {
            setW_confirm(false)
            clearInterval(intervalId);
            localStorage.removeItem("token");
            localStorage.removeItem("guide");
            dispatch(checkLogin(false));
            dispatch(checkGuide(false));
            navigate('/')
        }
    }, [w_confirm])

    return (
        <div className="header">
            <Modal message={message} />
            <Namu_Message message={namu_message} buyModal={buyModal} setBuyModal={setBuyModal} setW_confirm={setW_confirm} res={res} />
            <div className={`header-box ${darkname}`}>
                <div className="header-log" onClick={() => turnoff()}>
                    <Link to={`/`}>
                        <img src={dark ? "/img/bitket/logo-white.png" : "/img/bitket/logo-black.png"}></img>
                    </Link>
                </div>
                <div className="header-menu display-none">
                    <ul>
                        <li onMouseEnter={onMouseEnter1} onMouseLeave={onMouseLeave1}>
                            <Link to={'/market'} onClick={() => turnoff()}>{t("header_0")} <FontAwesomeIcon icon={faAngleDown} className="header-menu-arrow" /></Link>
                            <ul className={`header-menu-dropdown ${btn1 ? "enter1" : "leave1"} ${darkname}`}>
                                <li>
                                    <Link to={`/market`} onClick={() => turnoff()}>
                                        <div className="header-menu-dropdown-1">
                                            <div className="header-menu-dropdown-icon">
                                                <img src="/img/icon/market.png" style={{ width: "20px" }} />
                                            </div>
                                            <div className="header-menu-dropdown-wrap">
                                                <div className="header-menu-dropdown-wrap-1">{t("header_1")}</div>
                                                <div className="header-menu-dropdown-wrap-2">{t("header_2")}</div>
                                            </div>
                                            <div className="header-menu-dropdown-arrow">
                                                <svg viewBox="0 0 18.5 13.64">
                                                    <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                    <path className="st1" d="M0.75,6.82h16.83"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2}>
                            <div>{t("header_3")} <FontAwesomeIcon icon={faAngleDown} className="header-menu-arrow" /></div>
                            <ul className={`header-menu-dropdown ${btn2 ? "enter1" : "leave1"} ${darkname}`}>
                                <li>
                                    <Link to={`/spot/binance/BTCUSDT`} onClick={() => { turnoff(); onSelectType(1); }}>
                                        <div className="header-menu-dropdown-1">
                                            <div className="header-menu-dropdown-icon">
                                                <svg viewBox="0 0 126.6 126.6">
                                                    <path d="M38.7,53.2l24.6-24.6l24.6,24.6l14.3-14.3L63.3,0L24.4,38.9L38.7,53.2z" style={{ fill: "#F0B90B" }}></path>
                                                    <path className="st1" d="M0,63.3L14.3,49l14.3,14.3L14.3,77.6L0,63.3z"></path>
                                                    <path d="M38.7,73.4L63.3,98l24.6-24.6l14.3,14.3l0,0l-38.9,38.9L24.4,87.7l0,0L38.7,73.4z" style={{ fill: "#F0B90B" }}></path>
                                                    <path className="st1" d="M98,63.3L112.3,49l14.3,14.3l-14.3,14.3L98,63.3z"></path>
                                                    <path className="st1" d="M77.8,63.3L63.3,48.8L52.6,59.5l-1.2,1.2l-2.5,2.5l0,0l0,0l14.5,14.5L77.8,63.3L77.8,63.3L77.8,63.3z"></path>
                                                </svg>
                                            </div>
                                            <div className="header-menu-dropdown-wrap">
                                                <div className="header-menu-dropdown-wrap-1">{t("header_4")}</div>
                                                <div className="header-menu-dropdown-wrap-2">{t("header_5")}</div>
                                            </div>
                                            <div className="header-menu-dropdown-arrow">
                                                <svg viewBox="0 0 18.5 13.64">
                                                    <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                    <path className="st1" d="M0.75,6.82h16.83"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/spot/okx/BTC-USDT`}
                                        onClick={() => { turnoff(); onSelectType(0); }}>
                                        <div className="header-menu-dropdown-1">
                                            <div className="header-menu-dropdown-icon">
                                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="443.000000pt" height="188.000000pt" viewBox="0 0 443.000000 188.000000" preserveAspectRatio="xMidYMid meet">
                                                    <g transform="translate(0.000000,188.000000) scale(0.100000,-0.100000)" fill={dark ? "#fff" : "#000000"} stroke="none">
                                                        <path d="M0 940 l0 -940 2215 0 2215 0 0 940 0 940 -2215 0 -2215 0 0 -940zm1398 622 c10 -7 12 -133 10 -598 l-3 -589 -597 -3 -598 -2 0 600 0 600 588 0
c323 0 593 -4 600 -8z m596 2 c14 -5 16 -32 16 -200 l0 -194 198 -2 197 -3 0
-195 0 -195 -197 -3 -198 -2 -2 -198 -3 -197 -195 0 -195 0 -3 585 c-1 322 0
591 3 598 3 9 49 12 184 12 99 0 186 -3 195 -6z m804 -2 c9 -7 12 -53 10 -198
l-3 -189 -195 0 -195 0 -3 185 c-1 101 0 190 2 197 4 10 49 13 188 13 101 0
189 -4 196 -8z m607 -192 l0 -195 -195 0 -195 0 -3 185 c-1 101 0 190 3 197 3
11 46 13 197 11 l193 -3 0 -195z m800 0 l0 -195 -195 0 -195 0 -3 185 c-1 101
0 190 3 197 3 11 46 13 197 11 l193 -3 0 -195z m-400 -400 l0 -195 -195 0
-195 0 -3 185 c-1 101 0 190 3 197 3 11 46 13 197 11 l193 -3 0 -195z m-1007
-208 c9 -7 12 -53 10 -198 l-3 -189 -195 0 -195 0 -3 185 c-1 101 0 190 2 197
4 10 49 13 188 13 101 0 189 -4 196 -8z m607 -192 l0 -195 -195 0 -195 0 -3
185 c-1 101 0 190 3 197 3 11 46 13 197 11 l193 -3 0 -195z m800 0 l0 -195
-195 0 -195 0 -3 185 c-1 101 0 190 3 197 3 11 46 13 197 11 l193 -3 0 -195z"
                                                        ></path>
                                                        <path d="M615 1157 c-3 -7 -4 -96 -3 -197 l3 -185 195 0 195 0 0 195 0 195-193 3 c-151 2 -194 0 -197 -11z"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="header-menu-dropdown-wrap">
                                                <div className="header-menu-dropdown-wrap-1">{t("header_6")}</div>
                                                <div className="header-menu-dropdown-wrap-2">{t("header_7")}</div>
                                            </div>
                                            <div className="header-menu-dropdown-arrow">
                                                <svg viewBox="0 0 18.5 13.64">
                                                    <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                    <path className="st1" d="M0.75,6.82h16.83"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/spot/bybit/BTCUSDT`} onClick={() => { turnoff(); onSelectType(2); }}>
                                        <div className="header-menu-dropdown-1">
                                            <div className="header-menu-dropdown-icon">
                                                <svg viewBox="0 0 2498.8 833">
                                                    <polygon class="st1" points="1781,642.2 1781,0 1910.1,0 1910.1,642.2"></polygon>
                                                    <path class="st0" d="M276.7,832.9H0V190.8h265.6c129,0,204.3,70.4,204.3,180.4c0,71.3-48.3,117.2-81.8,132.6c39.9,18,91,58.6,91,144.3
                                                    C479.1,767.9,394.6,832.9,276.7,832.9L276.7,832.9z M255.4,302.7H129v147.9h126.4c54.8,0,85.5-29.8,85.5-74
                                                    S310.2,302.7,255.4,302.7L255.4,302.7z M263.7,563.3H129v157.8h134.6c58.6,0,86.4-36.1,86.4-79.4
                                                    C350,598.4,322.1,563.3,263.7,563.3z"></path>
                                                    <polygon class="st0" points="872.8,569.5 872.8,832.9 744.6,832.9 744.6,569.5 545.9,190.8 686.2,190.8 809.6,449.6 931.3,190.8 
                                                    1071.5,190.8"></polygon>
                                                    <path class="st1" d="M1437.4,832.9h-276.7V190.8h265.6c129,0,204.3,70.4,204.3,180.4c0,71.3-48.3,117.2-81.8,132.6c39.9,18,91,58.6,91,144.3
                                                    C1639.8,767.9,1555.4,832.9,1437.4,832.9L1437.4,832.9z M1416.1,302.7h-126.3v147.9h126.3c54.8,0,85.5-29.8,85.5-74
                                                    C1501.5,332.4,1470.8,302.7,1416.1,302.7z M1424.4,563.3h-134.6v157.8h134.6c58.6,0,86.4-36.1,86.4-79.4
                                                    C1510.8,598.4,1482.9,563.3,1424.4,563.3L1424.4,563.3z"></path>
                                                    <polygon class="st1" points="2326.1,302.7 2326.1,833 2197,833 2197,302.7 2024.3,302.7 2024.3,190.8 2498.8,190.8 2498.8,302.7"></polygon>
                                                </svg>
                                            </div>
                                            <div className="header-menu-dropdown-wrap">
                                                <div className="header-menu-dropdown-wrap-1">{t("header_6-1")}</div>
                                                <div className="header-menu-dropdown-wrap-2">{t("header_7-1")}</div>
                                            </div>
                                            <div className="header-menu-dropdown-arrow">
                                                <svg viewBox="0 0 18.5 13.64">
                                                    <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                    <path className="st1" d="M0.75,6.82h16.83"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li onMouseEnter={onMouseEnter3} onMouseLeave={onMouseLeave3}>
                            <div>{t("header_8")} <FontAwesomeIcon icon={faAngleDown} className="header-menu-arrow" />
                            </div>
                            <ul className={`header-menu-dropdown ${btn3 ? "enter1" : "leave1"} ${darkname}`}>
                                <li onMouseEnter={onMouseEnter7} onMouseLeave={onMouseLeave7}>
                                    <Link to={`/futures/binance/BTCUSDT`} onClick={() => { turnoff(); onSelectType(1); }}>
                                        <div className="header-menu-dropdown-1">
                                            <div className="header-menu-dropdown-icon">
                                                <svg viewBox="0 0 126.6 126.6">
                                                    <path d="M38.7,53.2l24.6-24.6l24.6,24.6l14.3-14.3L63.3,0L24.4,38.9L38.7,53.2z" style={{ fill: "#F0B90B" }}></path>
                                                    <path className="st1" d="M0,63.3L14.3,49l14.3,14.3L14.3,77.6L0,63.3z"></path>
                                                    <path d="M38.7,73.4L63.3,98l24.6-24.6l14.3,14.3l0,0l-38.9,38.9L24.4,87.7l0,0L38.7,73.4z" style={{ fill: "#F0B90B" }}></path>
                                                    <path className="st1" d="M98,63.3L112.3,49l14.3,14.3l-14.3,14.3L98,63.3z"></path>
                                                    <path className="st1" d="M77.8,63.3L63.3,48.8L52.6,59.5l-1.2,1.2l-2.5,2.5l0,0l0,0l14.5,14.5L77.8,63.3L77.8,63.3L77.8,63.3z"></path>
                                                </svg>
                                            </div>
                                            <div className="header-menu-dropdown-wrap">
                                                <div className="header-menu-dropdown-wrap-1">{t("header_9")}</div>
                                                <div className="header-menu-dropdown-wrap-2">{t("header_10")}</div>
                                            </div>
                                            <div className="header-menu-dropdown-arrow">
                                                <svg viewBox="0 0 18.5 13.64">
                                                    <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                    <path className="st1" d="M0.75,6.82h16.83"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/futures/okx/BTC-USDT-SWAP`} onClick={() => { turnoff(); onSelectType(0); }}>
                                        <div className="header-menu-dropdown-1">
                                            <div className="header-menu-dropdown-icon">
                                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="443.000000pt" height="188.000000pt" viewBox="0 0 443.000000 188.000000" preserveAspectRatio="xMidYMid meet">
                                                    <g transform="translate(0.000000,188.000000) scale(0.100000,-0.100000)" fill={dark ? "#fff" : "#000000"} stroke="none">
                                                        <path
                                                            d="M0 940 l0 -940 2215 0 2215 0 0 940 0 940 -2215 0 -2215 0 0 -940zm1398 622 c10 -7 12 -133 10 -598 l-3 -589 -597 -3 -598 -2 0 600 0 600 588 0
c323 0 593 -4 600 -8z m596 2 c14 -5 16 -32 16 -200 l0 -194 198 -2 197 -3 0
-195 0 -195 -197 -3 -198 -2 -2 -198 -3 -197 -195 0 -195 0 -3 585 c-1 322 0
591 3 598 3 9 49 12 184 12 99 0 186 -3 195 -6z m804 -2 c9 -7 12 -53 10 -198
l-3 -189 -195 0 -195 0 -3 185 c-1 101 0 190 2 197 4 10 49 13 188 13 101 0
189 -4 196 -8z m607 -192 l0 -195 -195 0 -195 0 -3 185 c-1 101 0 190 3 197 3
11 46 13 197 11 l193 -3 0 -195z m800 0 l0 -195 -195 0 -195 0 -3 185 c-1 101
0 190 3 197 3 11 46 13 197 11 l193 -3 0 -195z m-400 -400 l0 -195 -195 0
-195 0 -3 185 c-1 101 0 190 3 197 3 11 46 13 197 11 l193 -3 0 -195z m-1007
-208 c9 -7 12 -53 10 -198 l-3 -189 -195 0 -195 0 -3 185 c-1 101 0 190 2 197
4 10 49 13 188 13 101 0 189 -4 196 -8z m607 -192 l0 -195 -195 0 -195 0 -3
185 c-1 101 0 190 3 197 3 11 46 13 197 11 l193 -3 0 -195z m800 0 l0 -195
-195 0 -195 0 -3 185 c-1 101 0 190 3 197 3 11 46 13 197 11 l193 -3 0 -195z"
                                                        ></path>
                                                        <path d="M615 1157 c-3 -7 -4 -96 -3 -197 l3 -185 195 0 195 0 0 195 0 195-193 3 c-151 2 -194 0 -197 -11z"></path>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="header-menu-dropdown-wrap">
                                                <div className="header-menu-dropdown-wrap-1">{t("header_13")}</div>
                                                <div className="header-menu-dropdown-wrap-2">{t("header_14")}</div>
                                            </div>
                                            <div className="header-menu-dropdown-arrow">
                                                <svg viewBox="0 0 18.5 13.64">
                                                    <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                    <path className="st1" d="M0.75,6.82h16.83"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to={`/futures/bybit/BTCUSDT`} onClick={() => { turnoff(); onSelectType(2); }}>
                                        <div className="header-menu-dropdown-1">
                                            <div className="header-menu-dropdown-icon">
                                                <svg viewBox="0 0 2498.8 833">
                                                    <polygon class="st1" points="1781,642.2 1781,0 1910.1,0 1910.1,642.2"></polygon>
                                                    <path class="st0" d="M276.7,832.9H0V190.8h265.6c129,0,204.3,70.4,204.3,180.4c0,71.3-48.3,117.2-81.8,132.6c39.9,18,91,58.6,91,144.3
                                                    C479.1,767.9,394.6,832.9,276.7,832.9L276.7,832.9z M255.4,302.7H129v147.9h126.4c54.8,0,85.5-29.8,85.5-74
                                                    S310.2,302.7,255.4,302.7L255.4,302.7z M263.7,563.3H129v157.8h134.6c58.6,0,86.4-36.1,86.4-79.4
                                                    C350,598.4,322.1,563.3,263.7,563.3z"></path>
                                                    <polygon class="st0" points="872.8,569.5 872.8,832.9 744.6,832.9 744.6,569.5 545.9,190.8 686.2,190.8 809.6,449.6 931.3,190.8 
                                                    1071.5,190.8"></polygon>
                                                    <path class="st1" d="M1437.4,832.9h-276.7V190.8h265.6c129,0,204.3,70.4,204.3,180.4c0,71.3-48.3,117.2-81.8,132.6c39.9,18,91,58.6,91,144.3
                                                    C1639.8,767.9,1555.4,832.9,1437.4,832.9L1437.4,832.9z M1416.1,302.7h-126.3v147.9h126.3c54.8,0,85.5-29.8,85.5-74
                                                    C1501.5,332.4,1470.8,302.7,1416.1,302.7z M1424.4,563.3h-134.6v157.8h134.6c58.6,0,86.4-36.1,86.4-79.4
                                                    C1510.8,598.4,1482.9,563.3,1424.4,563.3L1424.4,563.3z"></path>
                                                    <polygon class="st1" points="2326.1,302.7 2326.1,833 2197,833 2197,302.7 2024.3,302.7 2024.3,190.8 2498.8,190.8 2498.8,302.7"></polygon>
                                                </svg>
                                            </div>
                                            <div className="header-menu-dropdown-wrap">
                                                <div className="header-menu-dropdown-wrap-1">{t("header_13-1")}</div>
                                                <div className="header-menu-dropdown-wrap-2">{t("header_14-1")}</div>
                                            </div>
                                            <div className="header-menu-dropdown-arrow">
                                                <svg viewBox="0 0 18.5 13.64">
                                                    <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                    <path className="st1" d="M0.75,6.82h16.83"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="header-right display-none">
                    {
                        login
                            ?
                            (
                                <ul>
                                    <li onMouseEnter={onMouseEnter5} onMouseLeave={onMouseLeave5}>
                                        <Link to={`/wallet`} state={{ num: 0 }} onClick={() => { turnoff(); dispatch(changeWalletPage(-1)); }}>{t("header_28")} <FontAwesomeIcon icon={faAngleDown} className="header-menu-arrow" />
                                        </Link>
                                        <ul className={`header-menu-dropdown ${btn5 ? "enter1" : "leave1"} ${darkname}`} style={{ right: "-15px", left: "unset" }}>
                                            <li>
                                                <Link to={`/wallet`} state={{ num: 0 }} onClick={() => { turnoff(); dispatch(changeWalletPage(-1)); }}>
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <img src="/img/icon/wallet_overview.png" style={{ width: "20px" }} />
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_29")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_30")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/wallet`} state={{ num: 1 }} onClick={() => { turnoff(); dispatch(changeWalletPage(-1)); }}>
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <img src="/img/icon/deposit.png" style={{ width: "20px" }} />
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_31")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_32")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/wallet`} state={{ num: 2 }} onClick={() => { turnoff(); dispatch(changeWalletPage(-1)); }}>
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <img src="/img/icon/withdraw.png" style={{ width: "20px" }} />
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_33")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_34")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/wallet`} state={{ num: 3 }} onClick={() => { turnoff(); dispatch(changeWalletPage(-1)); }}>
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <img src="/img/icon/convert.png" style={{ width: "20px" }} />
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_35")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_36")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/wallet`} state={{ num: 4 }} onClick={() => { turnoff(); dispatch(changeWalletPage(-1)); }}>
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <img src="/img/icon/transfer.png" style={{ width: "20px" }} />
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_37")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_38")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/wallet`} state={{ num: 5 }} onClick={() => { turnoff(); dispatch(changeWalletPage(-1)); }}>
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <img src="/img/icon/exchange.png" style={{ width: "20px" }} />
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_41")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_42")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/wallet`} state={{ num: 6 }} onClick={() => { turnoff(); dispatch(changeWalletPage(-1)); }}>
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <img src="/img/icon/gift.png" style={{ width: "20px" }} />
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_39")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_40")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li onClick={() => turnoff()}>
                                        <Link to={`/wallet`} state={{ num: 1 }}>
                                            <div className="header-right-regist">{t("header_31")}</div>
                                        </Link>
                                    </li>
                                    <li onMouseEnter={onMouseEnter6} onMouseLeave={onMouseLeave6}>
                                        <Link to={`/dashboard`} state={{ num: 0 }} onClick={() => { turnoff(); }}>
                                            <FontAwesomeIcon icon={faCircleUser} style={{ fontSize: "25px", color: darkname ? "#ebe9e9" : "#595959", }} />
                                            {/* {t('header_43')} <FontAwesomeIcon icon={faAngleDown} className="header-menu-arrow" /> */}
                                        </Link>
                                        <ul className={`header-menu-dropdown ${btn6 ? "enter1" : "leave1"} ${darkname}`} style={{ right: "-15px", left: "unset" }}>
                                            <li>
                                                <Link to={`/dashboard`} state={{ num: 0 }} onClick={() => { turnoff(); }}>
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <img src="/img/icon/profile.png" style={{ width: "20px" }} />
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_44")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_45")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/dashboard`} state={{ num: 1 }} onClick={() => { turnoff(); }} >
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <img src="/img/icon/security.png" style={{ width: "20px" }} /> </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_46")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_47")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/dashboard`} state={{ num: 2 }} onClick={() => { turnoff(); }}>
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <img src="/img/icon/self_referral.png" style={{ width: "20px" }} />
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_48")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_49")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07" ></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/dashboard`} state={{ num: 3 }} onClick={() => { turnoff(); }} >
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <img src="/img/icon/referral_program.png" style={{ width: "20px" }} />
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_50")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_51")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07" ></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li onMouseEnter={onMouseEnter4} onMouseLeave={onMouseLeave4}>
                                        <Link to={`/history`} state={{ num: 0 }} onClick={() => { turnoff(); }}>
                                            <FontAwesomeIcon icon={faWallet} style={{ fontSize: "22px", color: darkname ? "#ebe9e9" : "#595959", }} />
                                            {/* {t('header_15')} <FontAwesomeIcon icon={faAngleDown} className="header-menu-arrow" /> */}
                                        </Link>
                                        <ul className={`header-menu-dropdown ${btn4 ? "enter1" : "leave1"} ${darkname}`} style={{ right: "-15px", left: "unset" }}>
                                            <li>
                                                <Link to={`/history`} state={{ num: 0 }} onClick={() => { turnoff(); }} >
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <svg viewBox="0 0 15 15">
                                                                <rect className="st1" x="4.43" y="11.95" width="6.14" height="1.07" />
                                                                <rect className="st1" x="4.43" y="10.08" width="6.14" height="1.07" />
                                                                <rect className="st1" x="4.43" y=".27" width="6.14" height="2.12" rx=".2" ry=".2" />
                                                                <path className="st1" d="m12.89,14.9H2.11c-.24,0-.43-.19-.43-.43V1.2c0-.24.19-.43.43-.43h10.77c.24,0,.43.19.43.43v13.26c0,.24-.19.43-.43.43Zm-10.13-1.07h9.49V1.84H2.75v11.98Z" />
                                                                <g>
                                                                    <path className="st0" d="m7.48,5.21c0,.83-.49,1.54-1.2,1.87-.14.06-.28.11-.43.14-.13.02-.28.04-.42.04-1.13,0-2.05-.92-2.05-2.05s.92-2.05,2.05-2.05v2.06h2.06-.01Z" />
                                                                    <path className="st0" d="m7.74,4.92h-2.06v-2.06h0c.89,0,1.65.56,1.93,1.35.05.14.09.28.11.43.01.09.02.18.02.27h0Z" />
                                                                    <rect className="st0" x="7.56" y="6.74" width="1.07" height="2.97" />
                                                                    <rect className="st0" x="10.54" y="6.74" width="1.07" height="2.97" />
                                                                    <rect className="st0" x="8.94" y="5.37" width="1.3" height="4.36" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_16")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_17")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07" ></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/history`} state={{ num: 1 }} onClick={() => { turnoff(); }} >
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <svg viewBox="0 0 300.8 354.2">
                                                                <path className="st1" d="M281.6,181.1c-10.7-6.5-21.8-10.8-33.7-13.2c-49.1-9.7-99.7,23-110.8,71.8c-4.2,18.6-3.5,36.9,3.1,54.9
                                                    c0.8,2.1,0.4,3.3-1.1,4.8c-8.4,8.2-16.6,16.6-25,24.8c-1.2,1.1-3.2,2-4.8,2c-31.6,0.1-63.2,0.1-94.9,0.1C5.2,326.4,0,321.2,0,312
                                                    c0-99.2,0-198.5,0-297.7C0,5.2,5,0,14.1,0c84.5,0,169,0,253.4,0c8.9,0,14.2,5.1,14.2,13.8c0,55.2,0,110.5,0,165.7
                                                    C281.7,179.9,281.7,180.2,281.6,181.1z M45,63.8c64.1,0,127.9,0,191.6,0c0-8.5,0-16.8,0-25.2c-63.9,0-127.7,0-191.6,0
                                                    C45,47,45,55.3,45,63.8z M45,114.9c64.1,0,127.9,0,191.7,0c0-8.5,0-16.8,0-25.1c-64,0-127.9,0-191.7,0C45,98.3,45,106.5,45,114.9z
                                                    "
                                                                ></path>
                                                                <path className="st0" d="M190.4,320.5c-11.3,11.3-22.5,22.6-33.6,33.7c-6-6-11.9-11.9-17.8-17.8c11-11,22.4-22.3,33.6-33.6
                                                    c-22.6-34.3-12.8-75.7,15.7-96.9c27.9-20.8,66.8-18.1,91.5,6.3c24.8,24.4,28,63.1,7.7,91.4C267.6,331.4,225.9,343.8,190.4,320.5z
                                                        M185.7,262.4c0,24.8,20,44.7,44.7,44.7c24.8,0,44.7-20,44.7-44.8c0-24.8-20-44.7-44.8-44.7C205.7,217.6,185.7,237.6,185.7,262.4z
                                                    "
                                                                ></path>
                                                            </svg>
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_18")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_19")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07" ></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/history`} state={{ num: 2 }} onClick={() => { turnoff(); }} >
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <svg viewBox="0 0 15 15">
                                                                <rect className="st1" x="4.43" y="11.95" width="6.14" height="1.07" />
                                                                <rect className="st1" x="4.43" y="10.08" width="6.14" height="1.07" />
                                                                <rect className="st1" x="4.43" y=".27" width="6.14" height="2.12" rx=".2" ry=".2" />
                                                                <path className="st1" d="m12.89,14.9H2.11c-.24,0-.43-.19-.43-.43V1.2c0-.24.19-.43.43-.43h10.77c.24,0,.43.19.43.43v13.26c0,.24-.19.43-.43.43Zm-10.13-1.07h9.49V1.84H2.75v11.98Z" />
                                                                <g>
                                                                    <path className="st0" d="m7.48,5.21c0,.83-.49,1.54-1.2,1.87-.14.06-.28.11-.43.14-.13.02-.28.04-.42.04-1.13,0-2.05-.92-2.05-2.05s.92-2.05,2.05-2.05v2.06h2.06-.01Z" />
                                                                    <path className="st0" d="m7.74,4.92h-2.06v-2.06h0c.89,0,1.65.56,1.93,1.35.05.14.09.28.11.43.01.09.02.18.02.27h0Z" />
                                                                    <rect className="st0" x="7.56" y="6.74" width="1.07" height="2.97" />
                                                                    <rect className="st0" x="10.54" y="6.74" width="1.07" height="2.97" />
                                                                    <rect className="st0" x="8.94" y="5.37" width="1.3" height="4.36" />
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_20")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_21")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07"></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/history`} state={{ num: 3 }} onClick={() => { turnoff(); }}>
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <svg viewBox="0 0 300.8 354.2">
                                                                <path className="st1" d="M281.6,181.1c-10.7-6.5-21.8-10.8-33.7-13.2c-49.1-9.7-99.7,23-110.8,71.8c-4.2,18.6-3.5,36.9,3.1,54.9
                                                    c0.8,2.1,0.4,3.3-1.1,4.8c-8.4,8.2-16.6,16.6-25,24.8c-1.2,1.1-3.2,2-4.8,2c-31.6,0.1-63.2,0.1-94.9,0.1C5.2,326.4,0,321.2,0,312
                                                    c0-99.2,0-198.5,0-297.7C0,5.2,5,0,14.1,0c84.5,0,169,0,253.4,0c8.9,0,14.2,5.1,14.2,13.8c0,55.2,0,110.5,0,165.7
                                                    C281.7,179.9,281.7,180.2,281.6,181.1z M45,63.8c64.1,0,127.9,0,191.6,0c0-8.5,0-16.8,0-25.2c-63.9,0-127.7,0-191.6,0
                                                    C45,47,45,55.3,45,63.8z M45,114.9c64.1,0,127.9,0,191.7,0c0-8.5,0-16.8,0-25.1c-64,0-127.9,0-191.7,0C45,98.3,45,106.5,45,114.9z
                                                    "
                                                                ></path>
                                                                <path className="st0" d="M190.4,320.5c-11.3,11.3-22.5,22.6-33.6,33.7c-6-6-11.9-11.9-17.8-17.8c11-11,22.4-22.3,33.6-33.6
                                                    c-22.6-34.3-12.8-75.7,15.7-96.9c27.9-20.8,66.8-18.1,91.5,6.3c24.8,24.4,28,63.1,7.7,91.4C267.6,331.4,225.9,343.8,190.4,320.5z
                                                        M185.7,262.4c0,24.8,20,44.7,44.7,44.7c24.8,0,44.7-20,44.7-44.8c0-24.8-20-44.7-44.8-44.7C205.7,217.6,185.7,237.6,185.7,262.4z
                                                    "
                                                                ></path>
                                                            </svg>
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_22")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_23")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07" ></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={`/history`} state={{ num: 4 }} onClick={() => { turnoff(); }} >
                                                    <div className="header-menu-dropdown-1">
                                                        <div className="header-menu-dropdown-icon">
                                                            <svg viewBox="0 0 15 15">
                                                                <rect className="st1" x="1.78" y="11.76" width="11.31" height="1.11" />
                                                                <rect className="st1" x="1.78" y="9.33" width="11.31" height="1.11" />
                                                                <rect className="st1" x="1.78" y="6.9" width="11.31" height="1.11" />
                                                                <polygon className="st1" points="1.78 4.86 1.78 4.46 5.94 4.46 13.1 4.46 13.1 5.57 5.57 5.57 2.1 5.57 1.78 4.86" />
                                                                <polygon className="st1" points="1.78 4.86 1.78 4.46 5.94 4.46 13.1 4.46 13.1 5.57 5.57 5.57 2.1 5.57 1.78 4.86" />
                                                                <path className="st1" d="m7.62,2.47V.63c0-.19-.15-.34-.34-.34H.53c-.19,0-.34.15-.34.34v13.49c0,.19.15.34.34.34h13.97c.15,0,.26-.12.26-.26V2.81c0-.19-.15-.34-.34-.34h-6.79Zm6.03,10.89H1.3V1.4h5.22v2.18h0s7.14,0,7.14,0v9.78Z" />
                                                                <path className="st0" d="m5.96,4.36c0,.85-.51,1.59-1.24,1.92-.14.06-.29.11-.45.15-.14.03-.28.04-.43.04-1.17,0-2.12-.95-2.12-2.12s.95-2.11,2.11-2.12v2.12h2.13Z" />
                                                                <path className="st0" d="m4.85,3.93c-.37-.02-.6-.22-.62-.54h.28c.02.18.16.27.34.29v-.61l-.09-.02c-.3-.08-.48-.23-.48-.49,0-.29.24-.5.57-.53v-.21h.15v.21c.34.02.58.23.59.51h-.27c-.02-.15-.14-.25-.31-.27v.57l.08.02c.24.06.53.19.53.52,0,.29-.22.51-.61.54v.2h-.15v-.2Zm0-1.12v-.53c-.18.02-.29.13-.29.27,0,.16.15.23.29.27Zm.48.58c0-.16-.15-.22-.33-.28v.57c.2-.02.33-.13.33-.3Z" />
                                                            </svg>
                                                        </div>
                                                        <div className="header-menu-dropdown-wrap">
                                                            <div className="header-menu-dropdown-wrap-1">{t("header_24")}</div>
                                                            <div className="header-menu-dropdown-wrap-2">{t("header_25")}</div>
                                                        </div>
                                                        <div className="header-menu-dropdown-arrow">
                                                            <svg viewBox="0 0 18.5 13.64">
                                                                <path className="st0" d="M11.68,0.75l6.07,6.07l-6.07,6.07" ></path>
                                                                <path className="st1" d="M0.75,6.82h16.83"></path>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li onClick={() => turnoff()}>
                                        <div onClick={() => { Logout(); }} >
                                            {/* <div className='header-right-regist' onClick={() => { Logout() }}> */}
                                            <FontAwesomeIcon icon={faArrowRightFromBracket} style={{ fontSize: "25px", color: darkname ? "#ebe9e9" : "#595959", }} />
                                            {/* {t('header_54')} */}
                                        </div>
                                    </li>
                                </ul>
                            ) : (
                                <ul>
                                    <li onClick={() => turnoff()}>
                                        <Link to={`/login`} style={{ textDecoration: "none" }}>{t("header_55")} </Link>
                                    </li>
                                    <li onClick={() => turnoff()}>
                                        <Link to={`/regist`} style={{ textDecoration: "none" }} className="header-right-regist" >{t("header_56")}</Link>
                                    </li>
                                </ul>
                            )}
                    <div className="header-right-icon">
                        <ul>
                            <li onMouseEnter={onMouseEnterLan} onMouseLeave={onMouseLeaveLan}>
                                <div className={`header-right-icon-1 ${darkname}`}>
                                    <FontAwesomeIcon icon={faGlobe} style={{ fontSize: '20px', color: dark ? "#ebe9e9" : "#595959" }} />
                                </div>
                                <ul className={`header-right-icon-1-dropdown ${lan ? "lanEnter" : "lanLeave"} ${darkname}`} >
                                    <li>
                                        <Link onClick={() => { turnoff(); onChangeLang("ko-KR", 1); }} >
                                            <div className="header-right-icon-1-dropdown-1">
                                                <div className="header-right-icon-1-dropdown-ico">
                                                    <img src="/img/south-korea.png"></img>
                                                </div>
                                                <div className="header-right-icon-1-dropdown-wrap">
                                                    <div className="header-right-icon-1-dropdown-tit">한국어</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            onClick={() => { turnoff(); onChangeLang("en-US", 1); }} >
                                            <div className="header-right-icon-1-dropdown-1">
                                                <div className="header-right-icon-1-dropdown-ico">
                                                    <img src="/img/united-states.png"></img>
                                                </div>
                                                <div className="header-right-icon-1-dropdown-wrap">
                                                    <div className="header-right-icon-1-dropdown-tit">English</div>
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li onClick={() => { turnoff(); dispatch(checkMode(dark ? false : true)); }}>
                                <div className="header-right-icon-1">
                                    {
                                        dark
                                            ?
                                            <FontAwesomeIcon icon={faMoon} className="toggle-menu-moon-icon" />
                                            :
                                            <FontAwesomeIcon icon={faSun} className="toggle-menu-sun-icon" />
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="toggle-ham display-block" onClick={() => setToggle(true)}>
                    <svg viewBox="0 0 69.63 66.96">
                        <path d="M20.49,26.23H5.74C2.57,26.23,0,23.66,0,20.49V5.74C0,2.57,2.57,0,5.74,0h14.74c3.17,0,5.74,2.57,5.74,5.74v14.74C26.23,23.66,23.66,26.23,20.49,26.23z"></path>
                        <path d="M63.88,26.23H49.14c-3.17,0-5.74-2.57-5.74-5.74V5.74C43.4,2.57,45.97,0,49.14,0h14.74c3.17,0,5.74,2.57,5.74,5.74v14.74C69.63,23.66,67.06,26.23,63.88,26.23z" ></path>
                        <path d="M20.49,66.96H5.74C2.57,66.96,0,64.39,0,61.21V46.47c0-3.17,2.57-5.74,5.74-5.74h14.74c3.17,0,5.74,2.57,5.74,5.74v14.74 C26.23,64.39,23.66,66.96,20.49,66.96z"></path>
                        <path d="M63.88,66.96H49.14c-3.17,0-5.74-2.57-5.74-5.74V46.47c0-3.17,2.57-5.74,5.74-5.74h14.74c3.17,0,5.74,2.57,5.74,5.74v14.74 C69.63,64.39,67.06,66.96,63.88,66.96z"></path>
                    </svg>
                </div>
            </div>
            {
                toggle
                    ?
                    <Toggle setToggle={setToggle} onSelectType={onSelectType} login={login} dark={dark} Logout={Logout} onChangeLang={onChangeLang} />
                    :
                    ""
            }
        </div>
    );
}

function Toggle({ setToggle, onSelectType, login, dark, Logout, onChangeLang }) {
    const { t } = useTranslation();

    const darkname = dark ? "dark" : "";
    let dispatch = useDispatch();

    const [btn, setBtn] = useState(false);
    const [btn1, setBtn1] = useState(false);
    const [btn2, setBtn2] = useState(false);
    const [btn3, setBtn3] = useState(false);
    const [btn4, setBtn4] = useState(false);
    const [btn5, setBtn5] = useState(false);
    const [btn6, setBtn6] = useState(false);

    return (
        <div className={`toggle-menu ${darkname}`} data-aos="fade-left" data-aos-duration="500">
            <FontAwesomeIcon icon={faXmark} className="toggle-menu-x" onClick={() => setToggle(false)} />
            {
                login
                    ?
                    <div className="toggle-menu-top">
                        <Link to={``} className={`toggle-menu-top-1 ${darkname}`} onClick={() => { Logout(); setToggle(false); }} >{t("header_54")}</Link>
                    </div>
                    :
                    <div className="toggle-menu-top">
                        <Link to={`/login`} className={`toggle-menu-top-1 ${darkname}`} onClick={() => setToggle(false)} >{t("header_55")}</Link>
                        <Link to={`/regist`} className="toggle-menu-top-2" onClick={() => setToggle(false)}>{t("header_56")}</Link>
                    </div>
            }
            <ul className={`toggle-menu-mid-1 ${darkname}`}>
                <li>
                    <div onClick={() => { setBtn((btn) => !btn); setBtn1(false); setBtn2(false); setBtn3(false); setBtn4(false); setBtn5(false); setBtn6(false); }} >
                        <svg viewBox="0 0 381.1 381.1">
                            <path style={{ opacity: "0.4" }} className="st1" d="M354.9,144c-7.5-37.5-25.3-69.4-53.3-95.5C269.4,18.4,231,2.5,187.1,0.2c-19.2-1-38.1,1.3-56.7,6.6
                                C87.9,19.3,53.9,43.8,29.6,80.8c-24.7,37.7-34.3,79.1-27.5,124c-0.2,0.3-0.6,0.6-0.6,0.9c0.9,4.9,1.8,9.8,3,14.6
                                c11.5,46.4,36.9,83,76.8,109.4c25.6,16.9,53.9,26.2,84.4,28.8c4.4,0.4,8.9,0.1,13.4,0.1c0-2.4,0-4.5,0-6.6c0-1.6,0.1-3.2,0.1-4.9
                                c0-55.8,0-111.7,0-167.5c-0.1-8.4-0.8-6.9,6.9-6.9c57.4,0,114.8,0,172.2,0C357.2,163.1,356.8,153.4,354.9,144z"
                            ></path>
                            <path className="st0" d="M376.2,201.7c-56.7,0.1-113.5,0.1-170.2,0.1c-1.3,0-2.6,0-4.3,0c0,2,0,3.5,0,5c0,47.4,0,94.7,0,142.1
                                c0,8.5,0.1,17,0.1,25.5c0,2.2,0,4.4,0,6.8c18.5,0.1,36.2-2.4,53.3-8.1c61.6-20.7,101.6-62.4,119.8-124.8c2-7,2.5-14.4,3.8-21.6
                                c0.8-6.7,1.5-13.4,2.4-20.1C381.5,202.6,379.9,201.7,376.2,201.7z"
                            ></path>
                        </svg>
                        <p>{t("header_0")}</p>
                        <FontAwesomeIcon icon={faAngleDown} className="toggle-menu-mid-1-ico" />
                    </div>
                    {
                        btn
                            ?
                            <ul>
                                <li>
                                    <Link to={`/market`} className="toggle-menu-mid-1-menu" onClick={() => setToggle(false)} >{t("header_1")}</Link>
                                </li>
                            </ul>
                            :
                            ""
                    }
                </li>
                <li>
                    <div onClick={() => { setBtn3((btn3) => !btn3); setBtn1(false); setBtn2(false); setBtn(false); setBtn4(false); setBtn5(false); setBtn6(false); }}>
                        <svg viewBox="0 0 384 270.5">
                            <path className="st0" d="M384,123.2c-2.5,6.6-7.3,9.3-14.3,8.9c-4.2-0.2-8.5,0-13.3,0c0,1.5,0,2.8,0,4.1c0,13.5,0,27,0,40.5
                                c0,5.1-2.9,9-7.4,10.6c-4.3,1.5-9.6,0.5-12.1-3.3c-1.7-2.6-2.7-5.9-2.8-9c-0.3-14.1-0.1-28.2-0.1-42.8c-4.8,0-9.5,0-14.2,0
                                c-8.8,0-13.2-4.3-13.2-13c0-26.2,0-52.5,0-78.7c0-8.6,4.4-12.9,12.9-12.9c4.6,0,9.2,0,14.4,0c0-5.2-0.1-10.4,0-15.6
                                c0.1-6.4,4.2-11.1,9.9-11.7c6.8-0.7,12.3,3.9,12.5,10.8c0.2,5.3,0,10.7,0,16.5c4.6,0,9,0.2,13.3,0c6.9-0.4,11.8,2.2,14.3,8.9
                                C384,65.2,384,94.2,384,123.2z"
                            ></path>
                            <path style={{ opacity: "0.4" }} className="st1" d="M27.6,187.9c-5.4,0-10.3,0-15.3,0C4.5,187.8,0,183.3,0,175.5c0-27.4,0-54.7,0-82.1c0-7.8,4.6-12.2,12.4-12.2
                                c4.9,0,9.7,0,15.2,0c0-1.4,0-2.8,0-4.1c0-21.6,0-43.2,0-64.9C27.6,5,32,0.1,38.5,0c6.9-0.2,11.6,4.9,11.6,12.7
                                c0,21.4,0,42.7,0,64.1c0,1.3,0,2.7,0,4.3c5.4,0,10.5,0,15.6,0c7,0.1,11.7,4.4,11.7,11.3c0.1,28,0.1,56,0,84
                                c0,7-4.6,11.3-11.7,11.4c-5.1,0.1-10.2,0-15.7,0c0,5.5,0.1,10.8,0,16c-0.3,8.4-7.8,13.8-15.3,10.8c-4.6-1.8-7.1-5.4-7.2-10.4
                                C27.5,199,27.6,193.8,27.6,187.9z"
                            ></path>
                            <path style={{ opacity: "0.4" }} className="st1" d="M231.8,187c-5.3,0-10.3,0-15.2,0c-7.6-0.1-12.2-4.5-12.2-12.1c-0.1-17.7-0.1-35.5,0-53.2
                                c0-7.6,4.6-12,12.2-12.1c5,0,9.9,0,15.3,0c0-5.3,0-10.2,0-15.2c0-7.2,4.7-12.4,11.2-12.4c6.6,0,11.2,5,11.3,12.3c0,5,0,9.9,0,15.3
                                c5.3,0,10.2,0,15.2,0c7.8,0.1,12.4,4.5,12.4,12.3c0,17.6,0.1,35.2,0,52.9c0,7.7-4.7,12.2-12.5,12.3c-4.9,0-9.7,0-15.1,0
                                c0,1.4,0,2.8,0,4.1c0,22.5,0,45,0,67.5c0,4.7-1.8,8.3-5.9,10.5c-3.9,2.1-8,1.9-11.7-0.7c-3.6-2.5-4.9-6.1-4.9-10.4
                                c0-22.2,0-44.5,0-66.7C231.8,190,231.8,188.7,231.8,187z"
                            ></path>
                            <path className="st0" d="M152.2,242c0,5.7,0,10.9,0,16.1c-0.1,7.3-4.7,12.2-11.3,12.2c-6.6,0-11.1-5-11.2-12.3c0-5,0-10,0-15
                                c0-0.2-0.2-0.4-0.4-1.1c-4.7,0-9.5,0-14.4,0c-8.3-0.1-12.8-4.5-12.8-12.7c0-17.4,0-34.7,0-52.1c0-8.1,4.6-12.7,12.9-12.7
                                c4.7,0,9.5,0,14.7,0c0-5,0-10,0-14.9c0-7.3,4.5-12.4,11-12.6c6.6-0.1,11.4,5.1,11.5,12.5c0,4.9,0,9.7,0,15c5.1,0,10.1,0,15.1,0
                                c7.7,0,12.4,4.5,12.4,12.3c0.1,17.6,0.1,35.2,0,52.9c0,7.9-4.5,12.2-12.4,12.3C162.4,242.1,157.6,242,152.2,242z"
                            ></path>
                        </svg>
                        <p>{t("header_3")}</p>
                        <FontAwesomeIcon icon={faAngleDown} className="toggle-menu-mid-1-ico" />
                    </div>
                    {
                        btn3
                            ?
                            <ul>
                                <li>
                                    <Link to={`/spot/binance/BTCUSDT`} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); onSelectType(1); }}>{t("header_57")}</Link>
                                    <Link to={`/spot/okx/BTC-USDT`} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); onSelectType(0); }} >{t("header_58")}</Link>
                                    <Link to={`/spot/bybit/BTCUSDT`} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); onSelectType(2); }}>{t("header_58-1")}</Link>
                                </li>
                            </ul>
                            :
                            ""
                    }
                </li>
                <li>
                    <div onClick={() => { setBtn1((btn1) => !btn1); setBtn(false); setBtn2(false); setBtn3(false); setBtn4(false); setBtn5(false); setBtn6(false); }} >
                        <svg viewBox="0 0 384 270.5">
                            <path className="st0"
                                d="M384,123.2c-2.5,6.6-7.3,9.3-14.3,8.9c-4.2-0.2-8.5,0-13.3,0c0,1.5,0,2.8,0,4.1c0,13.5,0,27,0,40.5
                                c0,5.1-2.9,9-7.4,10.6c-4.3,1.5-9.6,0.5-12.1-3.3c-1.7-2.6-2.7-5.9-2.8-9c-0.3-14.1-0.1-28.2-0.1-42.8c-4.8,0-9.5,0-14.2,0
                                c-8.8,0-13.2-4.3-13.2-13c0-26.2,0-52.5,0-78.7c0-8.6,4.4-12.9,12.9-12.9c4.6,0,9.2,0,14.4,0c0-5.2-0.1-10.4,0-15.6
                                c0.1-6.4,4.2-11.1,9.9-11.7c6.8-0.7,12.3,3.9,12.5,10.8c0.2,5.3,0,10.7,0,16.5c4.6,0,9,0.2,13.3,0c6.9-0.4,11.8,2.2,14.3,8.9
                                C384,65.2,384,94.2,384,123.2z"
                            ></path>
                            <path style={{ opacity: "0.4" }} className="st1" d="M27.6,187.9c-5.4,0-10.3,0-15.3,0C4.5,187.8,0,183.3,0,175.5c0-27.4,0-54.7,0-82.1c0-7.8,4.6-12.2,12.4-12.2
                                c4.9,0,9.7,0,15.2,0c0-1.4,0-2.8,0-4.1c0-21.6,0-43.2,0-64.9C27.6,5,32,0.1,38.5,0c6.9-0.2,11.6,4.9,11.6,12.7
                                c0,21.4,0,42.7,0,64.1c0,1.3,0,2.7,0,4.3c5.4,0,10.5,0,15.6,0c7,0.1,11.7,4.4,11.7,11.3c0.1,28,0.1,56,0,84
                                c0,7-4.6,11.3-11.7,11.4c-5.1,0.1-10.2,0-15.7,0c0,5.5,0.1,10.8,0,16c-0.3,8.4-7.8,13.8-15.3,10.8c-4.6-1.8-7.1-5.4-7.2-10.4
                                C27.5,199,27.6,193.8,27.6,187.9z"
                            ></path>
                            <path style={{ opacity: "0.4" }} className="st1" d="M231.8,187c-5.3,0-10.3,0-15.2,0c-7.6-0.1-12.2-4.5-12.2-12.1c-0.1-17.7-0.1-35.5,0-53.2
                                c0-7.6,4.6-12,12.2-12.1c5,0,9.9,0,15.3,0c0-5.3,0-10.2,0-15.2c0-7.2,4.7-12.4,11.2-12.4c6.6,0,11.2,5,11.3,12.3c0,5,0,9.9,0,15.3
                                c5.3,0,10.2,0,15.2,0c7.8,0.1,12.4,4.5,12.4,12.3c0,17.6,0.1,35.2,0,52.9c0,7.7-4.7,12.2-12.5,12.3c-4.9,0-9.7,0-15.1,0
                                c0,1.4,0,2.8,0,4.1c0,22.5,0,45,0,67.5c0,4.7-1.8,8.3-5.9,10.5c-3.9,2.1-8,1.9-11.7-0.7c-3.6-2.5-4.9-6.1-4.9-10.4
                                c0-22.2,0-44.5,0-66.7C231.8,190,231.8,188.7,231.8,187z"
                            ></path>
                            <path className="st0" d="M152.2,242c0,5.7,0,10.9,0,16.1c-0.1,7.3-4.7,12.2-11.3,12.2c-6.6,0-11.1-5-11.2-12.3c0-5,0-10,0-15
                                c0-0.2-0.2-0.4-0.4-1.1c-4.7,0-9.5,0-14.4,0c-8.3-0.1-12.8-4.5-12.8-12.7c0-17.4,0-34.7,0-52.1c0-8.1,4.6-12.7,12.9-12.7
                                c4.7,0,9.5,0,14.7,0c0-5,0-10,0-14.9c0-7.3,4.5-12.4,11-12.6c6.6-0.1,11.4,5.1,11.5,12.5c0,4.9,0,9.7,0,15c5.1,0,10.1,0,15.1,0
                                c7.7,0,12.4,4.5,12.4,12.3c0.1,17.6,0.1,35.2,0,52.9c0,7.9-4.5,12.2-12.4,12.3C162.4,242.1,157.6,242,152.2,242z"
                            ></path>
                        </svg>
                        <p>{t("header_8")}</p>
                        <FontAwesomeIcon icon={faAngleDown} className="toggle-menu-mid-1-ico" />
                    </div>
                    {btn1 ? (
                        <ul>
                            <li>
                                <Link to={`/futures/binance/BTCUSDT`} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); onSelectType(1); }} >{t("header_59")}</Link>
                                <Link to={`/futures/okx/BTC-USDT-SWAP`} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); onSelectType(0); }} >{t("header_60")}</Link>
                                <Link to={`/futures/bybit/BTCUSDT`} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); onSelectType(2); }} >{t("header_60-1")}</Link>
                            </li>
                        </ul>
                    ) : (
                        ""
                    )}
                </li>
            </ul>
            <ul className={`toggle-menu-mid-1 ${darkname}`}>
                {
                    login
                        ?
                        <>
                            <li>
                                <div onClick={() => { setBtn4((btn4) => !btn4); setBtn1(false); setBtn2(false); setBtn3(false); setBtn(false); setBtn5(false); setBtn6(false); }} >
                                    <svg viewBox="0 0 397.4 417.3">
                                        <path d="M194.3,417.3c-17.3,0-25.8,0-48.1-0.1c-33-0.1-63.9-10.6-89.5-30.3c-25.6-19.8-43.5-47-51.7-78.8
                                        c-2.7-10.3-4.1-21.9-4.6-36.3c-0.5-14.9-0.4-30-0.3-44.7c0-5.8,0.1-11.5,0.1-17.3c0-11.7,8-20.3,19.1-20.3c11,0,19,8.6,19.1,20.3
                                        c0,9.8,0,19.5,0,29.3c0,10.4,0,20.9,0,31.3c0.1,50.2,31.1,91.2,79.1,104.6c8.8,2.5,18.5,3.7,28.9,3.9c18.2,0.2,27.8,0.3,51.6,0.3
                                        c15.7,0,31.4,0,47.1-0.1l16.2,0c54-0.1,89.1-39,97.1-92.5c0.7-4.5,1-9.6,1-16c0.1-42.6,0.1-84.4,0-124.1
                                        c-0.1-52.4-25.9-96.2-77.2-106.4c-6.3-1.3-13.1-1.9-21-1.9c-18.3-0.1-37.6-0.1-62.5-0.1c-11.9,0-13.8,0-25.7,0l-26.2,0
                                        c-26.1,0-49.6,8.5-70,25.1l14.5,0c0.8,0,1.6,0,2.3,0c0.8,0,1.6,0,2.4,0c1.8,0,3.2,0,4.5,0.1c9.6,0.5,17.1,7.9,17.9,17.7
                                        c0.8,9.2-5.9,17.9-15.2,19.8c-1.8,0.4-3.8,0.5-6.2,0.5c-11.3,0-22.5,0-33.8,0c-8.3,0-16.6,0-24.9,0c-13.6,0-21.3-7.8-21.3-21.3
                                        c0-5.8,0-11.6,0-17.4c0-13.1,0.1-26.6-0.1-39.9c-0.2-11.6,4.7-19,15-22.5c0.3-0.1,0.6-0.2,1-0.2h6.4c0.4,0,0.8,0.1,1.2,0.2
                                        c11.4,4.8,14.7,9.8,14.7,22.3l0,9.1C72.4,17.9,91.9,8.5,113.1,3.7c4.9-1.1,9.9-1.9,14.7-2.6c2.2-0.3,4.4-0.7,6.6-1.1
                                        c0.2,0,0.3,0,0.5,0h137.8c0.2,0,0.3,0,0.5,0c1.5,0.2,3,0.5,4.6,0.7c3.3,0.5,6.6,1,9.9,1.6c27.6,4.7,43.2,17.7,64.3,37.5
                                        c21,19.9,35.4,44.7,41.5,71.9c1.1,5.1,2,10.3,2.8,15.4c0.4,2.3,0.8,4.7,1.2,7c0,0.2,0,0.3,0,0.5v148.6c0,0.2,0,0.4,0,0.5
                                        c-0.4,2.3-0.8,4.5-1.2,6.8c-0.8,4.9-1.7,9.9-2.8,14.8c-6.1,27.2-20.5,52.1-41.5,72c-21.1,19.9-36.7,32.8-64.2,37.4
                                        c-9.1,1.5-18,2.3-26.4,2.3C240.5,417.3,218.6,417.3,194.3,417.3z"
                                        ></path>
                                        <path d="M262,290.9c-4.3,0-8.5-1.5-12-4.3c-1.2-0.9-2.2-1.9-3.1-2.9l-0.3-0.3c-5.6-5.6-11.3-11.3-16.9-16.9
                                        c-13.9-13.9-28.2-28.3-42.5-42.3c-5.2-5.1-7.6-10.9-7.5-18.2c0.1-18.3,0.1-36.8,0.1-54.8c0-12.2,0-24.5,0-36.7
                                        c0-11,8.3-19.6,18.9-19.6c2.1,0,4.1,0.3,6.2,1c7.9,2.6,13,9.8,13.1,18.4c0,9.8,0,19.8,0,29.5c0,4.2,0,8.4,0,12.6
                                        c0,3.4,0,6.9,0,10.3c0,10.4,0,21.2,0.1,31.8c0,1.2,0.7,2.9,1.6,3.7c18.3,18.5,37.1,37.3,54.7,54.8c5.6,5.5,7.7,11.5,6.4,18.2
                                        C278.9,284.4,271.3,290.9,262,290.9z"
                                        ></path>
                                    </svg>
                                    <p>{t("header_15")}</p>
                                    <FontAwesomeIcon icon={faAngleDown} className="toggle-menu-mid-1-ico" />
                                </div>
                                {
                                    btn4
                                        ?
                                        <ul>
                                            <li>
                                                <Link to={`/history`} state={{ num: 0 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); }}>{t("header_16")}</Link>
                                                <Link to={`/history`} state={{ num: 1 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); }}>{t("header_18")}</Link>
                                                <Link to={`/history`} state={{ num: 2 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); }}>{t("header_20")}</Link>
                                                <Link to={`/history`} state={{ num: 3 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); }}>{t("header_22")}</Link>
                                                <Link to={`/history`} state={{ num: 4 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); }}>{t("header_24")}</Link>
                                            </li>
                                        </ul>
                                        :
                                        ""
                                }
                            </li>
                            <li>
                                <div
                                    onClick={() => { setBtn5((btn5) => !btn5); setBtn1(false); setBtn2(false); setBtn3(false); setBtn4(false); setBtn(false); setBtn6(false); }} >
                                    <svg viewBox="0 0 375.6 424.9">
                                        <path d="M185.1,424.9c-39.6,0-77.2-0.1-121.9-0.2c-26.3-0.1-46.2-12.8-57.6-36.8c-1.9-4-3-8.3-4-12.4c-0.5-1.8-0.9-3.7-1.4-5.5
                                        C0,369.8,0,369.5,0,369.2V55.6c0-0.4,0.1-0.7,0.2-1.1c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.2,0.2-0.4,0.2-0.5c4-26.7,23.6-47.1,49.9-52
                                        c4.3-0.8,8.9-1.2,13.7-1.2C123.3,0,167,0,214.3,0c29.4,0,60.2,0,96.8,0c37.4,0,64.5,27.1,64.5,64.4c0,76.7,0,225.4,0,296
                                        c0,5.9-0.4,10.7-1.4,14.9c-6.4,29.5-31.3,49.4-61.9,49.5C265.3,424.9,224.7,424.9,185.1,424.9z M186,29.7c-37.3,0-74.7,0-121.8,0
                                        c-20.6,0-34.4,13.8-34.5,34.3c-0.1,76.8-0.1,226.6,0.1,296.8c0,5.5,1.2,11.2,3.3,15.7c5.9,12.5,16.2,18.6,31.5,18.6
                                        c54.1,0,95.3,0,138.2,0l108.2,0c1.7,0,3.6,0,5.3-0.2c16.2-1.5,28.5-14.1,29.3-29.8c0.5-9.9,0.4-40.1,0.4-62.2c0-8.4,0-15.7,0-19.7
                                        c0-2.6,0-5.2,0-7.8l0-0.8h-1.7c-9.2,0-16.1,0-22.3,0c-5,0-9.4,0-14.3,0c-7.5,0-15,0-24.3-0.1c-21.5-0.1-40.6-10-52.4-27.2
                                        c-11.6-16.8-14-37.6-6.7-57.1c9.1-24.2,32.3-39.8,59.1-39.9c10.6,0,18-0.1,24.9-0.1c4.7,0,9.1,0,14,0c6.1,0,12.9,0,21.9,0h1.7
                                        V65.1c0-0.3,0-0.5,0-0.8c0-0.5,0-0.9,0-1.3c-0.1-0.9-0.2-1.7-0.2-2.6c-0.2-2-0.3-3.9-0.8-5.7c-4.2-15.9-16.3-25-33.2-25
                                        C263,29.8,224.6,29.7,186,29.7z M300.9,180c-5.1,0-10.6,0.1-20.9,0.4c-8.7,0.3-16.6,4.2-22.3,11c-5.9,7.1-8.6,16.3-7.3,25.4
                                        c2.3,16.7,15.8,27.9,33.6,28c10.9,0,18.7,0.1,25.8,0.1c4.7,0,9.2,0,14.1,0c6.1,0,12.9,0,22,0V180l-12.2,0c-3,0-5.9,0-8.9,0l-3.6,0
                                        c-5.3,0-9.2,0-12.7-0.1C305.7,180,303.3,180,300.9,180z"
                                        ></path>
                                    </svg>
                                    <p>{t("header_28")}</p>
                                    <FontAwesomeIcon icon={faAngleDown} className="toggle-menu-mid-1-ico" />
                                </div>
                                {btn5 ? (
                                    <ul>
                                        <li>
                                            <Link to={`/wallet`} state={{ num: 0 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); dispatch(changeWalletPage(-1)); }}>{t("header_29")}</Link>
                                            <Link to={`/wallet`} state={{ num: 1 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); dispatch(changeWalletPage(-1)); }}>{t("header_31")}</Link>
                                            <Link to={`/wallet`} state={{ num: 2 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); dispatch(changeWalletPage(-1)); }}>{t("header_33")}</Link>
                                            <Link to={`/wallet`} state={{ num: 3 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); dispatch(changeWalletPage(-1)); }}>{t("header_35")}</Link>
                                            <Link to={`/wallet`} state={{ num: 4 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); dispatch(changeWalletPage(-1)); }}>{t("header_37")}</Link>
                                            <Link to={`/wallet`} state={{ num: 5 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); dispatch(changeWalletPage(-1)); }}>{t("header_41")}</Link>
                                            <Link to={`/wallet`} state={{ num: 6 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); dispatch(changeWalletPage(-1)); }}>{t("header_39")}</Link>
                                        </li>
                                    </ul>
                                ) : (
                                    ""
                                )}
                            </li>
                            <li>
                                <div
                                    onClick={() => { setBtn6((btn6) => !btn6); setBtn1(false); setBtn2(false); setBtn3(false); setBtn4(false); setBtn5(false); setBtn(false); }} >
                                    <svg viewBox="0 0 335.5 335.5">
                                        <path d="M76.1,152.6c-19.5,0-39.1,0-58.6,0C6.2,152.6,0,146.5,0,135.2C0,95.9,0,56.6,0,17.3C0,6.2,6.2,0,17.2,0
                                C56.6,0,96,0,135.4,0c10.9,0,17.1,6.2,17.1,17c0,39.5,0,79.1,0,118.6c0,10.8-6.3,17-17.2,17C115.6,152.6,95.9,152.6,76.1,152.6z
                                    M121.9,30.7c-30.5,0-60.8,0-91.2,0c0,30.5,0,60.8,0,91.2c30.5,0,60.8,0,91.2,0C121.9,91.5,121.9,61.3,121.9,30.7z"
                                        ></path>
                                        <path d="M182.9,76.1c0-19.5,0-39.1,0-58.6C182.9,6.1,189,0,200.3,0c39.3,0,78.6,0,117.9,0c11.1,0,17.3,6.1,17.3,17.2
                                c0,39.4,0,78.8,0,118.2c0,10.9-6.2,17.1-17,17.1c-39.5,0-79.1,0-118.6,0c-10.6,0-16.9-6.3-16.9-16.8
                                C182.9,115.8,182.9,95.9,182.9,76.1z M213.7,30.6c0,30.6,0,60.9,0,91.2c30.5,0,60.8,0,91.1,0c0-30.5,0-60.8,0-91.2
                                C274.3,30.6,244.1,30.6,213.7,30.6z"
                                        ></path>
                                        <path d="M0,258.9c0-19.5,0-39.1,0-58.6c0-11.3,6.1-17.4,17.4-17.4c39.3,0,78.6,0,117.9,0c11.1,0,17.2,6.2,17.2,17.2
                                c0,39.4,0,78.8,0,118.2c0,10.8-6.2,17.1-17.1,17.1c-39.5,0-79.1,0-118.6,0C6.3,335.5,0,329.2,0,318.6C0,298.7,0,278.8,0,258.9z
                                    M30.8,213.6c0,30.6,0,60.9,0,91.2c30.5,0,60.8,0,91,0c0-30.5,0-60.8,0-91.2C91.4,213.6,61.2,213.6,30.8,213.6z"
                                        ></path>
                                        <path d="M183,261.3c-0.8-41.6,31-76.3,73.5-78.3c41.6-1.9,77.4,31.1,78.9,73.9c1.5,42-31.4,77.2-73.9,78.4
                                C220.7,336.6,184.7,305.2,183,261.3z M213.5,259c0,25.4,20.4,45.8,45.7,45.9c25.3,0.1,45.7-20.5,45.7-45.9
                                c0-24.9-20.5-45.5-45.4-45.6C234.2,213.4,213.5,233.8,213.5,259z"
                                        ></path>
                                    </svg>
                                    <p>{t("header_43")}</p>
                                    <FontAwesomeIcon icon={faAngleDown} className="toggle-menu-mid-1-ico" />
                                </div>
                                {
                                    btn6
                                        ?
                                        <ul>
                                            <li>
                                                <Link to={`/dashboard`} state={{ num: 0 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); }}>{t("header_44")}</Link>
                                                <Link to={`/dashboard`} state={{ num: 1 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); }}>{t("header_46")}</Link>
                                                <Link to={`/dashboard`} state={{ num: 2 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); }}>{t("header_48")}</Link>
                                                <Link to={`/dashboard`} state={{ num: 3 }} className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); }}>{t("header_50")}</Link>
                                            </li>
                                        </ul>
                                        :
                                        ""
                                }
                            </li>
                        </>
                        :
                        ""
                }
                <li>
                    <div
                        onClick={() => { setBtn2((btn2) => !btn2); setBtn1(false); setBtn(false); setBtn3(false); setBtn4(false); setBtn5(false); setBtn6(false); }}>
                        <svg viewBox="0 0 419 419" style={{ transition: "all .2s ease-in-out" }}>
                            <path d="M209.5,419C93.98,419,0,325.02,0,209.5S93.98,0,209.5,0S419,93.98,419,209.5S325.02,419,209.5,419z M209.5,28
                                    C109.42,28,28,109.42,28,209.5S109.42,391,209.5,391S391,309.58,391,209.5S309.58,28,209.5,28z"
                            ></path>
                            <path d="M209.5,419c-31.03,0-59.35-23.13-79.77-65.14c-18.85-38.79-29.23-90.06-29.23-144.36c0-54.3,10.38-105.56,29.23-144.36
                                    C150.15,23.13,178.47,0,209.5,0s59.35,23.13,79.77,65.14c18.85,38.79,29.23,90.06,29.23,144.36c0,54.3-10.38,105.56-29.23,144.36
                                    C268.85,395.86,240.53,419,209.5,419z M209.5,28c-19.44,0-39.33,18-54.58,49.38c-17.04,35.06-26.42,81.98-26.42,132.12
                                    s9.38,97.06,26.42,132.12C170.17,373,190.06,391,209.5,391s39.33-18,54.58-49.38c17.04-35.06,26.42-81.98,26.42-132.12
                                    s-9.38-97.06-26.42-132.12C248.83,46,228.94,28,209.5,28z"
                            ></path>
                            <rect x="14.23" y="195.5" width="390.77" height="28"></rect>
                        </svg>
                        <p style={{ paddingLeft: "12px" }}>{t("header_61")}</p>
                        <FontAwesomeIcon icon={faAngleDown} className="toggle-menu-mid-1-ico" />
                    </div>
                    {
                        btn2
                            ?
                            <ul>
                                <li>
                                    <Link className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); onChangeLang("ko-KR", 1); }}>
                                        <div className="header-right-icon-1-dropdown-1">
                                            <div className="header-right-icon-1-dropdown-ico">
                                                <img src="/img/south-korea.png"></img>
                                            </div>
                                            <div className="header-right-icon-1-dropdown-wrap">
                                                <div className="header-right-icon-1-dropdown-tit">한국어</div>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link className="toggle-menu-mid-1-menu" onClick={() => { setToggle(false); onChangeLang("en-US", 1); }}>
                                        <div className="header-right-icon-1-dropdown-1">
                                            <div className="header-right-icon-1-dropdown-ico">
                                                <img src="/img/united-states.png"></img>
                                            </div>
                                            <div className="header-right-icon-1-dropdown-wrap">
                                                <div className="header-right-icon-1-dropdown-tit">English</div>
                                            </div>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                            :
                            ""
                    }
                </li>
                <li>
                    {
                        dark
                            ?
                            <div onClick={() => { setToggle(false); dispatch(checkMode(false)); }}>
                                <FontAwesomeIcon icon={faMoon} className="toggle-menu-moon-icon-1" />
                                <p>{t("header_62")}</p>
                            </div>
                            :
                            <div onClick={() => { setToggle(false); dispatch(checkMode(true)); }} >
                                <FontAwesomeIcon icon={faSun} className="toggle-menu-sun-icon-1" />
                                <p>{t("header_62")}</p>
                            </div>
                    }
                </li>
            </ul>
        </div>
    );
}

export default Header;

import '../../css/futures.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleDollar, faWindowRestore, faWindowMaximize, faJetFighter, faAlignLeft, faArrowUp, faArrowDown, faRotate, faFlag } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { findSmallest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "../../common/function"

let arr = []
let prePrice = 0
let pre_symbol = ""

let current = 0
let bol = false
let okx_cnt = 0
let socket_close = true
let maxPoint = 0;

function Fsection7({ tab1, tab3, tab4, setTab4, setTab3, setLastBuy, setLastSell, data, setCheckordertab, setSpeedorder, symbol, instrumentsData, setMax, max, tradeVolume, setCurrentPrice, currentPrice, checkisPlaying, btnbtn, markPrice, qtypoint }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let Alert = useSelector((state) => { return state.Alert });
    
    let Reload = useSelector((state) => { return state.Reload.value })
    const darkname = dark ? 'dark' : "";

    const [windowtype, setWindowtype] = useState(true);
    const [selectTrade, setSelectTrade] = useState("")

    const [selectVolume, setSelectVolume] = useState("1")
    const [smallNum, setSmallNum] = useState("1")
    const [checkup, setCheckup] = useState([]);
    const [checkdown, setCheckdown] = useState([]);
    const [up, setUp] = useState([]);
    const [down, setDown] = useState([]);
    const [tradePreData, setTradePreData] = useState([])
    const [tradeData, setTradeData] = useState([])
    const [isClicked1, setIsClicked1] = useState(true);
    const [priceDirect, setPriceDirect] = useState(false)

    const [isPlaying, setIsPlaying] = useState(false);
    const [close, setClose] = useState(false)

    current = currentPrice ? Number(currentPrice) : Number(data.lastPrice)

    const playAudio = () => {
        setIsPlaying(true);
        setTimeout(() => {
            setIsPlaying(false);
        }, 1000)
    };

    const handleSelectTrade = (event) => {
        setSelectTrade(event.target.value);
    };

    const onChangeVolume = (e) => {
        setSelectVolume(e.target.value)
    }

    useEffect(() => {
        if (window.innerWidth >= 700) setWindowtype(true)
        else {
            setWindowtype(false)
            setTab3(true)
            setTab4(1)
        }
        const handleResize = () => {
            if (window.innerWidth >= 700) setWindowtype(true)
            else {
                setWindowtype(false)
                setTab3(true)
                setTab4(1)
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (prePrice != currentPrice) {
            if (currentPrice > prePrice) setPriceDirect(true)
            else setPriceDirect(false)
            prePrice = currentPrice
        }
    }, [data])

    useEffect(() => {
        arr = []
        setTradeData([])
    }, [symbol])

    useEffect(() => {
        if (okx_cnt) {
            bol = true
            setClose(e => !e)
        }
    }, [Reload])

    const change_socket_close = () => {
        socket_close = false
        setTimeout(() => {
            socket_close = true
        }, 3000)
    }

    useEffect(() => {
        let tradeA = []
        let tradeB = []
        let newtradeA = []
        let newtradeB = []
        maxPoint = 0;
        let cntVolume = true;
        // setIsClicked1(bol ? false : true)

        let socket = new WebSocket(`wss://ws.okx.com:8443/ws/v5/public`);
        const subscribeMessage = JSON.stringify({
            op: "subscribe",
            args: [
                {
                    channel: "trades",
                    instId: symbol
                },
                {
                    channel: "books",
                    instId: symbol
                }
            ]
        });

        socket.onopen = () => {
            socket.send(subscribeMessage);
        };

        socket.onmessage = function (event) {
            if (event.data) {
                let tmp = JSON.parse(event.data);
                if (tmp.data) {
                    pre_symbol = symbol
                    if (tmp.arg.channel == "trades") {
                        setTradePreData(tmp.data[0])
                    }
                    else {
                        let tmp = JSON.parse(event.data);
                        let tradeArr = tmp.data

                        if (tradeArr && tradeArr[0] && tradeArr[0].asks && tradeArr[0].bids) {
                            let asks = tradeArr[0].asks;
                            let bids = tradeArr[0].bids;

                            if (asks) {
                                for (let i = 0; i < asks.length; i++) {
                                    let cnt = false
                                    for (let j = 0; j < tradeA.length; j++) {
                                        if (asks[i][0] === tradeA[j][0]) {
                                            tradeA[j] = asks[i]
                                            cnt = true
                                            break;
                                        }
                                    }
                                    if (!cnt) {
                                        tradeA.push(asks[i])
                                    }
                                    if (i >= 0 && i <= 3) {
                                        maxPoint = Math.max(maxPoint, findSmallest(asks[i][0]))
                                    }
                                }
                            }
                            if (bids) {
                                for (let i = 0; i < bids.length; i++) {
                                    let cnt = false
                                    for (let j = 0; j < tradeB.length; j++) {
                                        if (bids[i][0] === tradeB[j][0]) {
                                            tradeB[j] = bids[i]
                                            cnt = true
                                            break;
                                        }
                                    }
                                    if (!cnt) {
                                        tradeB.push(bids[i])
                                    }
                                }
                            }

                            newtradeA = tradeA ? tradeA.filter((p) => Number(p[1]) > 0 && Number(p[0]) >= current) : []
                            newtradeB = tradeB ? tradeB.filter((p) => Number(p[1]) > 0 && Number(p[0]) <= current) : []

                            newtradeA.sort((a, b) => Number(a[0]) - Number(b[0]))
                            newtradeB.sort((a, b) => Number(b[0]) - Number(a[0]))

                            newtradeA = newtradeA.splice(0, 500)
                            newtradeB = newtradeB.splice(0, 500)

                            setUp(newtradeA)
                            setDown(newtradeB)
                            tradeA = newtradeA
                            tradeB = newtradeB

                            setSmallNum(makeSamllest(maxPoint))
                            if (cntVolume) {
                                setSelectVolume(makeSamllest(maxPoint))
                                cntVolume = false
                            }
                        }
                    }
                }
            }
        }

        socket.onclose = function (event) {
            if (bol) {
                console.log("okx_orderbook close")
                bol = false
            }
            else {
                if (pre_symbol == symbol && socket_close) {
                    console.log("okx_orderbook close")
                    change_socket_close()
                    setClose(e => !e)
                }
            }
        };

        socket.error = function (error) {
            window.location.reload()
        }

        return () => {
            socket.close();
        }
    }, [symbol, close])

    useEffect(() => {
        let newup = []
        let newdown = []

        if (up) {
            for (let i = 0; i < up.length; i++) {
                let num = 0
                let check = true
                if (Number.isInteger(Number(selectVolume))) {
                    num = Number(up[i][0]) - (Number(up[i][0]) % Number(selectVolume))
                }
                else {
                    try {
                        let decimalPart = selectVolume.split('.')[1];
                        num = Number(up[i][0]).toFixed(decimalPart ? decimalPart.length : 0)
                    } catch (error) {
                    }
                }

                for (let j = 0; j < newup.length; j++) {
                    if (newup[j][0] === num) {
                        newup[j][1] += Number(up[i][1])
                        check = false
                    }
                }
                if (check) {
                    newup.push([num, Number(up[i][1])])
                }
            }
        }
        if (down) {
            for (let i = 0; i < down.length; i++) {
                let num = 0
                let check = true

                if (Number.isInteger(Number(selectVolume))) {
                    num = Number(down[i][0]) - (Number(down[i][0]) % Number(selectVolume))
                }
                else {
                    try {
                        let decimalPart = selectVolume.split('.')[1];
                        num = Number(down[i][0]).toFixed(decimalPart ? decimalPart.length : 0)
                    } catch (error) {
                    }
                }

                for (let j = 0; j < newdown.length; j++) {
                    if (newdown[j][0] === num) {
                        newdown[j][1] += Number(down[i][1])
                        check = false
                    }
                }
                if (check) {
                    newdown.push([num, Number(down[i][1])])
                }
            }
        }

        newup.sort((a, b) => Number(a[0]) - Number(b[0]));
        newdown.sort((a, b) => Number(b[0]) - Number(a[0]));

        if (data.priceChangePercent >= 0) {
            newup = newup.filter((p) => p[0] >= current)
            newdown = newdown.filter((p) => p[0] < current)
        }
        else {
            newup = newup.filter((p) => p[0] > current)
            newdown = newdown.filter((p) => p[0] <= current)
        }

        if (newup && newdown && newup.length >= 10 && newdown.length >= 10) {
            setIsClicked1(false)
            setMax(maxPoint)
        }

        setCheckup(newup)
        setCheckdown(newdown)

    }, [selectVolume, up, down])

    useEffect(() => {
        if (tradePreData) {
            if (arr && arr.length <= 22) {
                if (Number(tradePreData.sz) * Number(instrumentsData?.ctVal ?? 1) * Number(tradePreData.px) >= Number(selectTrade)) {
                    arr.unshift(tradePreData)
                }
            }
            else {
                arr.splice(22, 1)
                if (Number(tradePreData.sz) * Number(instrumentsData?.ctVal ?? 1) * Number(tradePreData.px) >= Number(selectTrade)) {
                    arr.unshift(tradePreData)
                }
            }
            if (Alert[0] == "ON" && !checkisPlaying && Number(tradePreData.sz) * Number(tradePreData.p) >= Number(tradeVolume)) {
                playAudio()
            }

            setCurrentPrice(tradePreData.px ? tradePreData.px : 0.00000000001)
            current = tradePreData.px ? tradePreData.px : 0.00000000001
            setTradeData(arr)
        }
    }, [tradePreData, selectTrade])

    return (
        <div className={tab1 ? `futures-section7 ${darkname}` : `futures-section7-check ${darkname}`}>
            {
                isPlaying
                    ?
                    <audio autoPlay={isPlaying}>
                        <source src="/audio/Sound.1.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            <div className={`futures-mid-top-3 ${darkname}`}>
                {
                    tab3
                        ?
                        <div className='futures-mid-top-3-box display-none-700'>
                            <FontAwesomeIcon icon={faFilterCircleDollar} className='futures-mid-top-3-ico' />
                            <select className={`futures-mid-top-3-box-select ${darkname}`} style={{ paddingLeft: "5px", width: "50px" }} value={selectTrade} onChange={handleSelectTrade}>
                                <option value={0}><span>All</span></option>
                                <option value={1000}><span>1k+</span></option>
                                <option value={5000}><span>5k+</span></option>
                                <option value={10000}><span>10k+</span></option>
                                <option value={50000}><span>50k+</span></option>
                            </select>
                        </div>
                        :
                        <div className='futures-mid-top-3-box'>
                        </div>
                }

                <div className={tab4 === 0 ? `futures-mid-top-3-box ${darkname} display-none-700 active` : `futures-mid-top-3-box display-none-700 ${darkname}`} onClick={() => { setTab4(0); setTab3(false) }}>
                    <FontAwesomeIcon icon={faWindowRestore} className='futures-mid-top-3-ico' />
                    <span style={{ paddingLeft: "5px" }}>{t('futures_64')}</span>
                </div>
                <div className={tab4 === 1 ? `futures-mid-top-3-box ${darkname} display-none-700 active` : `futures-mid-top-3-box display-none-700 ${darkname}`} onClick={() => { setTab4(1); setTab3(true) }}>
                    <FontAwesomeIcon icon={faWindowMaximize} className='futures-mid-top-3-ico' />
                    <span style={{ paddingLeft: "5px" }}>{t('futures_65')}</span>
                </div>
                <div className={tab4 === 2 ? `futures-mid-top-3-box ${darkname} display-none-700 active` : `futures-mid-top-3-box display-none-700 ${darkname}`} onClick={() => { setTab4(2); setTab3(false) }}>
                    <FontAwesomeIcon icon={faJetFighter} className='futures-mid-top-3-ico' />
                    <span style={{ paddingLeft: "5px" }}>{t('futures_66')}</span>
                </div>
                <div className='futures-mid-top-3-box-1'>
                    <FontAwesomeIcon icon={faAlignLeft} className='futures-mid-top-3-ico' />
                    <select className={`futures-mid-top-3-box-select ${darkname}`} style={{ paddingLeft: "5px", width: "50px" }} value={selectVolume} onChange={onChangeVolume}>
                        <option value={Number(smallNum).toFixed(max >= 0 ? max : 0)}><span>{smallNum}</span></option>
                        <option value={(Number(smallNum) * 10).toFixed(max - 1 >= 0 ? max - 1 : 0)}><span>{changeNum((Number(smallNum) * 10).toFixed(max - 1 >= 0 ? max - 1 : 0))}</span></option>
                        <option value={(Number(smallNum) * 100).toFixed(max - 2 >= 0 ? max - 2 : 0)}><span>{changeNum((Number(smallNum) * 100).toFixed(max - 2 >= 0 ? max - 2 : 0))}</span></option>
                    </select>
                </div>
            </div>
            {
                isClicked1
                    ?
                        <div className='spot-loading'>
                            <ClipLoader color={"#F5AC1D"} />
                        </div>
                    :
                    tab4 === 0
                        ?
                        <Dual up={checkup} down={checkdown} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} darkname={darkname} dark={dark} max={max} currentPrice={currentPrice} instrumentsData={instrumentsData} priceDirect={priceDirect} btnbtn={btnbtn} qtypoint={qtypoint} />
                        :
                        tab4 === 1
                            ?
                            <Single up={checkup} down={checkdown} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} tradeData={tradeData} setCheckordertab={setCheckordertab} darkname={darkname} max={max} priceDirect={priceDirect} currentPrice={currentPrice} instrumentsData={instrumentsData} btnbtn={btnbtn} markPrice={markPrice} qtypoint={qtypoint} />
                            :
                            <Speed up={checkup} down={checkdown} data={data} setSpeedorder={setSpeedorder} darkname={darkname} max={max} currentPrice={currentPrice} instrumentsData={instrumentsData} btnbtn={btnbtn} priceDirect={priceDirect} markPrice={markPrice} qtypoint={qtypoint} />
            }
        </div>
    )
}

function Speed({ up, down, data, setSpeedorder, max, currentPrice, instrumentsData, btnbtn, priceDirect, markPrice, qtypoint, darkname }) {
    const { t } = useTranslation();

    let sum1 = 0;
    let sum2 = 0;

    const up8 = up ? up.slice(0, 10) : [];
    const down8 = down ? down.slice(0, 10) : [];

    for (let i = 0; i < up8.length; i++) {
        sum1 += btnbtn === 0 ? Number(up8[i][1]) : Number(up8[i][1]) * Number(up8[i][0])
    }

    for (let i = 0; i < down8.length; i++) {
        sum2 += btnbtn === 0 ? Number(down8[i][1]) : Number(down8[i][1]) * Number(down8[i][0])
    }

    let sum3 = Math.max(sum1, sum2);
    let sum5 = 0;
    let sum6 = 0;

    return (
        <div className='futures-speed'>
            <div className='futures-speed-top'>
                <div className='futures-speed-top-1'>
                    <div className='futures-speed-top-1-1'>{t('futures_67')}</div>
                    <div className='futures-speed-top-1-2'>{t('futures_68')}({btnbtn === 0 ? t('futures_150') : "USDT"})</div>
                    <div className='futures-speed-top-1-2'>{t('futures_69')}</div>
                    <div className='futures-speed-top-1-1'>{t('futures_71')}</div>
                </div>
            </div>
            <div className='futures-speed-mid'>
                <div className='futures-speed-mid-1'>
                    {
                        [...up8].reverse().map(function (a, i) {
                            sum1 -= sum5
                            sum5 = btnbtn === 0 ? Number(a[1]) : Number(a[1]) * Number(a[0])
                            return (
                                <SpeedShort key={i} price={a} setSpeedorder={setSpeedorder} max={max} btnbtn={btnbtn} instrumentsData={instrumentsData} sum1={sum1} sum3={sum3} qtypoint={qtypoint} darkname={darkname} />
                            )
                        })}
                </div>
                <div className='futures-speed-mid-2'>
                    {
                        priceDirect
                            ?
                            <div className='futures-speed-mid-2-2 green'><FontAwesomeIcon icon={faArrowUp} style={{ marginRight: "3px", fontSize: "15px" }} />
                                {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                            :
                            <div className='futures-speed-mid-2-2 red'><FontAwesomeIcon icon={faArrowDown} style={{ marginRight: "3px", fontSize: "15px" }} />
                                {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                    }
                    <div className='futures-orderbook-2-4-1'><FontAwesomeIcon icon={faFlag} style={{ marginRight: "3px", fontSize: "11px" }} />
                        <span>{markPrice ? changeNum(Number(markPrice).toFixed(max)) : "0"}</span>
                    </div>
                </div>
                <div className='futures-speed-mid-3'>
                    {
                        down8.map(function (a, i) {
                            sum6 += btnbtn === 0 ? Number(a[1]) : Number(a[1]) * Number(a[0])
                            return (
                                <SpeedLong key={i} price={a} setSpeedorder={setSpeedorder} max={max} btnbtn={btnbtn} instrumentsData={instrumentsData} sum3={sum3} sum6={sum6} qtypoint={qtypoint} darkname={darkname} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

function SpeedShort({ price, setSpeedorder, max, btnbtn, instrumentsData, sum1, sum3, qtypoint, darkname }) {
    const { t } = useTranslation();
    let str = Number(price[0]).toFixed(max)
    let str1 = btnbtn === 0 ? (Number(price[1]) * (instrumentsData?.ctVal ?? 1)).toFixed(qtypoint) : (Number(price[0]) * Number(price[1]) * (instrumentsData?.ctVal ?? 1)).toFixed(2)
    let wid = sum3 > 0 ? sum1 * 100 / sum3 : 0;

    return (
        <>
            {
                <div className='futures-speedshort'>
                    <div className={`futures-orderbook-bg bg-red ${darkname}`} style={{ width: `${wid * 0.2}%` }}></div>
                    <div className='futures-speedshort-1 red' onClick={() => setSpeedorder([2, str])}>
                        <div className='futures-speedshort-1-btn'>{t('futures_67')}</div>
                    </div>
                    <div className='futures-speedshort-2'>{changeUnits(str1)}</div>
                    <div className='futures-speedshort-2 red'>{changeNum(str)}</div>
                    <div className='futures-speedshort-1'></div>
                </div>
            }
        </>
    )
}

function SpeedLong({ price, setSpeedorder, max, btnbtn, instrumentsData, sum3, sum6, qtypoint, darkname }) {
    const { t } = useTranslation();
    let str = Number(price[0]).toFixed(max)
    let str1 = btnbtn === 0 ? (Number(price[1]) * (instrumentsData?.ctVal ?? 1)).toFixed(qtypoint) : (Number(price[0]) * Number(price[1]) * (instrumentsData?.ctVal ?? 1)).toFixed(2)
    let wid = sum3 > 0 ? sum6 * 100 / sum3 : 0;

    return (
        <>
            {
                <div className='futures-speedlong'>
                    <div className={`futures-orderbook-bg bg-green ${darkname}`} style={{ width: `${wid * 0.2}%` }}></div>
                    <div className='futures-speedlong-1'></div>
                    <div className='futures-speedlong-2'>{changeUnits(str1)}</div>
                    <div className='futures-speedlong-2 green'>{changeNum(str)}</div>
                    <div className='futures-speedlong-1 green' onClick={() => setSpeedorder([1, String(str)])}>
                        <div className='futures-speedlong-1-btn'>{t('futures_71')}</div>
                    </div>
                </div>
            }
        </>
    )
}

function Dual({ up, down, setLastBuy, setLastSell, data, setCheckordertab, darkname, dark, max, currentPrice, instrumentsData, priceDirect, btnbtn, qtypoint }) {
    const { t } = useTranslation();
    let sum1 = 0;
    let sum2 = 0;

    const up8 = up ? up.slice(0, 20) : [];
    const down8 = down ? down.slice(0, 20) : [];

    for (let i = 0; i < up8.length; i++) {
        sum1 += btnbtn === 0 ? Number(up8[i][1]) : Number(up8[i][1]) * Number(up8[i][0])
    }

    for (let i = 0; i < down8.length; i++) {
        sum2 += btnbtn === 0 ? Number(down8[i][1]) : Number(down8[i][1]) * Number(down8[i][0])
    }

    let sum3 = Math.max(sum1, sum2);
    let sum5 = 0;
    let sum6 = 0;

    let deg = sum2 + sum1 > 0 ? (sum1 - sum2) * 90 / (sum2 + sum1) : 0

    return (
        <div className='futures-dual'>
            <div className='futures-dual-top'>
                {
                    dark
                        ?
                        <>
                            <img src='/img/area_panel.png' className='futures-dual-top-img-1' />
                            <img src='/img/gauge-needle.png' className='futures-dual-top-img-2' style={{ transform: `rotate(${deg}deg)` }} />
                        </>
                        :
                        <>
                            <img src='/img/area_panel-dark.png' className='futures-dual-top-img-1' />
                            <img src='/img/gauge-needle-dark.png' className='futures-dual-top-img-2' style={{ transform: `rotate(${deg}deg)` }} />
                        </>
                }
                <>
                    <div className='futures-dual-top-1 green'>
                        <div>{t('futures_73')}</div>
                        <div style={{ marginTop: "2px" }}>{changeUnits((Number(sum2) * (instrumentsData?.ctVal ?? 1)).toFixed(2))}</div>
                    </div>
                    <div className='futures-dual-top-2 red'>
                        <div>{t('futures_74')}</div>
                        <div style={{ marginTop: "2px", paddingRight: "3px" }}>{changeUnits((Number(sum1) * (instrumentsData?.ctVal ?? 1)).toFixed(2))}</div>
                    </div>
                </>
                {
                    priceDirect
                        ?
                        <div className='futures-dual-top-3 green'>{currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                        :
                        <div className='futures-dual-top-3 red'>{currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}</div>
                }
            </div>
            <div className={`futures-dual-bottom ${darkname}`}>
                <div className='futures-dual-bottom-1'>
                    <div className='futures-dual-bottom-top green'>
                        <div className='futures-dual-bottom-top-1'>{t('futures_75')}({btnbtn === 0 ? t('futures_150') : "USDT"})</div>
                        <div className='futures-dual-bottom-top-2'>{t('futures_77')}</div>
                    </div>
                    <div className='futures-dual-bottom-bottom'>
                        {
                            down8.map(function (a, i) {
                                sum6 += btnbtn === 0 ? Number(a[1]) : Number(a[1]) * Number(a[0])
                                return (
                                    <DualDown key={i} price={a} setLastBuy={setLastBuy} setLastSell={setLastSell} sum6={sum6} sum3={sum3} setCheckordertab={setCheckordertab} darkname={darkname} max={max} btnbtn={btnbtn} instrumentsData={instrumentsData} qtypoint={qtypoint} />
                                )
                            })
                        }
                    </div>
                </div>
                <div className='futures-dual-bottom-1'>
                    <div className='futures-dual-bottom-top red'>
                        <div className='futures-dual-bottom-top-2'>{t('futures_77')}</div>
                        <div className='futures-dual-bottom-top-1'>{t('futures_75')}({btnbtn === 0 ? t('futures_150') : "USDT"})</div>
                    </div>
                    <div className='futures-dual-bottom-bottom'>
                        {
                            up8.map(function (a, i) {
                                sum5 += btnbtn === 0 ? Number(a[1]) : Number(a[1]) * Number(a[0])
                                return (
                                    <DualUp key={i} price={a} setLastBuy={setLastBuy} setLastSell={setLastSell} sum5={sum5} sum3={sum3} setCheckordertab={setCheckordertab} darkname={darkname} max={max} btnbtn={btnbtn} instrumentsData={instrumentsData} qtypoint={qtypoint} />
                                )
                            })}
                    </div>
                </div>
            </div>
        </div>
    )
}

function DualDown({ price, setLastBuy, sum6, sum3, setCheckordertab, darkname, max, btnbtn, instrumentsData, qtypoint }) {
    let str = Number(price[0]).toFixed(max)
    let str1 = Number(price[1])
    let wid = sum3 > 0 ? sum6 * 100 / sum3 : 0;

    return (
        <>
            {
                <div className='futures-dualup' onClick={() => { setLastBuy(str); setCheckordertab(false) }}>
                    <div className={`futures-dualup-bg bg-green ${darkname}`} style={{ width: `${wid}%` }}></div>
                    <div className='futures-dualup-1'>{changeUnits((Number(sum6) * (instrumentsData?.ctVal ?? 1)).toFixed(btnbtn === 0 ? qtypoint : 2))}</div>
                    <div className='futures-dualup-2 green'>{changeNum(str)}</div>
                </div>
            }
        </>
    )
}

function DualUp({ price, setLastSell, sum5, sum3, setCheckordertab, darkname, max, btnbtn, instrumentsData, qtypoint }) {
    let str = Number(price[0]).toFixed(max)
    let str1 = Number(price[1])
    let wid = sum3 > 0 ? sum5 * 100 / sum3 : 0;
    return (
        <>
            {
                <div className='futures-dualup1' onClick={() => { setLastSell(str); setCheckordertab(false) }}>
                    <div className={`futures-dualup-bg bg-red ${darkname}`} style={{ width: `${wid}%` }}></div>
                    <div className='futures-dualup-2 red'>{changeNum(str)}</div>
                    <div className='futures-dualup-1'>{changeUnits((Number(sum5) * (instrumentsData?.ctVal ?? 1)).toFixed(btnbtn === 0 ? qtypoint : 2))}</div>
                </div>
            }
        </>
    )
}

function Single({ up, down, setLastBuy, setLastSell, data, tradeData, setCheckordertab, darkname, max, priceDirect, currentPrice, instrumentsData, btnbtn, markPrice, qtypoint }) {
    const { t } = useTranslation();

    return (
        <div className='futures-mid-1'>
            <div className='futures-mid-2 display-none-700'>
                <div style={{ marginBottom: "3px" }}>{t('futures_78')}</div>
                {tradeData.map(function (a, i) {
                    return (
                        < TradingTab key={i} price={a} instrumentsData={instrumentsData} qtypoint={qtypoint} />
                    )
                })}
            </div>
            <div className='futures-mid-3'>
                <Orderbook up={up} down={down} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} darkname={darkname} max={max} priceDirect={priceDirect} currentPrice={currentPrice} instrumentsData={instrumentsData} btnbtn={btnbtn} markPrice={markPrice} qtypoint={qtypoint} />
            </div>
        </div>
    )
}

function Orderbook({ up, down, setLastBuy, setLastSell, data, setCheckordertab, darkname, max, priceDirect, currentPrice, instrumentsData, btnbtn, markPrice, qtypoint }) {
    const { t } = useTranslation();
    let orderbook_width = true
    let sum1 = 0;
    let sum2 = 0;

    const up8 = up ? up.slice(0, 10) : [];
    const down8 = down ? down.slice(0, 10) : [];

    for (let i = 0; i < up8.length; i++) {
        sum1 += btnbtn === 0 ? Number(up8[i][1]) : Number(up8[i][1]) * Number(up8[i][0])
    }

    for (let i = 0; i < down8.length; i++) {
        sum2 += btnbtn === 0 ? Number(down8[i][1]) : Number(down8[i][1]) * Number(down8[i][0])
    }

    let sum3 = Math.max(sum1, sum2);
    let sum5 = 0;
    let sum6 = 0;

    let ref = useRef()
    let ref1 = useRef()
    let ref2 = useRef()

    let wid = ref && ref.current && ref.current.clientWidth ? ref.current.clientWidth : 0
    let wid1 = ref1 && ref1.current && ref1.current.clientWidth ? ref1.current.clientWidth : 0
    let wid2 = ref2 && ref2.current && ref2.current.clientWidth ? ref2.current.clientWidth : 0

    if (wid != 0 && wid < wid1 + wid2 + 15) orderbook_width = false
    else orderbook_width = true

    return (
        <div className={`futures-orderbook ${darkname}`}>
            <div className='futures-orderbook-1'>
                <div style={{ width: "100%" }}>
                    {
                        [...up8].reverse().map(function (a, i) {
                            sum1 -= sum5
                            sum5 = btnbtn === 0 ? Number(a[1]) : Number(a[1]) * Number(a[0])
                            return (
                                <OrderbookUp key={i} price={a} setLastSell={setLastSell} sum1={sum1} sum3={sum3} setCheckordertab={setCheckordertab} darkname={darkname} max={max} instrumentsData={instrumentsData} btnbtn={btnbtn} qtypoint={qtypoint} />
                            )
                        })
                    }
                </div>
            </div>
            <div className={`futures-orderbook-2 ${darkname}`} ref={ref}>
                <div className='futures-orderbook-2-1 red'>
                    <div className='futures-orderbook-2-3'>{t('futures_77')}</div>
                    <div className='futures-orderbook-2-3-1'>{t('futures_68')}({btnbtn === 0 ? t('futures_150') : "USDT"})</div>
                    <div className='futures-orderbook-2-3-1'>{t('futures_75')}({btnbtn === 0 ? t('futures_150') : "USDT"})</div>
                </div>
                <div className='futures-orderbook-2-2'
                    style={{
                        flexDirection: orderbook_width ? "" : "column",
                        gap: orderbook_width ? "" : "0px",
                        height: orderbook_width ? "" : "auto",
                        alignItems: orderbook_width ? "" : "flex-start"
                    }}>
                    {
                        priceDirect
                            ?
                            <div className='futures-orderbook-2-4 green' ref={ref1}><FontAwesomeIcon icon={faArrowUp} style={{ marginRight: "3px", fontSize: "15px" }} />
                                {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}
                            </div>
                            :
                            <div className='futures-orderbook-2-4 red' ref={ref1}><FontAwesomeIcon icon={faArrowDown} style={{ marginRight: "3px", fontSize: "15px" }} />
                                {currentPrice ? changeNum(Number(currentPrice).toFixed(max)) : changeNum(Number(data.lastPrice).toFixed(max))}
                            </div>
                    }
                    <div className='futures-orderbook-2-4-1' ref={ref2}><FontAwesomeIcon icon={faFlag} style={{ marginRight: "3px", fontSize: "11px" }} />
                        <span>{markPrice ? changeNum(Number(markPrice).toFixed(max)) : "0"}</span>
                    </div>
                </div>
                <div className='futures-orderbook-2-1 green'>
                    <div className='futures-orderbook-2-3'>{t('futures_77')}</div>
                    <div className='futures-orderbook-2-3-1'>{t('futures_68')}({btnbtn === 0 ? t('futures_150') : "USDT"})</div>
                    <div className='futures-orderbook-2-3-1'>{t('futures_75')}({btnbtn === 0 ? t('futures_150') : "USDT"})</div>
                </div>
            </div>
            <div className='futures-orderbook-3'>
                <div style={{ width: "100%" }}>
                    {
                        down8.map(function (a, i) {
                            sum6 += btnbtn === 0 ? Number(a[1]) : Number(a[1]) * Number(a[0])
                            return (
                                <OrderbookDown key={i} price={a} setLastBuy={setLastBuy} sum6={sum6} sum3={sum3} data={data} setCheckordertab={setCheckordertab} darkname={darkname} max={max} instrumentsData={instrumentsData} btnbtn={btnbtn} qtypoint={qtypoint} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

function OrderbookUp({ price, setLastSell, sum1, sum3, setCheckordertab, darkname, max, instrumentsData, btnbtn, qtypoint }) {
    let str = Number(price[0]).toFixed(max)
    let str1 = btnbtn === 0 ? (Number(price[1]) * (instrumentsData?.ctVal ?? 1)).toFixed(qtypoint) : (Number(price[0]) * Number(price[1]) * (instrumentsData?.ctVal ?? 1)).toFixed(2)
    let wid = sum3 > 0 ? sum1 * 100 / sum3 : 0;

    return (
        <>
            {
                <div className={`futures-orderbook-box ${darkname}`} onClick={() => { setLastSell(str); setCheckordertab(false) }}>
                    <div className={`futures-orderbook-bg bg-red ${darkname}`} style={{ width: `${wid}%` }}></div>
                    <div className='futures-orderbook-box-1 red'>
                        {changeNum(str)}
                    </div>
                    <div className={`futures-orderbook-box-2 ${darkname}`}>
                        {changeUnits(str1)}
                    </div>
                    <div className={`futures-orderbook-box-2 ${darkname}`}>
                        {changeUnits((Number(sum1) * (instrumentsData?.ctVal ?? 1)).toFixed(btnbtn === 0 ? qtypoint : 2))}
                    </div>
                </div>
            }
        </>

    )
}

function OrderbookDown({ price, setLastBuy, sum6, sum3, setCheckordertab, darkname, max, instrumentsData, btnbtn, qtypoint }) {
    let str = Number(price[0]).toFixed(max)
    let str1 = btnbtn === 0 ? (Number(price[1]) * (instrumentsData?.ctVal ?? 1)).toFixed(qtypoint) : (Number(price[0]) * Number(price[1]) * (instrumentsData?.ctVal ?? 1)).toFixed(2)
    let wid = sum3 > 0 ? sum6 * 100 / sum3 : 0;
    return (
        <>
            {
                <div className={`futures-orderbook-box ${darkname}`} onClick={() => { setLastBuy(str); setCheckordertab(false) }}>
                    <div className={`futures-orderbook-bg bg-green ${darkname}`} style={{ width: `${wid}%` }}></div>
                    <div className='futures-orderbook-box-1 green'>
                        {changeNum(str)}
                    </div>
                    <div className={`futures-orderbook-box-2 ${darkname}`}>
                        {changeUnits(str1)}
                    </div>
                    <div className={`futures-orderbook-box-2 ${darkname}`}>
                        {changeUnits((Number(sum6) * (instrumentsData?.ctVal ?? 1)).toFixed(btnbtn === 0 ? qtypoint : 2))}
                    </div>
                </div>
            }
        </>
    )
}

function TradingTab({ price, instrumentsData, qtypoint }) {

    return (
        <>
            {
                price === undefined
                    ?
                    ""
                    :
                    price.side === "buy"
                        ?
                        <div className='futures-left-mid-1-trade red'>
                            <div className='futures-left-mid-1-trade-1'>{changeUnits((Number(price.sz) * (instrumentsData?.ctVal ?? 1)).toFixed(qtypoint))}</div>
                        </div>
                        :
                        <div className='futures-left-mid-1-trade green'>
                            <div className='futures-left-mid-1-trade-1'>{changeUnits((Number(price.sz) * (instrumentsData?.ctVal ?? 1)).toFixed(qtypoint))}</div>
                        </div>
            }
        </>
    )
}

export default Fsection7;
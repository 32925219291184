import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import '../../css/main1.css'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { checkLogin } from '../../../store';
import { changeNum } from "../function"
import IMG from '../../img'
import { useTranslation } from "react-i18next";

import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

let arr_topTrade5 = []
let arr_topPercentUp5 = []
let arr_topPercentDown5 = []
let arr_topPrice5 = []

function Main1() {
    const { t } = useTranslation();
    let BinanceF = useSelector((state) => { return state.Binance_coinF });

    const [text, setText] = useState("")

    let dispatch = useDispatch();
    const navigate = useNavigate();

    const onChangeText = (e) => {
        setText(e.target.value)
    }

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
                navigate('/')
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
            navigate('/')
        }
    }, []);

    useEffect(() => {
        document.title = "bitket│One-Stop Trading Platform - Crypto Trading Universe";
    }, []);

    let topTrade5 = BinanceF ? [...BinanceF] : [];
    let topPercentUp5 = BinanceF ? [...BinanceF] : [];
    let topPercentDown5 = BinanceF ? [...BinanceF] : [];
    let topPrice5 = BinanceF ? [...BinanceF] : [];

    topTrade5.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice));
    topPercentUp5.sort((a, b) => {
        if (a.priceChangePercent < b.priceChangePercent) return 1;
        if (a.priceChangePercent > b.priceChangePercent) return -1;
    });
    topPercentDown5.sort((a, b) => {
        if (a.priceChangePercent > b.priceChangePercent) return 1;
        if (a.priceChangePercent < b.priceChangePercent) return -1;
    });
    topPrice5.sort((a, b) => Number(b.lastPrice) - Number(a.lastPrice));

    arr_topTrade5 = topTrade5.splice(0, 5)
    arr_topPercentUp5 = topPercentUp5.splice(0, 5)
    arr_topPercentDown5 = topPercentDown5.splice(0, 5)
    arr_topPrice5 = topPrice5.splice(0, 5)

    const onRegister = () => {
        navigate('/regist', {
            state: {
                id: text,
                type: isNumericString(text) ? 1 : 0
            }
        })
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onRegister()
        }
    };

    return (
        <div className='main1'>
            <div className='main1-1'>
                <div className='main1-1-img'>
                    <div className='main1-1-box'>
                        <div className='main1-1-box-1'>{t('bitket_main_0')}</div>
                        <div className='main1-1-box-2'>{t('bitket_main_1')} <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: "20px" }} /></div>
                        <div className='main1-1-box-3'>
                            <div className='main1-1-box-3-1'>
                                <input type='text' placeholder={`${t('bitket_main_2')}`} value={text} onChange={onChangeText} onKeyDown={handleKeyPress} />
                            </div>
                            <div className='main1-1-box-3-2' onClick={() => onRegister()} >{t('bitket_main_3')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main1-2'>
                <div className='main1-2-1'>
                    <div className='main1-2-1-1'>
                        <img src='/img/main1/light.svg' />
                        <div className='main1-2-1-1-text'>{t('bitket_main_4')}</div>
                    </div>
                    <div className='main1-2-1-2' onClick={() => alert("Coming Soon")}>{t('bitket_main_5')} <FontAwesomeIcon icon={faAngleRight} /></div>
                </div>
                <div className='main1-2-2'>
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={30}
                        slidesPerView={1}
                        navigation={true}
                        breakpoints={{
                            1000: {
                                slidesPerView: 2,
                            },
                            1500: {
                                slidesPerView: 3,
                            }
                        }}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className='main1-2-2-box'>
                                <img src='/img/main1/open.jpg' />
                                <div className='main1-2-2-box-text'>
                                    <div className='main1-2-2-box-text-1'>{t('bitket_main_6')}</div>
                                    <div className='main1-2-2-box-text-2'>{t('bitket_main_7')}</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='main1-2-2-box'>
                                <img src='/img/im_pt_slide_banner_okx_opening.png' />
                                <div className='main1-2-2-box-text'>
                                    <div className='main1-2-2-box-text-1'>{t('bitket_main_8')}</div>
                                    <div className='main1-2-2-box-text-2'>{t('bitket_main_9')}</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='main1-2-2-box'>
                                <img src='/img/goex_event2.jpg' />
                                <div className='main1-2-2-box-text'>
                                    <div className='main1-2-2-box-text-1'>{t('bitket_main_10')}</div>
                                    <div className='main1-2-2-box-text-2'>{t('bitket_main_11')}</div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='main1-2-2-box'>
                                <img src='/img/goex_event1.jpg' />
                                <div className='main1-2-2-box-text'>
                                    <div className='main1-2-2-box-text-1'>{t('bitket_main_12')}</div>
                                    <div className='main1-2-2-box-text-2'>{t('bitket_main_13')}</div>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className='main1-3'>
                <div className='main1-3-1'>
                    <div className='main1-3-1-1'>{t('bitket_main_14')}</div>
                    <Link to={`market`}>
                        <div className='main1-3-1-2'>{t('bitket_main_5')} <FontAwesomeIcon icon={faAngleRight} /></div>
                    </Link>
                </div>
                <div className='main1-3-2'>
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={30}
                        slidesPerView={1}
                        navigation={true}
                        breakpoints={{
                            1000: {
                                slidesPerView: 2,
                            },
                            1500: {
                                slidesPerView: 3,
                            }
                        }}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className='main1-3-2-1'>
                                <div className='main1-3-2-1-1'>
                                    <div className='main1-3-2-1-1-txt'>{t('bitket_main_15')}</div>
                                </div>
                                <div className='main1-3-2-1-2'>
                                    {
                                        arr_topTrade5.map(function (a, i) {
                                            return (
                                                <List_item key={i} item={a} />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='main1-3-2-1'>
                                <div className='main1-3-2-1-1'>
                                    <div className='main1-3-2-1-1-txt'>{t('bitket_main_16')}</div>
                                </div>
                                <div className='main1-3-2-1-2'>
                                    {
                                        arr_topPrice5.map(function (a, i) {
                                            return (
                                                <List_item key={i} item={a} />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='main1-3-2-1'>
                                <div className='main1-3-2-1-1'>
                                    <div className='main1-3-2-1-1-txt'>{t('bitket_main_17')}</div>
                                </div>
                                <div className='main1-3-2-1-2'>
                                    {
                                        arr_topPercentUp5.map(function (a, i) {
                                            return (
                                                <List_item key={i} item={a} />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className='main1-3-2-1'>
                                <div className='main1-3-2-1-1'>
                                    <div className='main1-3-2-1-1-txt'>{t('bitket_main_18')}</div>
                                </div>
                                <div className='main1-3-2-1-2'>
                                    {
                                        arr_topPercentDown5.map(function (a, i) {
                                            return (
                                                <List_item key={i} item={a} />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className='main1-3'>
                <div className='main1-3-1'>
                    <div className='main1-3-1-1'>{t('bitket_main_19')}</div>
                </div>
                <div className='main1-4-2'>
                    <Link to={`/spot/binance/BTCUSDT`}>
                        <div className='main1-4-2-1'>
                            <div className='main1-4-2-2'>
                                <div className='main1-4-2-2-1'>{t('bitket_main_20')}</div>
                                <div className='main1-4-2-2-2'>{t('bitket_main_21')}</div>
                                <div className='main1-4-2-2-3'>
                                    <span className='main1-4-2-2-4'>{t('bitket_main_22')}</span>
                                    <img src='/img/main1/arrow.svg' />
                                </div>
                            </div>
                            <div className='main1-4-2-3'>
                                <img src='/img/main1/goex_icon_1.png' />
                            </div>
                        </div>
                    </Link>
                    <Link to={`/futures/binance/BTCUSDT`}>
                        <div className='main1-4-2-1'>
                            <div className='main1-4-2-2'>
                                <div className='main1-4-2-2-1'>{t('bitket_main_23')}</div>
                                <div className='main1-4-2-2-2'>{t('bitket_main_24')}</div>
                                <div className='main1-4-2-2-3'>
                                    <span className='main1-4-2-2-4'>{t('bitket_main_22')}</span>
                                    <img src='/img/main1/arrow.svg' />
                                </div>
                            </div>
                            <div className='main1-4-2-3'>
                                <img src='/img/main1/goex_icon_2.png' />
                            </div>
                        </div>
                    </Link>
                    <Link onClick={() => alert("Coming Soon")}>
                        <div className='main1-4-2-1'>
                            <div className='main1-4-2-2'>
                                <div className='main1-4-2-2-1'>{t('bitket_main_25')}</div>
                                <div className='main1-4-2-2-2'>{t('bitket_main_26')}</div>
                                <div className='main1-4-2-2-3'>
                                    <span className='main1-4-2-2-4'>{t('bitket_main_22')}</span>
                                    <img src='/img/main1/arrow.svg' />
                                </div>
                            </div>
                            <div className='main1-4-2-3'>
                                <img src='/img/main1/goex_icon_3.png' />
                            </div>
                        </div>
                    </Link>
                    <Link to={`wallet`}>
                        <div className='main1-4-2-1'>
                            <div className='main1-4-2-2'>
                                <div className='main1-4-2-2-1'>{t('bitket_main_27')}</div>
                                <div className='main1-4-2-2-2'>{t('bitket_main_28')}</div>
                                <div className='main1-4-2-2-3'>
                                    <span className='main1-4-2-2-4'>{t('bitket_main_22')}</span>
                                    <img src='/img/main1/arrow.svg' />
                                </div>
                            </div>
                            <div className='main1-4-2-3'>
                                <img src='/img/main1/goex_icon_4.png' />
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <div className='main1-3'>
                <div className='main1-3-1'>
                    <div className='main1-3-1-1'>{t('bitket_main_29')}</div>
                </div>
                <div className='main1-5-2'>
                    <div className='main1-5-2-1'>
                        <img src='/img/main1/bg8.jpg' />
                    </div>
                    <div className='main1-5-2-2'>
                        <div className='main1-5-2-2-1'>{t('bitket_main_30')}</div>
                        <div className='main1-5-2-2-2'>
                            <img src='img/main1/Facebook.svg' onClick={() => window.open('https://bitketgm.zendesk.com/hc/ko')} />
                            <img src='img/main1/Twitter.svg' onClick={() => window.open('https://bitketgm.zendesk.com/hc/ko')} />
                            <img src='img/main1/Telegram.svg' onClick={() => window.open('https://bitketgm.zendesk.com/hc/ko')} />
                            <img src='img/main1/Reddit.svg' onClick={() => window.open('https://bitketgm.zendesk.com/hc/ko')} />
                        </div>
                        <div className='main1-5-2-2-3'>
                            <img src='/img/main1/avatar.svg' />
                            <div>
                                <div className='main1-5-2-2-3-1'>{t('bitket_main_31')}</div>
                                <div className='main1-5-2-2-3-2'>{t('bitket_main_32')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function List_item({ item }) {
    const { t } = useTranslation();
    
    let imgcheck = true
    if (IMG && !IMG.includes(item.ccy)) {
        imgcheck = false
    }

    return (
        <div className='main1-list-item'>
            <div className='main1-list-item-1'>
                <img src={imgcheck ? `/img/coin/${item.ccy}.png` : `/img/coin/origin.png`} style={{ width: "32px" }} />
                <div className='main1-list-item-1-1'>
                    <div className='main1-list-item-1-2'>{item.symbol}</div>
                    <div className='main1-list-item-1-3'>{Number(item.lastPrice).toFixed(item.point)}</div>
                </div>
            </div>
            <div className='main1-list-item-2'>
                <div className={`main1-list-item-1-4 ${Number(item.priceChangePercent) >= 0 ? "green" : "red"}`}>{Number(item.priceChangePercent).toFixed(2)}%</div>
                <div className='main1-list-item-1-5'>
                    <span className='main1-list-item-1-5-1'>{t('bitket_main_33')}</span>
                    <span className='main1-list-item-1-5-2'>{changeNum(((Number(item.lastPrice) * Number(item.volume)) / (1000000)).toFixed(2))}M</span>
                </div>
            </div>
        </div>
    )
}

function isNumericString(str) {
    return !isNaN(str);
}

export default Main1;
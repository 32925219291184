import './nav.css'
import { useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

function Footer() {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    const darkname = dark ? 'dark' : "";

    let location = useLocation();

    const [loca, setLoca] = useState(false)

    useEffect(() => {
        if (location.pathname == '/') setLoca(true)
        else setLoca(false)
    }, [location])

    return (
        <div className={`footer ${darkname}`}>
            <div className='footer-box'>
                {
                    loca
                        ?
                        <div className='footer-box-wrap'>
                            <div className='footer-box-left'>
                                <img src={dark ? "/img/bitket/logo-white.png" : "/img/bitket/logo-black.png"}></img>
                            </div>
                            <div className='footer-box-right'>
                                <div className='footer-box-right-menu'>
                                    <div className='footer-box-right-box'>
                                        <div className='footer-box-right-txt-1'>{t('footer_2')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open('https://bitketgm.zendesk.com/hc/ko')}>{t('footer_3')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open('https://bitketgm.zendesk.com/hc/ko/articles/9863297194255-Terms-of-Use')}>{t('footer_4')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open('https://bitketgm.zendesk.com/hc/ko/articles/9863386636687-Privacy-Policy')}>{t('footer_5')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open('https://bitketgm.zendesk.com/hc/ko')}>{t('footer_7')}</div>
                                    </div>
                                    <div className='footer-box-right-box'>
                                        <div className='footer-box-right-txt-1'>{t('footer_8')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open('https://bitketgm.zendesk.com/hc/ko')}>{t('footer_9')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open('https://bitketgm.zendesk.com/hc/ko')}>{t('footer_10')}</div>
                                    </div>
                                    <div className='footer-box-right-box'>
                                        <div className='footer-box-right-txt-1'>{t('footer_11')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open('https://bitketgm.zendesk.com/hc/ko')}>{t('footer_12')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`} onClick={() => window.open('https://bitketgm.zendesk.com/hc/ko')}>{t('footer_13')}</div>
                                    </div>
                                    <div className='footer-box-right-box'>
                                        <div className='footer-box-right-txt-1'>{t('footer_14')}</div>
                                        <div className={`footer-box-right-txt-2  ${darkname}`}>
                                            <img src='/img/telegram.png' style={{ width: "35px" }} onClick={() => window.open('https://telegram.org/?setln=ko')} />
                                            <img src='/img/youtube.png' style={{ width: "30px" }} onClick={() => window.open('https://www.youtube.com/')} />
                                            <img src='/img/twitter.png' style={{ width: "29px" }} onClick={() => window.open('https://x.com/?lang=ko')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className='footer-box-bottom'>{t('footer_15')}</div>
            </div>
        </div>
    )
}

export default Footer;
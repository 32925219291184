import '../../css/futures.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWallet, faComputerMouse, faCircle, faXmark, faAngleDown, faAngleUp, faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { useDispatch, useSelector } from 'react-redux'
import Modal from '../../message/modal'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { findSmallest, changeNum, RoundDown, changeUnits } from "../../common/function"
import { check_Error } from '../../../store';
import { ClipLoader, SyncLoader } from "react-spinners";

let last = 0;

function BFsection4({ data, lastBuy, lastSell, symbol, setCheckordertab, checkordertab, speedorder, exinfo, positionAmount, max, firstmargin, marginbtn, setMarginbtn, marginbtn1, setMarginbtn1, currentPrice, checkCurrentPosition, fwallet, current, btnbtn, setBtnbtn, setMaxPosition, risk_balance, risk_position, margin_type, tmm, upnl, pBoth }) {
    const { t } = useTranslation();
    let dark = useSelector((state) => { return state.Darkmode.value });
    let login = useSelector((state) => { return state.login.value });
    let check_KYC = useSelector((state) => { return state.check_KYC.value });
    
    let Alert = useSelector((state) => { return state.Alert });
    const darkname = dark ? 'dark' : "";
    let dispatch = useDispatch();

    const [margintab, setMargintab] = useState(false)
    const [ordertab, setOrdertab] = useState(0);
    const [autotab, setAutotab] = useState(true);
    const [checkbtn, setCheckbtn] = useState(false);
    const [quaninput, setQuaninput] = useState('');
    const [quaninput1, setQuaninput1] = useState('');
    const [btnpercent, setBtnpercent] = useState([50]);
    const [buyorderprice, setBuyorderprice] = useState();
    const [sellorderprice, setSellorderprice] = useState();
    const [message, setMessage] = useState([])
    const [selectedValue, setSelectedValue] = useState("GTC");
    const [qtypoint, setQtypoint] = useState(2)
    const [minCoin, setMinCoin] = useState("Loding")
    const [minQty, setQty] = useState("Loding")
    const [btnTPSL, setBtnTPSL] = useState(false)
    const [risk, setRisk] = useState(0)

    const [delay, setDelay] = useState(true)
    const [mmr, setMmr] = useState(0)
    const [cum, setCum] = useState(0)

    useEffect(() => {
        if (exinfo[1] && exinfo[5]) {
            let point = findSmallest(Number(exinfo[1].minQty))
            setQty(Number(exinfo[5].notional))
            setMinCoin(exinfo[1].minQty)
            setQtypoint(point)
        }
    }, [exinfo])

    useEffect(() => {
        setQuaninput('')
        setQuaninput1('')
    }, [symbol])

    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };

    useEffect(() => {
        setBuyorderprice(Number(lastBuy).toFixed(max))
        if (lastBuy != 0) setAutotab(false)
    }, [lastBuy])

    useEffect(() => {
        setSellorderprice(Number(lastSell).toFixed(max))
        if (lastSell != 0) setAutotab(false)
    }, [lastSell])

    useEffect(() => {
        setBuyorderprice(Number(currentPrice).toFixed(max))
        setSellorderprice(Number(currentPrice).toFixed(max))
        last = currentPrice
    }, [ordertab])

    if (autotab) setCheckordertab(true)
    else setCheckordertab(false)

    if (last != currentPrice && autotab && checkordertab) {
        setBuyorderprice(Number(currentPrice).toFixed(max))
        setSellorderprice(Number(currentPrice).toFixed(max))
        last = currentPrice
    }

    const onChangeQuan = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setQuaninput(onlyNumber)
    }

    const onChangeQuan1 = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setQuaninput1(onlyNumber)
    }

    const onChangePlusCoin = (e) => {
        if (e === 0) {
            setQuaninput('0')
            setQuaninput1('0')
        }
        else {
            let num = Number(quaninput) + e
            setQuaninput(num.toFixed(qtypoint))
            setQuaninput1((num * currentPrice).toFixed(3))
        }
    }

    const onChangePlusUSDT = (e) => {
        if (e === 0) {
            setQuaninput('0')
            setQuaninput1('0')
        }
        else {
            let num = Number(quaninput1) + e
            setQuaninput1(num.toFixed(qtypoint))
            setQuaninput((num / currentPrice).toFixed(qtypoint))
        }
    }

    const onChangePercent = (e) => {
        if (e === 0) {
            setQuaninput('0')
            setQuaninput1('0')
        }
        else {
            setQuaninput1(RoundDown((current * e * marginbtn1) / 100, qtypoint))
            setQuaninput(RoundDown((current / Number(currentPrice)) * e * marginbtn1 / 100, qtypoint))
        }
    }

    useEffect(() => {
        if (btnbtn === 0) {
            setQuaninput1(RoundDown(Number(quaninput) * currentPrice, 3))
        }
        else {
            setQuaninput(RoundDown(Number(quaninput1) / currentPrice, qtypoint))

        }
    }, [currentPrice, quaninput, quaninput1])

    const onChangeBuy = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setBuyorderprice(onlyNumber)
    }

    const onChangeSell = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setSellorderprice(onlyNumber)
    }

    const onChangeTPSL = (e) => {
        setBtnTPSL(e.target.checked)
    }

    const onChangeReduceOnly = (e) => {
        setCheckbtn(e.target.checked)
    }

    useEffect(() => {
        if (positionAmount && positionAmount[1] && positionAmount[1].length > 0) {
            setQuaninput(Number(positionAmount[1]).toFixed(qtypoint))
            setQuaninput1((Number(positionAmount[1]) * Number(currentPrice)).toFixed(3))
            setBtnbtn(0)
        }
    }, [positionAmount])


    useEffect(() => {
        if (firstmargin) {
            let mmr = 0
            let cum = 0
            for (let i = 0; i < firstmargin.length; i++) {
                if (quaninput1 && quaninput1.length > 0 && Number(quaninput1) + Math.abs(pBoth[0] * pBoth[1]) >= Number(firstmargin[i].notionalFloor)) {
                    mmr = Number(firstmargin[i].maintMarginRatio)
                    cum = Number(firstmargin[i].cum)
                }
            }
            setMmr(mmr)
            setCum(cum)
        }
    }, [firstmargin, quaninput1, marginbtn1, pBoth])

    useEffect(() => {
        if (firstmargin) {
            let mar = 0
            let mmr = 0
            let cum = 0
            let cal = 0
            
            for (let i = 0; i < firstmargin.length; i++) {
                if (marginbtn1 <= Number(firstmargin[i].initialLeverage)) {
                    mar = firstmargin[i].notionalCap
                }
                if (i === 0) {
                    mmr = Number(firstmargin[0].maintMarginRatio)
                    cum = Number(firstmargin[0].cum)
                }
                if (risk_position > Number(firstmargin[i].notionalCap)) {
                    mmr = Number(firstmargin[i].maintMarginRatio)
                    cum = Number(firstmargin[i].cum)
                }
            }
            setMaxPosition(mar)
            if (margin_type == "0") {
                cal = 0
            }
            else if (margin_type == "ISOLATED") {
                cal = risk_balance === 0 ? 0 : (risk_position * mmr - cum) * 100 / risk_balance
            }
            else {
                cal = (risk_position * mmr - cum) * 100 / ((fwallet?.balance ? Number(fwallet.balance) + Number(fwallet.crossUnPnl) : 0) - risk_balance)
            }
            setRisk(cal < 0 ? 0 : cal)
        }
    }, [firstmargin, risk_balance, risk_position])


    const FuturesChangeLever = async (m) => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(process.env.REACT_APP_API + `/api/v1/trade/bn/futures/leverage`, {
                symbol: symbol,
                leverage: m,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setMarginbtn1(m);
                    setMessage({ type: "binance", code: response?.data?.code, msg: t('message_29') })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: t('message_33') })
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 65, "msg": error }))
            })
        }
    }

    const createPostData = (side, px, sz) => {
        return {
            symbol: symbol,
            side: side,
            type: ordertab === 0 ? 'LIMIT' : ordertab === 1 ? 'MARKET' : "STOP_MARKET",
            quantity: sz,
            price: ordertab === 0 ? px : "",
            timeInForce: ordertab === 0 ? selectedValue : "",
            reduceOnly: checkbtn,
            stopPrice: ordertab === 2 ? px : "",
        };
    };
    
    const [isPlaying, setIsPlaying] = useState(false);

    const playAudio = () => {
        setIsPlaying(true);
        setTimeout(() => {
            setIsPlaying(false);
        }, 1000)
    };

    const onSubmitFutures = async (postData, url) => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            setDelay(false)

            await axios.post(url, postData, { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                if (response?.data?.code >= 0) {
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_12')}` })
                }
                else {
                    if (response?.data?.code === -2019 && Alert[2] == "ON") {
                        playAudio()
                    }
                    setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                }
                setTimeout(() => {
                    setDelay(true)
                }, 500)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 66, "msg": error }))
            })
        }
    }

    const onSubmitFuturesBuy = () => {
        if (delay) {
            let url = process.env.REACT_APP_API + '/api/v1/trade/bn/futures/order'
            let postData = createPostData("BUY", buyorderprice, quaninput);
            onSubmitFutures(postData, url);
        }
    };

    const onSubmitFuturesSell = () => {
        if (delay) {
            let url = process.env.REACT_APP_API + '/api/v1/trade/bn/futures/order'
            let postData = createPostData("SELL", sellorderprice, quaninput);
            onSubmitFutures(postData, url);
        }
    };

    useEffect(() => {
        if (speedorder[0] === 1) {
            let url = process.env.REACT_APP_API + '/api/v1/trade/bn/futures/order'
            let postData = createPostData("BUY", speedorder[1], quaninput);
            onSubmitFutures(postData, url);
        }
        else if (speedorder[0] === 2) {
            let url = process.env.REACT_APP_API + '/api/v1/trade/bn/futures/order'
            let postData = createPostData("SELL", speedorder[1], quaninput);
            onSubmitFutures(postData, url);
        }
    }, [speedorder])

    const changeMarginType = async (e) => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(process.env.REACT_APP_API + `/api/v1/trade/bn/futures/set/margin-type`, {
                symbol: symbol,
                marginType: e === 0 ? "CROSSED" : "ISOLATED"
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setMarginbtn(e === 0 ? 0 : 1)
                    setMessage({ type: "binance", code: response?.data?.code, msg: e == 1 ? t('message_31') : t('message_32') })
                }
                else {
                    setMessage({ type: "binance", code: response?.data?.code, msg: t('message_33') })
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 67, "msg": error }))
            })
        }
    }

    return (
        <div className={`futures-section4 ${darkname}`}>
            {
                isPlaying
                    ?
                    <audio autoPlay={isPlaying}>
                        <source src="/audio/Sound.7.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            <div style={{ filter: check_KYC && check_KYC.bnKyc === 0 ? "blur(5px)" : "" }}>
                <Modal message={message} />
                <div className={`${btnTPSL ? "" : "hide"}`}>
                    <TPSL darkname={darkname} setBtnTPSL={setBtnTPSL} buyorderprice={buyorderprice} sellorderprice={sellorderprice} quaninput={quaninput} max={max} symbol={symbol} type={ordertab === 0 ? "Limit" : "Market"} reduceOnly={checkbtn} createPostData={createPostData} setMessage={setMessage} checkCurrentPosition={checkCurrentPosition} marginbtn1={marginbtn1} />
                </div>
                <div className={`futures-section4-top display-none-700 ${darkname}`}>
                    <div className={marginbtn === 0 ? `futures-section4-top-btn active ${darkname}` : `futures-section4-top-btn ${darkname}`} onClick={() => changeMarginType(0)}>{t('futures_4')}</div>
                    <div className={marginbtn === 1 ? `futures-section4-top-btn active ${darkname}` : `futures-section4-top-btn ${darkname}`} onClick={() => changeMarginType(1)}>{t('futures_5')}</div>
                    <div className={`futures-section4-top-btn-1 active ${darkname}`} onClick={() => setMargintab(true)}><span>{marginbtn1}</span> x</div>
                    {
                        margintab
                            ?
                            <Margin setMargintab={setMargintab} marginbtn1={marginbtn1} darkname={darkname} FuturesChangeLever={FuturesChangeLever} firstmargin={firstmargin && firstmargin[0] ? firstmargin[0].initialLeverage : "100"} />
                            :
                            ""
                    }
                </div>
                <div className='futures-section4-top-1'>
                    <div className={ordertab === 0 ? `futures-section4-top-1-btn active ${darkname}` : 'futures-section4-top-1-btn'} onClick={() => { setOrdertab(0); setCheckbtn(false);; setAutotab(true) }}>{t('futures_6')}</div>
                    <div className={ordertab === 1 ? `futures-section4-top-1-btn active ${darkname}` : 'futures-section4-top-1-btn'} onClick={() => { setOrdertab(1); setCheckbtn(false); setAutotab(true) }}>{t('futures_7')}</div>
                    <div className={ordertab === 2 ? `futures-section4-top-1-btn active ${darkname}` : 'futures-section4-top-1-btn'} onClick={() => { setOrdertab(2); setCheckbtn(true); setAutotab(false) }}>{t('futures_8')}</div>
                </div>
                <div className={`futures-section4-top-2 ${darkname}`}>
                    <FontAwesomeIcon icon={faWallet} className='futures-section4-top-2-ico' />
                    <div className='futures-section4-top-2-1'>{t('futures_0')}</div>
                    <div className={`futures-section4-top-2-2 ${darkname} red`}><span>{fwallet.balance ? changeNum((Number(fwallet.balance) + Number(fwallet.crossUnPnl)).toFixed(3)) : "0.0000"}</span> <span style={{ fontSize: "11px" }}>USDT</span></div>
                </div>
                {
                    ordertab === 0
                        ?
                        autotab
                            ?
                            <div className={`futures-section4-top-3-1 ${darkname}`} onClick={() => { setAutotab(false) }}>
                                <span>{t('futures_10')}</span>&nbsp;<span className='color' style={{ fontWeight: "bold" }}>{t('futures_11')}</span>
                                <FontAwesomeIcon icon={faCircle} className='futures-section4-top-3-1-ico' />
                            </div>
                            :
                            <div className={`futures-section4-top-3-2 ${darkname}`} onClick={() => { setAutotab(true) }}>
                                <span>{t('futures_10')}</span>&nbsp;<span style={{ fontWeight: "bold", color: "#c1c1c1" }}>{t('futures_12')}</span>
                                <FontAwesomeIcon icon={faCircle} className='futures-section4-top-3-1-ico' />
                            </div>
                        :
                        <div className={`futures-section4-top-3-2 ${darkname}`}>
                            <span>{t('futures_10')}</span>&nbsp;<span style={{ fontWeight: "bold", color: "#c1c1c1" }}>{t('futures_12')}</span>
                            <FontAwesomeIcon icon={faCircle} className='futures-section4-top-3-1-ico' />
                        </div>
                }
                <div className='futures-section4-top-4'>
                    <div className={`futures-section4-top-4-1 ${darkname}`}>
                        <div className='futures-section4-top-4-3'>
                            <div className='futures-section4-top-4-1-1'>{t('futures_13')}</div>
                            {
                                ordertab === 1
                                    ?
                                    <input type='number' className={`futures-section4-top-4-1-2 ${darkname}`} value={buyorderprice} onChange={onChangeBuy} disabled />
                                    :
                                    <input type='number' className={`futures-section4-top-4-1-2 ${darkname}`} value={buyorderprice} onChange={onChangeBuy} onClick={() => { setAutotab(false); setCheckordertab(false) }} />
                            }
                        </div>
                    </div>
                    <div className={`futures-section4-top-4-2 ${darkname}`}>
                        <div className='futures-section4-top-4-3'>
                            <div className='futures-section4-top-4-1-1'>{t('futures_14')}</div>
                            {
                                ordertab === 1
                                    ?
                                    <input type='number' className={`futures-section4-top-4-1-3 ${darkname}`} value={sellorderprice} onChange={onChangeSell} disabled />
                                    :
                                    <input type='number' className={`futures-section4-top-4-1-3 ${darkname}`} value={sellorderprice} onChange={onChangeSell} onClick={() => { setAutotab(false); setCheckordertab(false) }} />
                            }
                        </div>
                    </div>
                </div>
                <div className='futures-section4-top-5'>
                    {
                        btnbtn === 0
                            ?
                            <>
                                <div style={{ padding: "5px 0", width: "100%" }}>
                                    <div className='futures-section4-top-5-1'>
                                        <div className='futures-section-top-5-1-1'>{t('futures_15')}</div>
                                        <div className='futures-section-top-5-1-2'>{data.ccy}</div>
                                        <input className={`futures-section-top-5-1-3 ${darkname}`} type='number' onChange={onChangeQuan} placeholder={0} value={quaninput} />
                                    </div>
                                    <div className='futures-section-top-5-1-4'>
                                        <div style={{ width: "100%", textAlign: "left" }}><span>{t('futures_30')}</span> <span>{minCoin}</span> <span>{data.ccy}</span></div>
                                        <div style={{ width: "100%", textAlign: "right" }}>≈ <span>{quaninput1}</span> <span style={{ fontSize: "10px", paddingLeft: "5px" }}>USDT</span></div>
                                    </div>
                                    <div className='futures-section-top-5-1-4'>
                                        <div style={{ width: "100%", textAlign: "left" }}><span>{t('futures_30_1')}</span> <span>{minQty}</span> <span>USDT</span></div>
                                    </div>
                                </div>
                                <div className='futures-section4-top-5-2'>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(Number(minCoin))}>{isNaN(minCoin) ? 0 : changeUnits(minCoin)}</div>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(Number(minCoin) * 5)}>{isNaN(minCoin) ? 0 : changeUnits(Number(minCoin) * 5)}</div>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(Number(minCoin) * 10)}>{isNaN(minCoin) ? 0 : changeUnits(Number(minCoin) * 10)}</div>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(Number(minCoin) * 50)}>{isNaN(minCoin) ? 0 : changeUnits(Number(minCoin) * 50)}</div>
                                </div>
                                <div className='futures-section4-top-5-2'>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(Number(minCoin) * 100)}>{isNaN(minCoin) ? 0 : changeUnits(Number(minCoin) * 100)}</div>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(Number(minCoin) * 500)}>{isNaN(minCoin) ? 0 : changeUnits(Number(minCoin) * 500)}</div>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(Number(minCoin) * 1000)}>{isNaN(minCoin) ? 0 : changeUnits(Number(minCoin) * 1000)}</div>
                                    <div className={`futures-section4-top-5-2-1 ${darkname}`} onClick={() => onChangePlusCoin(0)}>{t('futures_16')}</div>
                                </div>
                            </>
                            :
                            btnbtn === 1
                                ?
                                <>
                                    <div style={{ padding: "5px 0", width: "100%" }}>
                                        <div className='futures-section4-top-5-1'>
                                            <div className='futures-section-top-5-1-1'>{t('futures_15')}</div>
                                            <div className='futures-section-top-5-1-2'>USDT</div>
                                            <input className={`futures-section-top-5-1-3 ${darkname}`} type='number' onChange={onChangeQuan1} placeholder={`0`} value={quaninput1} />
                                        </div>
                                        <div className='futures-section-top-5-1-4'>
                                            <div style={{ width: "100%", textAlign: "left" }}><span>{t('futures_30')}</span> <span>{minCoin}</span> <span>{data.ccy}</span></div>
                                            <div style={{ width: "100%", textAlign: "right" }}>≈ <span>{quaninput}</span> <span style={{ fontSize: "10px", paddingLeft: "5px" }}>{data.ccy}</span></div>
                                        </div>
                                        <div className='futures-section-top-5-1-4'>
                                            <div style={{ width: "100%", textAlign: "left" }}><span>{t('futures_30_1')}</span> <span>{minQty}</span> <span>USDT</span></div>
                                        </div>
                                    </div>
                                    <div className='futures-section4-top-5-2'>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(100)}>100</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(500)}>500</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(1000)}>1,000</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(5000)}>5,000</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(10000)}>10 K</div>
                                    </div>
                                    <div className='futures-section4-top-5-2'>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(50000)}>50 K</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(100000)}>100 K</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(500000)}>500 K</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(1000000)}>1 M</div>
                                        <div className={`futures-section4-top-5-2-2 ${darkname}`} onClick={() => onChangePlusUSDT(0)}>{t('futures_16')}</div>
                                    </div>
                                </>
                                :
                                <>
                                    <div style={{ padding: "5px 0", width: "100%" }}>
                                        <div className='futures-section4-top-5-1'>
                                            <div className='futures-section-top-5-1-1'>{t('futures_15')}</div>
                                            <div className='futures-section-top-5-1-2'>USDT</div>
                                            <input className={`futures-section-top-5-1-3 ${darkname}`} type='number' onChange={onChangeQuan1} placeholder={`0`} value={quaninput1} />
                                        </div>
                                        <div className='futures-section-top-5-1-4'>
                                            <div style={{ width: "100%", textAlign: "left" }}><span>{t('futures_30')}</span> <span>{minCoin}</span> <span>{data.ccy}</span></div>
                                            <div style={{ width: "100%", textAlign: "right" }}>≈ <span>{quaninput}</span> <span style={{ fontSize: "10px", paddingLeft: "5px" }}>{data.ccy}</span></div>
                                        </div>
                                        <div className='futures-section-top-5-1-4'>
                                            <div style={{ width: "100%", textAlign: "left" }}><span>{t('futures_30_1')}</span> <span>{minQty}</span> <span>USDT</span></div>
                                        </div>
                                    </div>
                                    <div className='futures-section4-top-5-4' >
                                        <output futures-margin-output id="output"><span>{btnpercent[0]}</span> <span>%</span></output>
                                        <Range
                                            values={btnpercent}
                                            step={1}
                                            min={0}
                                            max={100}
                                            onChange={e => { setBtnpercent(e); onChangePercent(e) }}
                                            renderTrack={({ props, children }) => (
                                                <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} style={{ ...props.style, height: "36px", display: "flex", width: "93%" }}>
                                                    <div
                                                        ref={props.ref}
                                                        style={{
                                                            height: "5px", width: "100%", borderRadius: "4px",
                                                            background: getTrackBackground({
                                                                values: btnpercent,
                                                                colors: ["#F5AC1D", "#ccc"],
                                                                min: 0,
                                                                max: 100
                                                            }),
                                                            alignSelf: "center"
                                                        }}
                                                    >
                                                        {children}
                                                    </div>
                                                </div>
                                            )}
                                            renderThumb={({ props, isDragged }) => (
                                                <div {...props} style={{ ...props.style, height: "20px", width: "20px", borderRadius: "4px", backgroundColor: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 2px 6px #AAA" }}>
                                                    <div style={{ height: "16px", width: "5px", backgroundColor: isDragged ? "#F5AC1D" : "#CCC" }} />
                                                </div>
                                            )}
                                        />
                                        <div className='futures-section4-top-5-4-1'>
                                            <div className='futures-section4-top-5-4-2'>0%</div>
                                            <div className='futures-section4-top-5-4-3'>25%</div>
                                            <div className='futures-section4-top-5-4-2'>50%</div>
                                            <div className='futures-section4-top-5-4-3'>75%</div>
                                            <div className='futures-section4-top-5-4-2'>100%</div>
                                        </div>
                                    </div>
                                </>
                    }
                    {
                        login
                            ?
                            ordertab === 0
                                ?
                                <div className='futures-section4-top-5-3'>
                                    <div className={`futures-section4-top-5-3-1 ${darkname}`} onClick={() => onSubmitFuturesBuy()}>
                                        <div className={`futures-section4-top-5-3-1-1`}>{
                                            delay
                                                ?
                                                t('futures_17')
                                                :
                                                <ClipLoader color="#595959" size='17px' />
                                        }
                                        </div>
                                    </div>
                                    <div className={`futures-section4-top-5-3-2 ${darkname}`} onClick={() => onSubmitFuturesSell()}>
                                        <div className={`futures-section4-top-5-3-1-2`}>{
                                            delay
                                                ?
                                                t('futures_18')
                                                :
                                                <ClipLoader color="#595959" size='17px' />
                                        }
                                        </div>
                                    </div>
                                </div>
                                :
                                ordertab === 1
                                    ?
                                    <div className='futures-section4-top-5-3'>
                                        <div className={`futures-section4-top-5-3-1 ${darkname}`}>
                                            <div className={`futures-section4-top-5-3-1-1`} onClick={() => onSubmitFuturesBuy()}>{
                                                delay
                                                    ?
                                                    t('futures_19')
                                                    :
                                                    <ClipLoader color="#595959" size='17px' />
                                            }</div>
                                        </div>
                                        <div className={`futures-section4-top-5-3-2 ${darkname}`}>
                                            <div className={`futures-section4-top-5-3-1-2`} onClick={() => onSubmitFuturesSell()}>{
                                                delay
                                                    ?
                                                    t('futures_20')
                                                    :
                                                    <ClipLoader color="#595959" size='17px' />
                                            }</div>
                                        </div>
                                    </div>
                                    :
                                    <div className='futures-section4-top-5-3'>
                                        <div className={`futures-section4-top-5-3-1 ${darkname}`}>
                                            <div className={`futures-section4-top-5-3-1-1`} onClick={() => onSubmitFuturesBuy()}>{
                                                delay
                                                    ?
                                                    t('futures_21')
                                                    :
                                                    <ClipLoader color="#595959" size='17px' />
                                            }</div>
                                        </div>
                                        <div className={`futures-section4-top-5-3-2 ${darkname}`}>
                                            <div className={`futures-section4-top-5-3-1-2`} onClick={() => onSubmitFuturesSell()}>{
                                                delay
                                                    ?
                                                    t('futures_22')
                                                    :
                                                    <ClipLoader color="#595959" size='17px' />
                                            }</div>
                                        </div>
                                    </div>
                            :
                            <div className='futures-section4-top-5-3'>
                                <Link to={`/login`} className={`futures-section4-top-5-3-1 ${darkname}`}>
                                    <div className={`futures-section4-top-5-3-1-1`}>{t('login_0')}</div>
                                </Link>
                                <Link to={`/login`} className={`futures-section4-top-5-3-2 ${darkname}`}>
                                    <div className={`futures-section4-top-5-3-1-2`}>{t('login_0')}</div>
                                </Link>
                            </div>
                    }

                </div >
                <div className='futures-section4-top-6'>
                    <div className='futures-section4-top-6-1' onClick={() => { setBtnbtn(0) }}>
                        <div className='futures-section4-top-6-2'>
                            <FontAwesomeIcon icon={faComputerMouse} className='futures-section4-top-6-1-ico' />
                            <div className={btnbtn === 0 ? 'futures-section4-top-6-1-tit active' : 'futures-section4-top-6-1-tit'}>{t('futures_23')}</div>
                        </div>
                    </div>
                    <div className='futures-section4-top-6-1' onClick={() => { setBtnbtn(1) }}>
                        <div className='futures-section4-top-6-2'>
                            <FontAwesomeIcon icon={faComputerMouse} className='futures-section4-top-6-1-ico' />
                            <div className={btnbtn === 1 ? 'futures-section4-top-6-1-tit active' : 'futures-section4-top-6-1-tit'}>{t('futures_24')}</div>
                        </div>
                    </div>
                    <div className='futures-section4-top-6-1' onClick={() => { setBtnbtn(2) }}>
                        <div className='futures-section4-top-6-2'>
                            <FontAwesomeIcon icon={faComputerMouse} className='futures-section4-top-6-1-ico' />
                            <div className={btnbtn === 2 ? 'futures-section4-top-6-1-tit active' : 'futures-section4-top-6-1-tit'}>{t('futures_25')}</div>
                        </div>
                    </div>
                </div>
                <div className='futures-section4-top-7'>
                    {
                        ordertab === 0
                            ?
                            <select className={`futures-section4-top-7-select ${darkname}`} disabled={false} value={selectedValue} onChange={handleSelectChange}>
                                <option value={"GTC"}><span>{t('futures_26')}</span></option>
                                <option value={"GTX"}><span>{t('futures_27')}</span></option>
                                <option value={"FOK"}><span>{t('futures_28')}</span></option>
                                <option value={"IOC"}><span>{t('futures_29')}</span></option>
                            </select>
                            :
                            <select className={`futures-section4-top-7-select ${darkname}`} disabled={true}>
                                <option><span>{t('futures_26')}</span></option>
                                <option><span>{t('futures_27')}</span></option>
                                <option><span>{t('futures_28')}</span></option>
                                <option><span>{t('futures_29')}</span></option>
                            </select>
                    }
                    <div className='futures-section4-top-7-1'>
                        <div className='futures-section4-top-7-2'>
                            <input type='checkbox' className='futures-section4-top-7-input' checked={btnTPSL} onChange={onChangeTPSL} />
                            <div className='futures-section4-top-7-tit'>{t('futures_149')}</div>
                        </div>
                        <div className='futures-section4-top-7-2'>
                            <input type='checkbox' className='futures-section4-top-7-input' checked={checkbtn} onChange={onChangeReduceOnly} />
                            <div className='futures-section4-top-7-tit'>{t('futures_31')}</div>
                        </div>
                    </div>
                </div>
                <div className={`futures-section4-top-8 ${darkname}`}>
                    <div className='futures-section4-top-8-1 green'>
                        <div className='futures-section4-top-8-2'>
                            <div className='futures-section4-top-8-3'>{t('futures_32')}</div>
                            <div className='futures-section4-top-8-4'>{isNaN((quaninput * Number(buyorderprice) / Number(marginbtn1))) ? "0" : changeNum((quaninput * Number(buyorderprice) / Number(marginbtn1)).toFixed(qtypoint + max))}</div>
                        </div>
                        <div className='futures-section4-top-8-2'>
                            <div className='futures-section4-top-8-3'>{t('futures_33')}</div>
                            <div className='futures-section4-top-8-4'>{isNaN((quaninput * Number(buyorderprice))) ? "0" : changeNum((quaninput * Number(buyorderprice)).toFixed(qtypoint + max))}</div>
                        </div>
                        <div className='futures-section4-top-8-2'>
                            <div className='futures-section4-top-8-3'>{t('futures_34')}</div>
                            <div className='futures-section4-top-8-4'>
                                {
                                    marginbtn === 0
                                        ?
                                        pBoth[1] === 0
                                            ?
                                            isNaN((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (Number(buyorderprice) * Number(quaninput))) / ((Number(quaninput)) * (mmr - 1)))
                                                || quaninput && quaninput == "" || Number(quaninput) == 0
                                                ?
                                                "0.0000"
                                                :
                                                (Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (Number(buyorderprice) * Number(quaninput) * (1 / Number(marginbtn1)))) < 0
                                                    ?
                                                    `${t('futures_153')}`
                                                    :
                                                    ((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (Number(buyorderprice) * Number(quaninput))) / ((Number(quaninput)) * (mmr - 1))) < 0
                                                        ?
                                                        "0.0000"
                                                        :
                                                        changeNum(((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (Number(buyorderprice) * Number(quaninput))) / ((Number(quaninput)) * (mmr - 1))).toFixed(max))
                                            :
                                            pBoth[1] > 0
                                                ?
                                                isNaN((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (pBoth[0] * pBoth[1]) - (Number(buyorderprice) * Number(quaninput))) / ((Number(quaninput) + (pBoth[1])) * (mmr - 1)))
                                                    || quaninput && quaninput == "" || Number(quaninput) == 0
                                                    ?
                                                    "0.0000"
                                                    :
                                                    (Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (pBoth[0] * pBoth[1] * (1 / Number(pBoth[2]))) - (Number(buyorderprice) * Number(quaninput) * (1 / Number(marginbtn1)))) < 0
                                                        ?
                                                        `${t('futures_153')}`
                                                        :
                                                        ((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (pBoth[0] * pBoth[1]) - (Number(buyorderprice) * Number(quaninput))) / ((Number(quaninput) + (pBoth[1])) * (mmr - 1))) < 0
                                                            ?
                                                            "0.0000"
                                                            :
                                                            changeNum(((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (pBoth[0] * pBoth[1]) - (Number(buyorderprice) * Number(quaninput))) / ((Number(quaninput) + (pBoth[1])) * (mmr - 1))).toFixed(max))
                                                :
                                                isNaN((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (Number(buyorderprice) * Math.abs(Number(quaninput) + pBoth[1]))) / (Math.abs(Number(quaninput) + (pBoth[1])) * (mmr - 1)))
                                                    || quaninput && quaninput == "" || Number(quaninput) == 0 || (Number(quaninput) + (pBoth[1])) == 0
                                                    ?
                                                    "0.0000"
                                                    :
                                                    (Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (Number(buyorderprice) * Math.abs(Number(quaninput) + pBoth[1]) * (1 / Number(marginbtn1)))) < 0
                                                        ?
                                                        `${t('futures_153')}`
                                                        :
                                                        ((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (Number(buyorderprice) * Math.abs(Number(quaninput) + pBoth[1]))) / (Math.abs(Number(quaninput) + (pBoth[1])) * (mmr - 1))) < 0
                                                            ?
                                                            "0.0000"
                                                            :
                                                            changeNum(((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (Number(buyorderprice) * Math.abs(Number(quaninput) + pBoth[1]))) / (Math.abs(Number(quaninput) + (pBoth[1])) * (mmr - 1))).toFixed(max))
                                        :
                                        pBoth[1] === 0
                                            ?
                                            isNaN(((Number(buyorderprice) * Number(quaninput) * (1 / Number(marginbtn1) - 1)) + cum) / ((Number(quaninput)) * (mmr - 1)))
                                                || quaninput && quaninput == "" || Number(quaninput) == 0
                                                ?
                                                "0.0000"
                                                :
                                                (Number(fwallet.balance) + Number(fwallet.crossUnPnl) + cum - (Number(buyorderprice) * Number(quaninput) * (1 / Number(marginbtn1)))) < 0
                                                    ?
                                                    `${t('futures_153')}`
                                                    :
                                                    (((Number(buyorderprice) * Number(quaninput) * (1 / Number(marginbtn1) - 1)) + cum) / ((Number(quaninput)) * (mmr - 1))) < 0
                                                        ?
                                                        "0.0000"
                                                        :
                                                        changeNum((((Number(buyorderprice) * Number(quaninput) * (1 / Number(marginbtn1) - 1)) + cum) / ((Number(quaninput)) * (mmr - 1))).toFixed(max))
                                            :
                                            pBoth[1] > 0
                                                ?
                                                isNaN(((Number(buyorderprice) * Number(quaninput) * (1 / Number(marginbtn1) - 1)) + (pBoth[0] * pBoth[1] * (1 / pBoth[2])) + cum - (pBoth[0] * pBoth[1])) / ((Number(quaninput) + (pBoth[1])) * (mmr - 1)))
                                                    || quaninput && quaninput == "" || Number(quaninput) == 0
                                                    ?
                                                    "0.0000"
                                                    :
                                                    (Number(fwallet.balance) + Number(fwallet.crossUnPnl) + cum - (Number(buyorderprice) * Number(quaninput) * (1 / Number(marginbtn1))) - (pBoth[0] * pBoth[1] * (1 / pBoth[2]))) < 0
                                                        ?
                                                        `${t('futures_153')}`
                                                        :
                                                        (((Number(buyorderprice) * Number(quaninput) * (1 / Number(marginbtn1) - 1)) + (pBoth[0] * pBoth[1] * (1 / pBoth[2])) + cum - (pBoth[0] * pBoth[1])) / ((Number(quaninput) + (pBoth[1])) * (mmr - 1))) < 0
                                                            ?
                                                            "0.0000"
                                                            :
                                                            changeNum((((Number(buyorderprice) * Number(quaninput) * (1 / Number(marginbtn1) - 1)) + (pBoth[0] * pBoth[1] * (1 / pBoth[2])) + cum - (pBoth[0] * pBoth[1])) / ((Number(quaninput) + (pBoth[1])) * (mmr - 1))).toFixed(max))
                                                :
                                                isNaN(((Number(buyorderprice) * Math.abs(Number(quaninput) + pBoth[1]) * (1 / Number(marginbtn1) - 1)) + cum) / (Math.abs(Number(quaninput) + (pBoth[1])) * (mmr - 1)))
                                                    || quaninput && quaninput == "" || Number(quaninput) == 0 || (Number(quaninput) + (pBoth[1])) == 0
                                                    ?
                                                    "0.0000"
                                                    :
                                                    (Number(fwallet.balance) + Number(fwallet.crossUnPnl) + cum - (Number(buyorderprice) * (Number(quaninput) + pBoth[1]) * (1 / Number(marginbtn1)))) < 0
                                                        ?
                                                        `${t('futures_153')}`
                                                        :
                                                        (((Number(buyorderprice) * Math.abs(Number(quaninput) + pBoth[1]) * (1 / Number(marginbtn1) - 1)) + cum) / (Math.abs(Number(quaninput) + (pBoth[1])) * (mmr - 1))) < 0
                                                            ?
                                                            "0.0000"
                                                            :
                                                            changeNum((((Number(buyorderprice) * Math.abs(Number(quaninput) + pBoth[1]) * (1 / Number(marginbtn1) - 1)) + cum) / (Math.abs(Number(quaninput) + (pBoth[1])) * (mmr - 1))).toFixed(max))
                                }
                            </div>
                        </div>
                    </div>
                    <div className='futures-section4-top-8-1 red'>
                        <div className='futures-section4-top-8-2'>
                            <div className='futures-section4-top-8-3'>{t('futures_32')}</div>
                            <div className='futures-section4-top-8-4'>{isNaN((quaninput * Number(sellorderprice) / Number(marginbtn1))) ? "0" : changeNum((quaninput * Number(sellorderprice) / Number(marginbtn1)).toFixed(qtypoint + max))}</div>
                        </div>
                        <div className='futures-section4-top-8-2'>
                            <div className='futures-section4-top-8-3'>{t('futures_33')}</div>
                            <div className='futures-section4-top-8-4'>{isNaN((quaninput * Number(sellorderprice))) ? "0" : changeNum((quaninput * Number(sellorderprice)).toFixed(qtypoint + max))}</div>
                        </div>
                        <div className='futures-section4-top-8-2'>
                            <div className='futures-section4-top-8-3'>{t('futures_34')}</div>
                            <div className='futures-section4-top-8-4'>
                                {
                                    marginbtn === 0
                                        ?
                                        pBoth[1] === 0
                                            ?
                                            isNaN((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum + (Number(sellorderprice) * Number(quaninput))) / ((Number(quaninput)) * (mmr + 1)))
                                                || quaninput && quaninput == "" || Number(quaninput) == 0
                                                ?
                                                "0.0000"
                                                :
                                                (Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (Number(sellorderprice) * Number(quaninput) * (1 / Number(marginbtn1)))) < 0
                                                    ?
                                                    `${t('futures_153')}`
                                                    :
                                                    ((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum + (Number(sellorderprice) * Number(quaninput))) / ((Number(quaninput)) * (mmr + 1))) < 0
                                                        ?
                                                        "0.0000"
                                                        :
                                                        changeNum(((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum + (Number(sellorderprice) * Number(quaninput))) / ((Number(quaninput)) * (mmr + 1))).toFixed(max))
                                            :
                                            pBoth[1] < 0
                                                ?
                                                isNaN((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum + (pBoth[0] * Math.abs(pBoth[1])) + (Number(sellorderprice) * Number(quaninput))) / ((Number(quaninput) - (pBoth[1])) * (mmr + 1)))
                                                    || quaninput && quaninput == "" || Number(quaninput) == 0
                                                    ?
                                                    "0.0000"
                                                    :
                                                    (Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (pBoth[0] * Math.abs(pBoth[1]) * (1 / Number(pBoth[2]))) - (Number(sellorderprice) * Number(quaninput) * (1 / Number(marginbtn1)))) < 0
                                                        ?
                                                        `${t('futures_153')}`
                                                        :
                                                        ((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum + (pBoth[0] * Math.abs(pBoth[1])) + (Number(sellorderprice) * Number(quaninput))) / ((Number(quaninput) - (pBoth[1])) * (mmr + 1))) < 0
                                                            ?
                                                            "0.0000"
                                                            :
                                                            changeNum(((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum + (pBoth[0] * Math.abs(pBoth[1])) + (Number(sellorderprice) * Number(quaninput))) / ((Number(quaninput) - (pBoth[1])) * (mmr + 1))).toFixed(max))
                                                :
                                                isNaN((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum + (Number(sellorderprice) * Math.abs(Number(quaninput) - pBoth[1]))) / (Math.abs(Number(quaninput) - (pBoth[1])) * (mmr + 1)))
                                                    || quaninput && quaninput == "" || Number(quaninput) == 0 || (Number(quaninput) + (pBoth[1])) == 0
                                                    ?
                                                    "0.0000"
                                                    :
                                                    (Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum - (Number(sellorderprice) * Math.abs(Number(quaninput) + Math.abs(pBoth[1])) * (1 / Number(marginbtn1)))) < 0
                                                        ?
                                                        `${t('futures_153')}`
                                                        :
                                                        ((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum + (Number(sellorderprice) * Math.abs(Number(quaninput) - pBoth[1]))) / (Math.abs(Number(quaninput) - (pBoth[1])) * (mmr + 1))) < 0
                                                            ?
                                                            "0.0000"
                                                            :
                                                            changeNum(((Number(fwallet.balance) + Number(fwallet.crossUnPnl) - (tmm * mmr - cum * (tmm === 0 ? 0 : 1)) + upnl + cum + (Number(sellorderprice) * Math.abs(Number(quaninput) - pBoth[1]))) / (Math.abs(Number(quaninput) - (pBoth[1])) * (mmr + 1))).toFixed(max))
                                        :
                                        pBoth[1] === 0
                                            ?
                                            isNaN(((Number(sellorderprice) * Number(quaninput) * (1 / Number(marginbtn1) + 1)) + cum) / ((Number(quaninput)) * (mmr + 1)))
                                                || quaninput && quaninput == "" || Number(quaninput) == 0
                                                ?
                                                "0.0000"
                                                :
                                                (Number(fwallet.balance) + Number(fwallet.crossUnPnl) + cum - (Number(sellorderprice) * Number(quaninput) * (1 / Number(marginbtn1)))) < 0
                                                    ?
                                                    `${t('futures_153')}`
                                                    :
                                                    (((Number(sellorderprice) * Number(quaninput) * (1 / Number(marginbtn1) + 1)) + cum) / ((Number(quaninput)) * (mmr + 1))) < 0
                                                        ?
                                                        "0.0000"
                                                        :
                                                        changeNum((((Number(sellorderprice) * Number(quaninput) * (1 / Number(marginbtn1) + 1)) + cum) / ((Number(quaninput)) * (mmr + 1))).toFixed(max))
                                            :
                                            pBoth[1] < 0
                                                ?
                                                isNaN(((Number(sellorderprice) * Number(quaninput) * (1 / Number(marginbtn1) + 1)) - (pBoth[0] * pBoth[1] * (1 / pBoth[2])) + cum - (pBoth[0] * pBoth[1])) / ((Number(quaninput) - (pBoth[1])) * (mmr + 1)))
                                                    || quaninput && quaninput == "" || Number(quaninput) == 0
                                                    ?
                                                    "0.0000"
                                                    :
                                                    (Number(fwallet.balance) + Number(fwallet.crossUnPnl) + cum - (Number(sellorderprice) * Number(quaninput) * (1 / Number(marginbtn1))) - (pBoth[0] * Math.abs(pBoth[1]) * (1 / pBoth[2]))) < 0
                                                        ?
                                                        `${t('futures_153')}`
                                                        :
                                                        (((Number(sellorderprice) * Number(quaninput) * (1 / Number(marginbtn1) + 1)) - (pBoth[0] * pBoth[1] * (1 / pBoth[2])) + cum - (pBoth[0] * pBoth[1])) / ((Number(quaninput) - (pBoth[1])) * (mmr + 1))) < 0
                                                            ?
                                                            "0.0000"
                                                            :
                                                            changeNum((((Number(sellorderprice) * Number(quaninput) * (1 / Number(marginbtn1) + 1)) - (pBoth[0] * pBoth[1] * (1 / pBoth[2])) + cum - (pBoth[0] * pBoth[1])) / ((Number(quaninput) - (pBoth[1])) * (mmr + 1))).toFixed(max))
                                                :
                                                isNaN(((Number(sellorderprice) * Math.abs(Number(quaninput) - pBoth[1]) * (1 / Number(marginbtn1) + 1)) + cum) / (Math.abs(Number(quaninput) - (pBoth[1])) * (mmr + 1)))
                                                    || quaninput && quaninput == "" || Number(quaninput) == 0 || (Number(quaninput) + (pBoth[1])) == 0
                                                    ?
                                                    "0.0000"
                                                    :
                                                    (Number(fwallet.balance) + Number(fwallet.crossUnPnl) + cum - (Number(sellorderprice) * (Number(quaninput) - pBoth[1]) * (1 / Number(marginbtn1)))) < 0
                                                        ?
                                                        `${t('futures_153')}`
                                                        :
                                                        (((Number(sellorderprice) * Math.abs(Number(quaninput) - pBoth[1]) * (1 / Number(marginbtn1) + 1)) + cum) / (Math.abs(Number(quaninput) - (pBoth[1])) * (mmr + 1))) < 0
                                                            ?
                                                            "0.0000"
                                                            :
                                                            changeNum((((Number(sellorderprice) * Math.abs(Number(quaninput) - pBoth[1]) * (1 / Number(marginbtn1) + 1)) + cum) / (Math.abs(Number(quaninput) - (pBoth[1])) * (mmr + 1))).toFixed(max))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`futures-section4-liq-annotation ${darkname}`}><FontAwesomeIcon icon={faCircleExclamation} /> {t('futures_151')}</div>
                <div className='futures-section4-top-9 display-none-700'>
                    <div className='futures-section4-top-9-1 red'>{t('futures_152')}</div>
                    <div className='futures-section4-top-9-2 green'>{risk ? changeNum(risk.toFixed(2)) : "0.00"}</div>
                    <div className='futures-section4-top-9-2'>%</div>
                </div>
                <div className='futures-section4-top-10 display-none-700'>
                    <div className='futures-section4-top-10-1'>
                        <div className='futures-section4-top-10-1-1'>{t('futures_0')}</div>
                        <div className='futures-section4-top-10-1-2'>{fwallet.balance ? changeNum((Number(fwallet.balance) + Number(fwallet.crossUnPnl)).toFixed(3)) : "0.0000"}</div>
                        <div className='futures-section4-top-10-1-2'>USDT</div>
                    </div>
                    <div className='futures-section4-top-10-1'>
                        <div className='futures-section4-top-10-1-1'>{t('futures_1')}</div>
                        <div className='futures-section4-top-10-1-2'>{fwallet.balance ? changeNum(Number(fwallet.balance).toFixed(3)) : "0.0000"}</div>
                        <div className='futures-section4-top-10-1-2'>USDT</div>
                    </div>
                    <div className='futures-section4-top-10-1'>
                        <div className='futures-section4-top-10-1-1'>{t('futures_2')}</div>
                        <div className='futures-section4-top-10-1-2'>{fwallet.availableBalance ? changeNum(Number(fwallet.availableBalance).toFixed(3)) : "0.0000"}</div>
                        <div className='futures-section4-top-10-1-2'>USDT</div>
                    </div>
                </div>
            </div>
            {
                check_KYC && check_KYC.bnKyc === 0
                    ?
                    <div className='spot-kyc-modal' style={{ position: "absolute" }}>
                        <div className={`spot-kyc-modal-1 ${darkname}`}>
                            <div className='spot-kyc-modal-text'>{t('message_4')}<br />{t('message_5')}</div>
                            <Link to={`/dashboard`} state={{ num: 1 }}>
                                <div className='spot-kyc-modal-btn'>{t('message_6')}</div>
                            </Link>
                        </div>
                    </div>
                    :
                    ""
            }
        </div >
    )
}

function Margin({ setMargintab, marginbtn1, darkname, FuturesChangeLever, firstmargin }) {
    
    const { t } = useTranslation();
    const [btnpercent, setBtnpercent] = useState([marginbtn1]);

    const inputRef = useRef(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const onChangePercent = (e) => {
        const { value } = e.target;
        if (Number(value) <= firstmargin) {
            let onlyNumber = value.replace(/[^0-9]/g, '');
            if (onlyNumber && onlyNumber.length > 0 && onlyNumber.charAt(0) == '0') {
                onlyNumber = onlyNumber.substring(1);
            }
            setBtnpercent([onlyNumber])
        }
    }

    return (
        <div className='futures-margin-modal'>
            <div className='futures-margin-modal-1'>
                <div className={`futures-margin-modal-1-1 ${darkname}`}>
                    <div className='futures-margin-modal-1-1-tit' style={{ textAlign: "center" }}>{t('futures_35')}</div>
                    <div className={`futures-margin-modal-1-1-wrap ${darkname}`}>
                        <div className={`futures-margin-modal-1-1-txt ${darkname}`}>
                            <div className='futures-section4-top-5-4' >
                                <div className='futures-reverage-input'>
                                    <input type='number' className='futures-margin-output' id="output" value={btnpercent[0]} onChange={onChangePercent} style={{ width: "35px", marginTop: "0" }} ref={inputRef} />
                                    <div style={{ opacity: ".5" }}>x</div>
                                </div>
                                {/* <output className='futures-margin-output' id="output"><span>{btnpercent[0]}</span> x</output> */}
                                <Range
                                    values={btnpercent}
                                    step={1}
                                    min={1}
                                    max={firstmargin}
                                    onChange={e => { setBtnpercent(e) }}
                                    renderTrack={({ props, children }) => (
                                        <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart} style={{ ...props.style, height: "36px", display: "flex", width: "93%" }}>
                                            <div
                                                ref={props.ref}
                                                style={{
                                                    height: "5px", width: "100%", borderRadius: "4px",
                                                    background: getTrackBackground({
                                                        values: btnpercent,
                                                        colors: ["#F5AC1D", "#ccc"],
                                                        min: 1,
                                                        max: firstmargin,
                                                    }),
                                                    alignSelf: "center"
                                                }}
                                            >
                                                {children}
                                            </div>
                                        </div>
                                    )}
                                    renderThumb={({ props, isDragged }) => (
                                        <div {...props} style={{ ...props.style, height: "25px", width: "25px", borderRadius: "4px", backgroundColor: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", boxShadow: "0px 2px 6px #AAA" }}>
                                            <div style={{ height: "16px", width: "5px", backgroundColor: isDragged ? "#F5AC1D" : "#CCC" }} />
                                        </div>
                                    )}
                                />
                                <div className='futures-section4-top-5-4-1'>
                                    <div className='futures-section4-top-5-4-2'>0 x</div>
                                    <div className='futures-section4-top-5-4-3'>{Math.floor(firstmargin / 4)} x</div>
                                    <div className='futures-section4-top-5-4-2'>{Math.floor(firstmargin / 2)} x</div>
                                    <div className='futures-section4-top-5-4-3'>{Math.floor((firstmargin * 3) / 4)} x</div>
                                    <div className='futures-section4-top-5-4-2'>{firstmargin} x</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='futures-margin-modal-2'>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([1]) }}>1x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([5]) }} style={{ display: firstmargin >= 5 ? "" : "none" }}>5x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([10]) }} style={{ display: firstmargin >= 10 ? "" : "none" }}>10x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([20]) }} style={{ display: firstmargin >= 20 ? "" : "none" }}>20x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([50]) }} style={{ display: firstmargin >= 50 ? "" : "none" }}>50x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([75]) }} style={{ display: firstmargin >= 75 ? "" : "none" }}>75x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([100]) }} style={{ display: firstmargin >= 100 ? "" : "none" }}>100x</div>
                        <div className={`futures-margin-modal-2-1 ${darkname}`} onClick={() => { setBtnpercent([125]) }} style={{ display: firstmargin >= 125 ? "" : "none" }}>125x</div>
                    </div>
                    <div className='futures-margin-modal-1-1-btn'>
                        <div className='futures-margin-modal-1-1-btn-1' onClick={() => { setMargintab(false); FuturesChangeLever(btnpercent[0]) }}>{t('futures_36')}</div>
                        <div className='futures-margin-modal-1-1-btn-2' onClick={() => setMargintab(false)}>{t('futures_37')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function TPSL({ darkname, setBtnTPSL, buyorderprice, sellorderprice, quaninput, max, symbol, type, reduceOnly, createPostData, setMessage, checkCurrentPosition, marginbtn1 }) {
    
    const { t } = useTranslation();
    let dispatch = useDispatch();
    let Languages = useSelector((state) => { return state.Languages.value })

    const [checked, setChecked] = useState('1')
    const [select0, setSelect0] = useState(false)
    const [selectValue0, setSelectValue0] = useState(t('futures_133'))
    const [select1, setSelect1] = useState(false)
    const [selectValue1, setSelectValue1] = useState(0)
    const [select2, setSelect2] = useState(false)
    const [selectValue2, setSelectValue2] = useState(t('futures_139'))
    const [select3, setSelect3] = useState(false)
    const [selectValue3, setSelectValue3] = useState(t('futures_133'))
    const [select4, setSelect4] = useState(false)
    const [selectValue4, setSelectValue4] = useState(0)
    const [select5, setSelect5] = useState(false)
    const [selectValue5, setSelectValue5] = useState(t('futures_139'))

    const [tp, setTP] = useState("")
    const [tpOption, setTPOption] = useState("")
    const [tpPrice, setTPPrice] = useState("")
    const [sl, setSL] = useState("")
    const [slOption, setSLOption] = useState("")
    const [slPrice, setSLPrice] = useState("")

    const [btn, setBtn] = useState(true)
    const [exitPNL, setExitPNL] = useState("0")
    const [stopPNL, setStopPNL] = useState("0")
    const [spinner, setSpinner] = useState(false)

    const [tpcheck, setTpCheck] = useState(true)
    const [slcheck, setSlCheck] = useState(true)

    useEffect(() => {
        onReset()
    }, [symbol])

    useEffect(() => {
        setSelectValue0(t('futures_133'))
        setSelectValue2(t('futures_139'))
        setSelectValue3(t('futures_133'))
        setSelectValue5(t('futures_139'))
    }, [Languages])

    const onChangeRadio = (e) => {
        let bol = e.target.value
        setChecked(bol)
        onReset()
    }

    const onChangeTP = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setTP(onlyNumber)
    }

    const onChangeTPOption = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setTPOption(onlyNumber)
    }

    useEffect(() => {
        let num = tpOption ? Number(tpOption) : 0

        if (quaninput && Number(quaninput) != 0 && num != 0 && tpcheck) {
            if (checked == '1') {
                if (selectValue1 === 0) {
                    setTP((Number(buyorderprice) * (1 + (num / 100))).toFixed(max))
                    setTPPrice((Number(buyorderprice) * (1 + (num / 100))).toFixed(max))
                }
                else if (selectValue1 === 1) {
                    setTP((Number(buyorderprice) + (num / Number(quaninput))).toFixed(max))
                    setTPPrice((Number(buyorderprice) + (num / Number(quaninput))).toFixed(max))
                }
                else {
                    setTP((Number(buyorderprice) * (1 + (num / (100 * Number(marginbtn1))))).toFixed(max))
                    setTPPrice((Number(buyorderprice) * (1 + (num / (100 * Number(marginbtn1))))).toFixed(max))
                }
            }
            else {
                if (selectValue1 === 0) {
                    setTP((Number(sellorderprice) * (1 - (num / 100))).toFixed(max))
                    setTPPrice((Number(sellorderprice) * (1 - (num / 100))).toFixed(max))
                }
                else if (selectValue1 === 1) {
                    setTP((Number(sellorderprice) - (num / Number(quaninput))).toFixed(max))
                    setTPPrice((Number(sellorderprice) - (num / Number(quaninput))).toFixed(max))
                }
                else {
                    setTP((Number(sellorderprice) * (1 - (num / (100 * Number(marginbtn1))))).toFixed(max))
                    setTPPrice((Number(sellorderprice) * (1 - (num / (100 * Number(marginbtn1))))).toFixed(max))
                }
            }
        }
    }, [buyorderprice, sellorderprice, tpOption, marginbtn1, quaninput])

    const onChangeTPPrice = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setTPPrice(onlyNumber)
        let num = isNaN(onlyNumber) ? 0 : Number(onlyNumber)

        if (quaninput && Number(quaninput) != 0) {
            if (checked == '1') {
                if (selectValue1 === 0) {
                    setTPOption((Math.abs(Number(buyorderprice) - num) / Number(buyorderprice)).toFixed(2))
                }
                else if (selectValue1 === 1) {
                    setTPOption(((num - Number(buyorderprice)) * Number(quaninput)).toFixed(2))
                }
                else {
                    setTPOption((Math.abs(Number(buyorderprice) - num) * 100 * Number(marginbtn1) / Number(buyorderprice)).toFixed(2))
                }
            }
            else {
                if (selectValue1 === 0) {
                    setTPOption((Math.abs(Number(sellorderprice) + num) / Number(sellorderprice)).toFixed(2))
                }
                else if (selectValue1 === 1) {
                    setTPOption(((num + Number(sellorderprice)) * Number(quaninput)).toFixed(2))
                }
                else {
                    setTPOption((Math.abs(Number(sellorderprice) - num) * 100 * Number(marginbtn1) / Number(sellorderprice)).toFixed(2))
                }
            }
        }
    }

    useEffect(() => {
        if (quaninput && tpPrice.length > 0) {
            if (checked == '1') {
                setExitPNL(Number((tpPrice - buyorderprice) * quaninput).toFixed(max))
            }
            else {
                setExitPNL(Number((sellorderprice - tpPrice) * quaninput).toFixed(max))
            }
        }
    }, [tpPrice])

    useEffect(() => {
        if (slPrice.length > 0) {
            if (checked == '1') {
                setStopPNL(Number((buyorderprice - slPrice) * quaninput).toFixed(max))
            }
            else {
                setStopPNL(Number((slPrice - sellorderprice) * quaninput).toFixed(max))
            }
        }
    }, [slPrice])

    const onChangeSL = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setSL(onlyNumber)
    }

    const onChangeSLOption = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setSLOption(selectValue4 === 1 ? "-" + onlyNumber : onlyNumber)
    }

    useEffect(() => {
        let num = slOption ? Number(slOption) : 0

        if (quaninput && Number(quaninput) != 0 && num != 0 && slcheck) {
            if (checked == '1') {
                if (selectValue4 === 0) {
                    setSL(Math.abs(Number(buyorderprice) * (1 - (num / 100))).toFixed(max))
                    setSLPrice(Math.abs(Number(buyorderprice) * (1 - (num / 100))).toFixed(max))
                }
                else if (selectValue4 === 1) {
                    setSL((Number(buyorderprice) + (num / Number(quaninput))).toFixed(max))
                    setSLPrice((Number(buyorderprice) + (num / Number(quaninput))).toFixed(max))
                }
                else {
                    setSL(Math.abs(Number(buyorderprice) * (1 - (num / (100 * Number(marginbtn1))))).toFixed(max))
                    setSLPrice(Math.abs(Number(buyorderprice) * (1 - (num / (100 * Number(marginbtn1))))).toFixed(max))
                }
            }
            else {
                if (selectValue4 === 0) {
                    setSL(Math.abs(Number(sellorderprice) * (1 + (num / 100))).toFixed(max))
                    setSLPrice(Math.abs(Number(sellorderprice) * (1 + (num / 100))).toFixed(max))
                }
                else if (selectValue4 === 1) {
                    setSL((Number(sellorderprice) + (num / Number(quaninput))).toFixed(max))
                    setSLPrice((Number(sellorderprice) + (num / Number(quaninput))).toFixed(max))
                }
                else {
                    setSL(Math.abs(Number(sellorderprice) * (1 + (num / (100 * Number(marginbtn1))))).toFixed(max))
                    setSLPrice(Math.abs(Number(sellorderprice) * (1 + (num / (100 * Number(marginbtn1))))).toFixed(max))
                }
            }
        }
    }, [buyorderprice, sellorderprice, slOption, marginbtn1, quaninput])

    const onChangeSLPrice = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, '')
        setSLPrice(onlyNumber)

        let num = isNaN(onlyNumber) ? 0 : Number(onlyNumber)

        if (quaninput && Number(quaninput) != 0) {
            if (checked == '1') {
                if (selectValue4 === 0) {
                    setSLOption((Math.abs(Number(buyorderprice) - num) / Number(buyorderprice)).toFixed(2))
                }
                else if (selectValue4 === 1) {
                    setSLOption(((Number(buyorderprice) - num) * Number(quaninput)).toFixed(2))
                }
                else {
                    setSLOption((Math.abs(Number(buyorderprice) - num) * 100 * Number(marginbtn1) / Number(buyorderprice)).toFixed(2))
                }
            }
            else {
                if (selectValue4 === 0) {
                    setSLOption((Math.abs(Number(sellorderprice) + num) / Number(sellorderprice)).toFixed(2))
                }
                else if (selectValue4 === 1) {
                    setSLOption(((Number(sellorderprice) + num) * Number(quaninput)).toFixed(2))
                }
                else {
                    setSLOption((Math.abs(Number(sellorderprice) + num) * 100 * Number(marginbtn1) / Number(sellorderprice)).toFixed(2))
                }
            }
        }
    }

    const onClickSelect1 = (e) => {
        setSelectValue1(e);
        setSelect1(true)
        setTP("")
        setTPOption("")
        setTPPrice("")
        setExitPNL("0")
    }

    const onClickSelect4 = (e) => {
        setSelectValue4(e);
        setSelect4(true)
        setSL("")
        setSLOption("")
        setSLPrice("")
        setStopPNL("0")
    }

    const onReset = () => {
        setSelect0(false)
        setSelectValue0(t('futures_133'))
        setSelect1(false)
        setSelectValue1(0)
        setSelect2(false)
        setSelectValue2(t('futures_139'))
        setSelect3(false)
        setSelectValue3(t('futures_133'))
        setSelect4(false)
        setSelectValue4(0)
        setSelect5(false)
        setSelectValue5(t('futures_139'))
        setTP("")
        setTPOption("")
        setTPPrice("")
        setSL("")
        setSLOption("")
        setSLPrice("")
        setExitPNL("0")
        setStopPNL("0")
    }


    const checkPosition = () => {
        if (btn) {
            setSpinner(true)
            setBtn(false)
            if (checkCurrentPosition) {
                onSubmit_TPSL()
            }
            else {
                onSubmitFuturesOrder()
            }
        }
    }

    const onSubmitFuturesOrder = () => {
        let url = process.env.REACT_APP_API + '/api/v1/trade/bn/futures/order'
        let postData = checked == '1' ? createPostData("BUY", buyorderprice, quaninput) : createPostData("SELL", sellorderprice, quaninput);
        onSubmitFutures(postData, url);
    };

    const onSubmitFutures = async (postData, url) => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(url, postData, { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                if (response?.data?.code >= 0) {
                    setTimeout(() => {
                        onSubmit_TPSL()
                    }, 2000)
                }
                else {
                    setTimeout(() => {
                        setSpinner(false)
                        setBtn(true)
                        setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                    }, 500)
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 68, "msg": error }))
            })
        }
    }

    const onSubmit_TPSL = async () => {
        const objString = localStorage.getItem('token');
        if (objString  ) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(process.env.REACT_APP_API + `/api/v1/trade/bn/futures/tpsl/order`, {
                symbol: symbol,
                side: checked == "1" ? "BUY" : "SELL",
                type: type,
                quantity: quaninput,
                tpPrice: tpPrice,
                tpStopPrice: tp,
                tpType: selectValue0 == t('futures_133') ? "MARK_PRICE" : "CONTRACT_PRICE",
                slPrice: slPrice,
                slStopPrice: sl,
                slType: selectValue3 == t('futures_133') ? "MARK_PRICE" : "CONTRACT_PRICE",
                reduceOnly: reduceOnly,
            }, {
                headers: { Authorization: `Bearer ${token}` }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setBtnTPSL(false)
                    setMessage({ type: "binance", code: response?.data?.code, msg: `${t('message_12')}` })
                }
                else {
                    setBtn(true)
                    setMessage({ type: "binance", code: response?.data?.code, msg: response?.data?.msg })
                }
                setTimeout(() => {
                    setBtn(true)
                    setSpinner(false)
                }, 1000)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 69, "msg": error }))
            })
        }
    }

    return (
        <>
            {
                spinner
                    ?
                    <div className="dashboard-right-bottom1-modal" style={{ zIndex: "10010" }}>
                        <SyncLoader color={"#F5AC1D"} />
                    </div>
                    :
                    ""
            }
            <div className='dashboard-right-bottom1-modal' style={{ backdropFilter: "blur(1px)" }}></div>
            <div className={`tpsl-modal ${darkname}`}>
                <div className='tpsl-modal-1'><FontAwesomeIcon icon={faXmark} style={{ cursor: "pointer" }} onClick={() => setBtnTPSL(false)} /></div>
                <div className='tpsl-modal-2'>
                    <div className='tpsl-modal-2-1'>
                        <span>{t('futures_128')}</span>
                    </div>
                    <div className='tpsl-modal-2-2'>
                        <div className='tpsl-modal-2-2-1'>
                            <input className='tpsl-modal-2-2-2' type="checkbox" value="1" checked={checked === '1'} onChange={onChangeRadio} />
                            <div>{t('futures_129')}</div>
                        </div>
                        <div className='tpsl-modal-2-2-1'>
                            <input className='tpsl-modal-2-2-2' type="checkbox" value="2" checked={checked === '2'} onChange={onChangeRadio} />
                            <div>{t('futures_130')}</div>
                        </div>
                        <div className='tpsl-modal-2-2-3' onClick={() => onReset()}>{t('futures_131')}</div>
                    </div>
                </div>
                <div className='tpsl-modal-3'>
                    <div className='tpsl-modal-3-1'>
                        <div style={{ fontWeight: "bold" }}>{t('futures_132')}</div>
                        <div className='tpsl-modal-3-2-3' onClick={() => setSelect0(e => !e)}>
                            <span style={{ fontWeight: "bold" }}>{selectValue0} <FontAwesomeIcon icon={select0 ? faAngleUp : faAngleDown} /></span>
                            {
                                select0
                                    ?
                                    <>
                                        <div className='select-modal' onClick={() => setSelect0(true)}></div>
                                        <div className={`select-box ${darkname}`}>
                                            <div onClick={() => { setSelectValue0(t('futures_133')); setSelect0(true) }}>{t('futures_133')}</div>
                                            <div onClick={() => { setSelectValue0(t('futures_134')); setSelect0(true) }}>{t('futures_134')}</div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    <div className='tpsl-modal-3-2'>
                        <div className={`tpsl-modal-3-2-1 ${darkname}`}>
                            <input className='tpsl-modal-input' type='number' value={tp} onChange={onChangeTP} placeholder='TP' />
                        </div>
                        <div className={`tpsl-modal-3-2-2 ${darkname}`} onClick={() => setTpCheck(true)}>
                            <div style={{ width: "70%" }}>
                                <input className='tpsl-modal-input' type='number' value={tpOption} onChange={onChangeTPOption} placeholder={selectValue1 === 0 ? `${t('futures_135')}` : selectValue1 === 1 ? `${t('futures_136')}` : `${t('futures_137')}`} />
                            </div>
                            <div className='tpsl-modal-3-2-3' onClick={() => setSelect1(e => !e)} >
                                <span style={{ fontWeight: "bold" }}>{selectValue1 === 0 ? "%" : selectValue1 === 1 ? "USDT" : "%"} <FontAwesomeIcon icon={faAngleDown} /></span>
                                {
                                    select1
                                        ?
                                        <>
                                            <div className='select-modal' onClick={() => setSelect1(true)}></div>
                                            <div className={`select-box ${darkname}`}>
                                                <div onClick={() => { onClickSelect1(0) }}>{t('futures_135')}(%)</div>
                                                <div onClick={() => { onClickSelect1(1) }}>{t('futures_136')}(USDT)</div>
                                                <div onClick={() => { onClickSelect1(2) }}>{t('futures_137')}(%)</div>
                                            </div>
                                        </>
                                        :
                                        ""
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`tpsl-modal-3-3 ${darkname}`} onClick={() => { setTpCheck(false) }}>
                        <div style={{ width: "50%" }}>
                            {
                                selectValue2 == t('futures_140')
                                    ?
                                    <div className='tpsl-modal-input' >{t('futures_138')}</div>
                                    :
                                    <input className='tpsl-modal-input' type='number' value={tpPrice} onChange={onChangeTPPrice} placeholder='TP Price' />
                            }
                        </div>
                        <div className='tpsl-modal-3-2-3' onClick={() => setSelect2(e => !e)}>
                            <span style={{ fontWeight: "bold" }}>{selectValue2} <FontAwesomeIcon icon={select2 ? faAngleUp : faAngleDown} /></span>
                            {
                                select2
                                    ?
                                    <>
                                        <div className='select-modal' onClick={() => setSelect2(true)}></div>
                                        <div className={`select-box ${darkname}`}>
                                            <div onClick={() => { setSelectValue2(t('futures_139')); setSelect2(true) }}>{t('futures_139')}</div>
                                            <div onClick={() => { setSelectValue2(t('futures_140')); setSelect2(true) }}>{t('futures_140')}</div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div>
                <div className='tpsl-modal-3'>
                    <div className='tpsl-modal-3-1'>
                        <div style={{ fontWeight: "bold" }}>{t('futures_141')}</div>
                        <div className='tpsl-modal-3-2-3' onClick={() => setSelect3(e => !e)}>
                            <span style={{ fontWeight: "bold" }}>{selectValue3} <FontAwesomeIcon icon={select3 ? faAngleUp : faAngleDown} /></span>
                            {
                                select3
                                    ?
                                    <>
                                        <div className='select-modal' onClick={() => setSelect3(true)}></div>
                                        <div className={`select-box ${darkname}`}>
                                            <div onClick={() => { setSelectValue3(t('futures_133')); setSelect3(true) }}>{t('futures_133')}</div>
                                            <div onClick={() => { setSelectValue3(t('futures_134')); setSelect3(true) }}>{t('futures_134')}</div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                    <div className='tpsl-modal-3-2'>
                        <div className={`tpsl-modal-3-2-1 ${darkname}`}>
                            <input className='tpsl-modal-input' type='number' value={sl} onChange={onChangeSL} placeholder='SL' />
                        </div>
                        <div className={`tpsl-modal-3-2-2 ${darkname}`} onClick={() => setSlCheck(true)}>
                            <div style={{ width: "70%" }}>
                                <input className='tpsl-modal-input' type='number' value={slOption} onChange={onChangeSLOption} placeholder={selectValue4 === 0 ? `${t('futures_135')}` : selectValue4 === 1 ? `${t('futures_136')}` : `${t('futures_137')}`} />
                            </div>
                            <div className='tpsl-modal-3-2-3' onClick={() => setSelect4(e => !e)}>
                                <span style={{ fontWeight: "bold" }}>{selectValue4 === 0 ? "%" : selectValue4 === 1 ? "USDT" : "%"} <FontAwesomeIcon icon={faAngleDown} /></span>
                                {
                                    select4
                                        ?
                                        <>
                                            <div className='select-modal' onClick={() => setSelect4(true)}></div>
                                            <div className={`select-box ${darkname}`}>
                                                <div onClick={() => { onClickSelect4(0) }}>{t('futures_135')}(%)</div>
                                                <div onClick={() => { onClickSelect4(1) }}>{t('futures_136')}(USDT)</div>
                                                <div onClick={() => { onClickSelect4(2) }}>{t('futures_137')}(%)</div>
                                            </div>
                                        </>
                                        :
                                        ""
                                }
                            </div>
                        </div>
                    </div>
                    <div className={`tpsl-modal-3-3 ${darkname}`} onClick={() => setSlCheck(false)}>
                        <div style={{ width: "50%" }}>
                            {
                                selectValue5 == t('futures_140')
                                    ?
                                    <div className='tpsl-modal-input' >{t('futures_138')}</div>
                                    :
                                    <input className='tpsl-modal-input' type='number' value={slPrice} onChange={onChangeSLPrice} placeholder='SL Price' />
                            }
                        </div>
                        <div className='tpsl-modal-3-2-3' onClick={() => setSelect5(e => !e)}>
                            <span style={{ fontWeight: "bold" }}>{selectValue5} <FontAwesomeIcon icon={select5 ? faAngleUp : faAngleDown} /></span>
                            {
                                select5
                                    ?
                                    <>
                                        <div className='select-modal' onClick={() => setSelect5(true)}></div>
                                        <div className={`select-box ${darkname}`}>
                                            <div onClick={() => { setSelectValue5(t('futures_139')); setSelect5(true) }}>{t('futures_139')}</div>
                                            <div onClick={() => { setSelectValue5(t('futures_140')); setSelect5(true) }}>{t('futures_140')}</div>
                                        </div>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </div>
                <div className='tpsl-modal-4'>
                    <div className='tpsl-modal-4-1'>
                        <div>{t('futures_142')}</div>
                        <div>{exitPNL} USDT</div>
                    </div>
                    <div className='tpsl-modal-4-1'>
                        <div>{t('futures_143')}</div>
                        <div>{stopPNL} USDT</div>
                    </div>
                </div>
                <div className='tpsl-modal-5' onClick={() => checkPosition()}>{t('futures_144')}</div>
            </div >
        </>
    )
}

export default BFsection4;
import '../../css/wallet.css'
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { checkSelectType, changeWalletPage } from '../../../store';
import { useTranslation } from "react-i18next";

function Common_Withdrawal({ darkname }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [isToggled1, setIsToggled1] = useState(false);
    const [selectedOption, setSelectedOption] = useState("");
    const [tab, setTab] = useState(false)
    const toggleButton1 = () => {
        // setIsToggled1(!isToggled1);
    };

    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_33')}</div>
                <p className='history-right-top-2'>{t('header_34')}</p>
            </div>
            <div className='wallet-right-form'>
                <div className='wallet-right-form-1'>
                    <div className='wallet-right-form-1-box'>
                        <div className='wallet-right-form-1-box-1'>
                            <p>{t('wallet_10')}</p>
                            <div className={`wallet-right-form-1-box-1-label ${isToggled1 ? 'toggled' : ''}  ${darkname}`} onClick={toggleButton1}>
                                <div className="wallet-right-form-1-box-1-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToggled1
                        ?
                        <div className='wallet-right-form-3'>
                            <div className='wallet-right-form-3-1'>{t('wallet_10')}</div>
                            <div className='wallet-right-form-3-2'>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_84')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        1. {t('wallet_85')} <br />
                                        2. {t('wallet_86')} <br />
                                        3. {t('wallet_87')} <br />
                                        4. {t('wallet_88')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_89')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_90')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_91')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_92')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_93')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_94')} <br />
                                        1. {t('wallet_95')} <br />
                                        2. {t('wallet_96')} <br /> <br />

                                        {t('wallet_34')} <br />
                                        - {t('wallet_97')} <br />
                                        - {t('wallet_98')} <br />
                                        - {t('wallet_99')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_100')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_101')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_102')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} onClick={() => setTab(e => !e)}>
                            {t('wallet_225')}
                        </div>
                        {
                            tab
                                ?
                                <>
                                    <div className="header-modal" onClick={() => setTab(false)}></div>
                                    <div className={`wallet-right-form-4-3-box ${darkname}`}>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(1)); dispatch(changeWalletPage(1)); setTab(false) }}>{t('exchange_0')}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(0)); dispatch(changeWalletPage(0)); setTab(false) }}>{t('exchange_1')}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(2)); dispatch(changeWalletPage(2)); setTab(false) }}>{t('exchange_2')}</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_53')}</div>
                    <div className='wallet-right-form-4-2'>
                        <input type='text' className={`wallet-right-form-4-3 ${darkname}`} placeholder={t('wallet_54')} />
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_57')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3-1 ${darkname}`}>
                            <select
                                value={selectedOption}
                            >
                                <option value="">{t('wallet_58')}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <p className='wallet-right-form-5'>
                    {t('wallet_103')}<br />
                    {t('wallet_104')}
                </p>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_105')}</div>
                    <div className='wallet-right-form-4-2'>
                        <input className={`wallet-right-form-4-3 ${darkname}`} placeholder='-' />
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_63')}</div>
                    <div className='wallet-right-form-4-2'>
                        <input className={`wallet-right-form-4-3 ${darkname}`} placeholder='-' />
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`} style={{ display: "flex", alignItems: "center" }}>
                    <div className='wallet-right-form-4-1'>{t('wallet_106')}</div>
                    <div className='wallet-right-form-4-1-1'>
                        <span className='wallet-right-form-4-1-1-span'></span>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_107')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`}>
                            <input className='wallet-right-form-4-3-input' placeholder='0' />
                            <span className='wallet-right-form-4-3-span'></span>
                            <div className='wallet-right-form-4-3-btn' >{t('wallet_108')}</div>
                        </div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_111')}</div>
                    <div className='wallet-right-form-4-2' style={{ opacity: ".5" }}>
                        {t('wallet_112')}
                    </div>
                    <div className='wallet-right-form-8'>
                    </div>
                </div>
                <div className='wallet-right-form-9'>
                    <div className={`wallet-right-form-9-1 ${darkname}`}>{t('wallet_113')}</div>
                </div>
                <div className='wallet-right-form-7'>
                    <div className='wallet-right-form-7-1'>{t('wallet_114')}</div>
                    <div className='wallet-right-form-7-2'></div>
                    <div className='wallet-right-form-7-3'>
                        <div className={`wallet-right-form-7-3-top ${darkname}`}>
                            <div className='wallet-right-form-7-3-top-1'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_68')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_69')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-1'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_70')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_71')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-3'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_72')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_73')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-4 wallet-display-none-800'>{t('wallet_74')}</div>
                        </div>
                        <div className='wallet-right-form-7-3-bottom'>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Common_Withdrawal;
import '../../css/wallet.css'
import { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from 'react-redux'
import { checkSelectType, changeWalletPage } from '../../../store';
import { useTranslation } from "react-i18next";


function OKX_Transfer({ darkname }) {
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [tab, setTab] = useState(false)

    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_37')}</div>
                <p className='history-right-top-2'>{t('header_38')}</p>
            </div>
            <div className='wallet-right-form'>
                <div className='wallet-right-form-1' style={{ height: "24px" }}>
                </div>

                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_153')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} style={{ height: "18px", cursor: "auto", cursor: "pointer" }} onClick={() => setTab(e => !e)}>
                        </div>
                        {
                            tab
                                ?
                                <>
                                    <div className="header-modal" onClick={() => setTab(false)}></div>
                                    <div className={`wallet-right-form-4-3-box ${darkname}`}>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(1)); dispatch(changeWalletPage(1)); setTab(false) }}>{t('exchange_0')}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(2)); dispatch(changeWalletPage(2)); setTab(false) }}>{t('exchange_2')}</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_154')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} style={{ height: "18px", cursor: "auto" }}></div>
                    </div>
                </div>
                <div className={`wallet-right-form-10 ${darkname}`}>
                    <div className='wallet-right-form-10-1'>
                        <p className='wallet-right-form-10-2'>{t('wallet_131')} <span>-</span></p>
                        <p className='wallet-right-form-10-3'>{t('wallet_131')} <span>-</span></p>
                    </div>
                    <div className='wallet-right-form-10-1' style={{ marginTop: "3px" }}>
                        <p className={`wallet-right-form-10-4 ${darkname}`}>{t('wallet_155')}</p>
                        <div className='wallet-right-form-10-5'><FontAwesomeIcon icon={faCircleArrowRight} /></div>
                        <p className={`wallet-right-form-10-4 ${darkname}`}>{t('wallet_156')}</p>
                    </div>
                    <div className='wallet-right-form-10-1' style={{ marginTop: "3px" }}>
                        <p className='wallet-right-form-10-3'>{t('wallet_157')}</p>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_158')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} style={{ height: "18px", cursor: "auto" }}></div>
                    </div>
                </div>
                <div className='wallet-right-form-9'>
                    <div className={`wallet-right-form-9-1 ${darkname}`}>{t('wallet_223')}</div>
                </div>
            </div>
        </div >
    )
}

export default OKX_Transfer;
import '../../css/wallet.css'
import { List } from 'react-virtualized';
import { Link } from "react-router-dom"
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType } from '../../../store';
import { ClipLoader } from "react-spinners";
import IMG from "../../img"
import { useTranslation } from "react-i18next";
import { changeNum, RoundDown, useCounter } from "../../common/function"

let filterSymbol = []
let cnt = 0

function Binance_Overview({ darkname, walletData, BcurrentUSDT, setFastCoin }) {
    
    const { t } = useTranslation();
    let dispatch = useDispatch();
    const [tab1, setTab1] = useState(0);
    const [search, setSearch] = useState("");
    const [checked, setChecked] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [activeItem, setActiveItem] = useState(null);
    const [totalSum, setTotalSum] = useState(0);
    const [avaAmount, setAvaAmount] = useState(0);
    const [lockAmount, setLockAmount] = useState(0);

    const onChange = (e) => {
        setSearch(e.target.value)
    }

    useEffect(() => {
        let total = 0;
        let ava = 0;
        let lock = 0;

        if (tab1 === 0) {
            if (walletData) {
                for (let i = 0; i < walletData.length; i++) {
                    total += (Number(walletData[i].availBal) + Number(walletData[i].frozenBal)) * Number(walletData[i].lastPrice)
                    ava += Number(walletData[i].availBal) * Number(walletData[i].lastPrice)
                    lock += Number(walletData[i].frozenBal) * Number(walletData[i].lastPrice)
                }
            }
            setTotalSum(total)
            setAvaAmount(ava)
            setLockAmount(lock)
        }
        else {
            if (BcurrentUSDT) {
                setTotalSum(Number(BcurrentUSDT.balance))
                setAvaAmount(Number(BcurrentUSDT.availableBalance))
                setLockAmount(Number(BcurrentUSDT.balance) - Number(BcurrentUSDT.availableBalance))
            }
        }

    }, [walletData, tab1])

    useEffect(() => {
        setActiveItem(null)
        if (cnt === 0) {
            cnt += 1
            handleClick()
        }
        else {
            handleClick()
        }
    }, [tab1])

    if (tab1 == 0 && walletData) {
        filterSymbol = walletData.filter((p) => {
            return (p.symbol.toLocaleLowerCase().includes(search.toLocaleLowerCase()) || p.fullName.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        })
    }
    else filterSymbol = BcurrentUSDT;

    const handleItemClick = (index) => {
        setActiveItem(index);
    };

    const handleClick = () => {
        setIsClicked(true);

        setTimeout(() => {
            setIsClicked(false);
        }, 500);
    };

    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_29')}</div>
                <p className='history-right-top-2'>{t('header_30')}</p>
            </div>
            <div className='history-right-bottom'>
                <div className='history-right-bottom-1'>
                    <div className={`history-right-bottom-1-btn current ${darkname}`} onClick={() => { dispatch(checkSelectType(1)); handleClick() }}>{t('exchange_0')}</div>
                    <div className={`history-right-bottom-1-btn ${darkname}`} onClick={() => { dispatch(checkSelectType(0)); handleClick() }}>{t('exchange_1')}</div>
                    <div className={`history-right-bottom-1-btn ${darkname}`} onClick={() => { dispatch(checkSelectType(2)); handleClick() }}>{t('exchange_2')}</div>
                </div>
                <div className='history-right-bottom-2'>
                    <div className='history-right-bottom-2-1'>
                        <div className='history-right-bottom-2-1-tit'>{t('wallet_0')}</div>
                    </div>
                    <div className='wallet-right-bottom-1'>
                        <div className={tab1 === 0 ? 'wallet-right-bottom-1-1 current' : 'wallet-right-bottom-1-1'} onClick={() => { setTab1(0); handleClick() }}>{t('market_1')}</div>
                        <div className={tab1 === 1 ? 'wallet-right-bottom-1-1 current' : 'wallet-right-bottom-1-1'} onClick={() => { setTab1(1); handleClick() }}>{t('market_2')}</div>
                    </div>
                    <div className='history-right-bottom-3'>
                        <div className='history-right-bottom-3-1'>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 360 360">
                                        <g>
                                            <g>
                                                <path className="fill-color" d="M358.5,180.1c-0.2,99.2-80.1,178.7-180.1,177.8C80.5,357,2.2,277.3,2.5,178.8C2.8,81.2,83,1.8,181.1,2
			C278.9,2.2,358.6,82.2,358.5,180.1z M159,180c-0.9,1.3-1.8,2.5-2.6,3.7c-19,26.6-38.1,53.2-57.1,79.8c-3.5,4.9-4.4,10.2-1.5,15.6
			c2.8,5.3,7.4,7.7,13.4,7.7c46.3,0,92.7,0,139,0c8.3,0,14.5-5.8,14.7-14c0.2-13.1,0.2-26.2,0-39.3c-0.1-8.2-6.3-14-14.6-14.1
			c-3.1-0.1-6.2,0-9.3,0c-9.3,0.1-15.2,6-15.3,15.3c0,4.3,0,8.6,0,12.7c-22.4,0-44.3,0-66.7,0c0.9-1.3,1.6-2.3,2.3-3.3
			c12.9-18.1,25.8-36.2,38.7-54.3c5.1-7.1,5-12.6,0-19.7c-12.9-18.1-25.8-36.2-38.7-54.3c-0.7-1-1.4-2-2.3-3.3c22.6,0,44.5,0,66.7,0
			c0,4.6-0.1,9,0,13.5c0.1,8.4,6.1,14.4,14.5,14.5c3,0.1,5.9,0,8.9,0c9.8,0,15.7-5.8,15.7-15.6c0-12,0-24,0-36
			c0-10.1-5.8-15.8-15.9-15.8c-45.6,0-91.2,0-136.8,0c-6.3,0-11.5,2-14.5,7.7c-3.1,5.9-1.6,11.3,2.2,16.6
			c9,12.4,17.8,24.9,26.7,37.3C137.4,149.8,148.2,164.9,159,180z"/>
                                            </g>
                                        </g>
                                    </svg>
                                    <div>{t('wallet_1')}</div>
                                </div>
                                <div>
                                    <div className='history-right-bottom-3-1-box-txt-1'>
                                        <span>{changeNum(useCounter(RoundDown(totalSum, 6), 6))}</span>
                                        <span className='history-right-bottom-span'>USDT</span>
                                    </div>
                                </div>
                            </div>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512">
                                        <g>
                                            <path className="fill-color" d="M503.2,212.9c-15.3-81.3-60-141.3-132.7-180.3C341,16.8,309.2,8.3,275.8,5.8c-1-0.1-2.1-0.5-3.1-0.8
		c-11.1,0-22.2,0-33.3,0c-8.8,1.2-17.7,2-26.4,3.8C113,29.2,47.1,88.2,15.5,184.9c-5.3,16.2-6.5,33.8-9.6,50.8
		c-0.2,1.2-0.6,2.4-0.9,3.7c0,11.1,0,22.2,0,33.3c1.3,9,2.1,18,3.9,26.9C26.6,389.1,77.7,451.8,161,488.3
		c23.7,10.4,48.9,15.9,74.7,17.8c0.9,0.1,1.7,0.6,2.6,0.9c11.1,0,22.2,0,33.3,0c9.2-1.3,18.4-2.1,27.4-3.8
		c87.6-16.9,149.7-66.5,187.1-147.1c11.7-25.4,17.9-52.4,20-80.3c0.1-1.1,0.5-2.1,0.8-3.2c0-10.8,0-21.6,0-32.4
		C505.7,231.2,504.9,222,503.2,212.9z M338.3,308.7c-4.2,27.7-22.1,45.3-51.8,51.1c-4,0.8-8.1,1.4-12.6,2.1c0,13.6,0,27.1,0,40.8
		c-12,0-23.5,0-35.4,0c0-14,0-27.7,0-41.6c-24.6-3.9-47.4-11.7-67.7-26.3c6.1-11.9,12-23.3,18.1-35.3c14,10.1,28.9,17.6,45.2,22
		c14.4,3.9,29,5.4,43.7,1.7c8.8-2.2,15.4-6.8,16.8-16.7c1.4-9.9-2.9-17.4-11.4-21.2c-11.2-5-23-8.8-34.6-12.8
		c-11.1-3.8-22.6-6.7-33.5-11c-38.9-15.1-41.6-49.1-29.6-77.3c8.7-20.5,26.3-30.3,46.9-35.8c1.9-0.5,3.8-0.9,6.2-1.5
		c0-12.6,0-25.3,0-38.2c11.8,0,23.2,0,35.1,0c0,12.4,0,24.8,0,37.8c21.5,3.1,40,11.3,57.8,22.7c-6.1,11.2-12,22.1-18.2,33.4
		c-13-10.1-27.4-16.1-42.9-19.3c-10.3-2.1-20.7-2.3-30.9,0.6c-10,2.8-16.2,9.6-17.2,18.7c-1.1,10.1,2.5,18,13,22
		c13.8,5.4,28.3,9,42.5,13.6c10.2,3.4,20.7,6.4,30.3,11.1C331.9,261.1,342.3,282.2,338.3,308.7z"/>
                                        </g>
                                    </svg>
                                    <div>{t('wallet_2')}</div>
                                </div>
                                <div>
                                    <div className='history-right-bottom-3-1-box-txt-1'>
                                        <span>{changeNum(useCounter(RoundDown(avaAmount, 6), 6))}</span>
                                        <span className='history-right-bottom-span'>USDT</span>
                                    </div>
                                </div>
                            </div>
                            <div className={`history-right-bottom-3-1-box ${darkname}`}>
                                <div className='history-right-bottom-3-1-box-tit'>
                                    <svg version="1.1" x="0px" y="0px" viewBox="0 0 512 512">
                                        <g>
                                            <path className="fill-color" d="M503.2,212.9c-15.3-81.3-60-141.3-132.7-180.3C341,16.8,309.2,8.3,275.8,5.8c-1-0.1-2.1-0.5-3.1-0.8
		c-11.1,0-22.2,0-33.3,0c-8.8,1.2-17.7,2-26.4,3.8C113,29.2,47.1,88.2,15.5,184.9c-5.3,16.2-6.5,33.8-9.6,50.8
		c-0.2,1.2-0.6,2.4-0.9,3.7c0,11.1,0,22.2,0,33.3c1.3,9,2.1,18,3.9,26.9C26.6,389.1,77.7,451.8,161,488.3
		c23.7,10.4,48.9,15.9,74.7,17.8c0.9,0.1,1.7,0.6,2.6,0.9c11.1,0,22.2,0,33.3,0c9.2-1.3,18.4-2.1,27.4-3.8
		c87.6-16.9,149.7-66.5,187.1-147.1c11.7-25.4,17.9-52.4,20-80.3c0.1-1.1,0.5-2.1,0.8-3.2c0-10.8,0-21.6,0-32.4
		C505.7,231.2,504.9,222,503.2,212.9z M338.3,308.7c-4.2,27.7-22.1,45.3-51.8,51.1c-4,0.8-8.1,1.4-12.6,2.1c0,13.6,0,27.1,0,40.8
		c-12,0-23.5,0-35.4,0c0-14,0-27.7,0-41.6c-24.6-3.9-47.4-11.7-67.7-26.3c6.1-11.9,12-23.3,18.1-35.3c14,10.1,28.9,17.6,45.2,22
		c14.4,3.9,29,5.4,43.7,1.7c8.8-2.2,15.4-6.8,16.8-16.7c1.4-9.9-2.9-17.4-11.4-21.2c-11.2-5-23-8.8-34.6-12.8
		c-11.1-3.8-22.6-6.7-33.5-11c-38.9-15.1-41.6-49.1-29.6-77.3c8.7-20.5,26.3-30.3,46.9-35.8c1.9-0.5,3.8-0.9,6.2-1.5
		c0-12.6,0-25.3,0-38.2c11.8,0,23.2,0,35.1,0c0,12.4,0,24.8,0,37.8c21.5,3.1,40,11.3,57.8,22.7c-6.1,11.2-12,22.1-18.2,33.4
		c-13-10.1-27.4-16.1-42.9-19.3c-10.3-2.1-20.7-2.3-30.9,0.6c-10,2.8-16.2,9.6-17.2,18.7c-1.1,10.1,2.5,18,13,22
		c13.8,5.4,28.3,9,42.5,13.6c10.2,3.4,20.7,6.4,30.3,11.1C331.9,261.1,342.3,282.2,338.3,308.7z"/>
                                        </g>
                                    </svg>
                                    <div>{t('wallet_3')}</div>
                                </div>
                                <div>
                                    <div className='history-right-bottom-3-1-box-txt-1'>
                                        <span>{changeNum(useCounter(RoundDown(lockAmount, 6), 6))}</span>
                                        <span className='history-right-bottom-span'>USDT</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='wallet-right-bottom-2'>
                        <div className={`wallet-right-bottom-2-1 ${darkname}`}>
                            <input type="text" placeholder={`${t('wallet_11')}`} value={search} onChange={onChange} />
                        </div>
                        <div className='wallet-right-bottom-2-2' onClick={() => setChecked(checked => !checked)}>
                            <span className={checked ? 'regist-box-left-form-3-span1 checked' : 'regist-box-left-form-3-span1'} ></span>
                            <span className='wallet-right-bottom-2-2-span'>{t('wallet_4')}</span>
                        </div>
                    </div>
                    <div className='history-right-bottom-4'>
                        <div className={`history-right-bottom-4-top ${darkname}`} style={{ paddingRight: "10px" }}>
                            <div className='wallet-right-bottom-4-top-1' style={{ textAlign: "left" }}>{t('wallet_5')}</div>
                            <div className='wallet-right-bottom-4-top-1 wallet-display-none-800'>{t('wallet_6')}</div>
                            <div className='wallet-right-bottom-4-top-1'>{t('wallet_7')}</div>
                            <div className='wallet-right-bottom-4-top-1'>{t('wallet_8')}</div>
                            {
                                tab1 === 0
                                    ?
                                    <>
                                        <div className='wallet-right-bottom-4-top-1  wallet-display-none-800'>{t('wallet_224')} (USDT)</div>
                                        <div className='wallet-right-bottom-4-top-1'></div>
                                    </>
                                    :
                                    ""
                            }
                        </div>
                        <div className={`wallet-right-bottom-4-bottom ${darkname}`}>
                            {
                                isClicked
                                    ?
                                    <div className='history-right-bottom-4-bottom-1'>
                                        <ClipLoader color={"#F5AC1D"} />
                                    </div>
                                    :
                                    tab1 === 0
                                        ?
                                        checked
                                            ?
                                            filterSymbol.length === 0
                                                ?
                                                <div className='history-right-bottom-4-bottom-1'>
                                                    <img src='/img/img_nofound.png'></img>
                                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                    <p>{t('message_0')}</p>
                                                </div>
                                                :
                                                filterSymbol.map(function (a, i) {
                                                    if (Number(a.availBal) + Number(a.frozenBal) > 0) {
                                                        return (
                                                            <MyWalletItem
                                                                key={i}
                                                                item={a}
                                                                active={activeItem === i}
                                                                onClick={() => handleItemClick(i)}
                                                                darkname={darkname}
                                                                setFastCoin={setFastCoin}
                                                            />
                                                        )
                                                    }
                                                })
                                            :
                                            filterSymbol.length === 0
                                                ?
                                                <div className='history-right-bottom-4-bottom-1'>
                                                    <img src='/img/img_nofound.png'></img>
                                                    <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                    <p>{t('message_0')}</p>
                                                </div>
                                                :
                                                <MyListComponent data={filterSymbol} activeItem={activeItem} darkname={darkname} handleItemClick={handleItemClick} setFastCoin={setFastCoin} />
                                        // filterSymbol.map(function (a, i) {
                                        //     return (
                                        //         <MyWalletItem
                                        //             key={i}
                                        //             item={a}
                                        //             active={activeItem === i}
                                        //             onClick={() => handleItemClick(i)}
                                        //             darkname={darkname}
                                        //             setFastCoin={setFastCoin}
                                        //         />
                                        //     )
                                        // })
                                        :
                                        filterSymbol.length === 0
                                            ?
                                            <div className='history-right-bottom-4-bottom-1'>
                                                <img src='/img/img_nofound.png'></img>
                                                <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                                <p>{t('message_0')}</p>
                                            </div>
                                            :
                                            <MyWalletFuturesItem
                                                item={filterSymbol}
                                                darkname={darkname} />

                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function MyWalletFuturesItem({ item, darkname }) {
    return (
        <>
            {
                <div className={`wallet-right-bottom-3 ${darkname}`}>
                    <div className='wallet-right-bottom-3-1-0' style={{ textAlign: "left" }}>
                        <img src={`/img/coin/USDT.png`}></img>
                        <span style={{ marginLeft: "10px" }}>USDT</span>
                    </div>
                    <div className='wallet-right-bottom-3-1-1 wallet-display-none-800'>{changeNum(RoundDown(Number(item.balance), 6))}</div>
                    <div className='wallet-right-bottom-3-1-1'>{changeNum(RoundDown(Number(item.availableBalance), 6))}</div>
                    <div className='wallet-right-bottom-3-1-1'>{changeNum(RoundDown(Number(item.balance) - Number(item.availableBalance), 6))}</div>
                </div>
            }
        </>
    )
}

function MyListComponent({ data, activeItem, darkname, handleItemClick, setFastCoin }) {
    const rowRenderer = ({ index, key, style }) => {
        const post = data[index]
        return (
            <MyWalletItem key={key} style={style} item={post} active={activeItem === index} darkname={darkname} setFastCoin={setFastCoin} onClick={() => handleItemClick(index)} />
        );
    };

    return (
        <List
            width={980} // 전체 크기
            height={700} // 전체 높이
            rowCount={data.length} // 항목 개수
            rowHeight={50} // 항목 높이
            rowRenderer={rowRenderer} // 항목을 렌더링할 때 쓰는 함수
            list={data} // 배열
            style={{ outline: 'none' }} // List에 기본 적용되는 outline 스타일 제거
        />
    );
}

function MyWalletItem({ item, active, onClick, darkname, setFastCoin, key, style }) {
    let check_KYC = useSelector((state) => { return state.check_KYC.value });
    const { t } = useTranslation();
    let imgcheck = true
    if (IMG && !IMG.includes(item.symbol) && item.symbol != "USDT") {
        imgcheck = false
    }

    return (
        <div className={`wallet-right-bottom-3 ${darkname}`} key={key} style={style}>
            <div className='wallet-right-bottom-3-1-0' style={{ textAlign: "left" }}>
                <img src={imgcheck ? `/img/coin/${item.symbol}.png` : `/img/coin/origin.png`}></img>
                <span style={{ marginLeft: "10px" }}>{item.symbol}</span>
            </div>
            <div className='wallet-right-bottom-3-1-1 wallet-display-none-800'>{changeNum(RoundDown(Number(item.availBal) + Number(item.frozenBal), 6))}</div>
            <div className='wallet-right-bottom-3-1-1'>{changeNum(RoundDown(Number(item.availBal), 6))}</div>
            <div className='wallet-right-bottom-3-1-1'>{changeNum(RoundDown(Number(item.frozenBal), 6))}</div>
            <div className='wallet-right-bottom-3-1-1 wallet-display-none-800'>{changeNum(RoundDown((Number(item.availBal) + Number(item.frozenBal)) * Number(item.lastPrice), 6))}</div>
            <div className='wallet-right-bottom-3-1-2'>
                {
                    active
                        ?
                        check_KYC && check_KYC.bnKyc === 1
                            ?
                            <div className='wallet-right-bottom-3-1-22' data-aos="zoom-in" data-aos-duration="500" data-aos-once="true">
                                <Link to={`/wallet`} state={{ num: 1 }} onClick={() => setFastCoin(item.symbol)}>
                                    <div className='wallet-right-bottom-3-2'>{t('header_31')}</div>
                                </Link>
                                <Link to={`/wallet`} state={{ num: 2 }} onClick={() => setFastCoin(item.symbol)}>
                                    <div className='wallet-right-bottom-3-2'>{t('header_33')}</div>
                                </Link>
                                <Link to={`/wallet`} state={{ num: 3 }} onClick={() => setFastCoin(item.symbol)}>
                                    <div className='wallet-right-bottom-3-2'>{t('header_35')}</div>
                                </Link>
                            </div>
                            :
                            <div className='wallet-right-bottom-3-1-22' data-aos="zoom-in" data-aos-duration="500" data-aos-once="true">
                                <Link to={`/dashboard`} state={{ num: 1 }}>
                                    <div className='wallet-right-bottom-3-2' style={{ padding: "0 10px" }}>KYC verification</div>
                                </Link>
                            </div>
                        :
                        <FontAwesomeIcon icon={faEllipsis} onClick={onClick} style={{ fontSize: "18px", cursor: "pointer" }} />
                }
            </div>
        </div>
    )
}

export default Binance_Overview;
import '../css/futures.css'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, checkLogin, check_Error } from '../../store';
import { useTranslation } from "react-i18next";
import ErrorBoundary from '../ErrorBoundary'
import isEqual from 'lodash/isEqual';
import { findSmallest, changeNum } from "../common/function"
import '@fortawesome/fontawesome-free/css/all.css';

import Fsection1 from './futures/Fsection1';
import Fsection4 from './futures/Fsection4';
import Fsection8 from './futures/Fsection8';
import Fsection7 from './futures/Fsection7';
import Fsection6 from './futures/Fsection6';

let data = []
let preArr = []
let arr = []

function Futures() {
    const { t } = useTranslation();

    let OKXF = useSelector((state) => { return state.OKX_coinF });
    let dark = useSelector((state) => { return state.Darkmode.value });
    let OKX_position = useSelector((state) => { return state.OKX_position });
    let OKX_liquidation = useSelector((state) => { return state.OKX_liquidation });
    let Alert = useSelector((state) => { return state.Alert });
    let OKX_wallet_usdt = useSelector((state) => { return state.OKX_wallet_usdt });
    const darkname = dark ? 'dark' : "";

    let { symbol } = useParams();
    let dispatch = useDispatch();

    const [windowtype, setWindowtype] = useState(true);
    const [windowtypeM, setWindowtypeM] = useState(true);
    const [tab, setTab] = useState(localStorage.getItem('f_coinList') ? localStorage.getItem('f_coinList') == "true" ? true : false : true)
    const [tab1, setTab1] = useState(localStorage.getItem('f_orderbook') ? localStorage.getItem('f_orderbook') == "true" ? true : false : true)
    const [tab3, setTab3] = useState(true)
    const [tab4, setTab4] = useState(1)
    const [tab5, setTab5] = useState(0)
    const [lastBuy, setLastBuy] = useState(0);
    const [lastSell, setLastSell] = useState(0);
    const [checkordertab, setCheckordertab] = useState(true)
    const [speedorder, setSpeedorder] = useState([0, 0])
    const [instrumentsData, setInstrumentData] = useState([])
    const [fwallet, setFwallet] = useState([])
    const [current, setCurrent] = useState('0');
    const [positionAmount, setPositionAmount] = useState([false, ""])
    const [orderReload, setOrderReload] = useState(false)

    const [max, setMax] = useState(4)
    const [maxCnt, setMaxCnt] = useState(true)
    const [positionData, setPositionData] = useState([]);

    const [firstmargin, setFirstmargin] = useState([])
    const [marginbtn, setMarginbtn] = useState(0)
    const [marginbtn1, setMarginbtn1] = useState(1)
    const [marginList, setMarginList] = useState([])
    const [maxPosition, setMaxPosition] = useState(0)

    const [isPlaying, setIsPlaying] = useState(false);
    const [isPlaying1, setIsPlaying1] = useState(false);
    const [checkisPlaying, setCheckisPlaying] = useState(false)

    const [tradeVolume, setTradeVolume] = useState("10000")
    const [currentPrice, setCurrentPrice] = useState(0.00000000001)
    const [checkCurrentPosition, setCheckCurrentPosition] = useState(false)

    const [btnbtn, setBtnbtn] = useState(0);
    const [markPrice, setMarkPrice] = useState([])
    const [fundingFee, setFundingFee] = useState(0)
    const [positionDirect, setPositionDirect] = useState(0)
    const [risk_balance, setRisk_balance] = useState(0)
    const [risk_position, setRisk_position] = useState(0)
    const [margin_type, setMargin_type] = useState("")

    const [tmm, setTMM] = useState(0)
    const [upnl, setUPNL] = useState(0)
    const [pBoth, setPBoth] = useState([0, 0, 1])
    const [minSz, setMinSz] = useState(2)
    const [minCoin, setMinCoin] = useState("Loding")
    const [qtypoint, setQtypoint] = useState(2)

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            if (Date.now() > obj.expire) {
                localStorage.removeItem('token');
                dispatch(checkLogin(false));
            }
            else {
                dispatch(checkLogin(true));
            }
        }
        else {
            dispatch(checkLogin(false));
        }
    }, []);

    let USDTSymbolF = OKXF ? OKXF : [];
    dispatch(checkSelectType(0))

    USDTSymbolF.map(function (a, i) {
        if (a.symbol === symbol) {
            if (maxCnt) {
                setMax(a.point)
                setMaxCnt(false)
            }
            data = {
                symbol: a.symbol, ccy: a.ccy, priceChangePercent: a.priceChangePercent, lastPrice: a.lastPrice, volume: a.volume
                , highPrice: a.highPrice, lowPrice: a.lowPrice, priceChange: a.priceChange, point: a.point
            }
        }
    })

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1550) setTab(false)
            else setTab(true)
            if (window.innerWidth >= 1300) setWindowtype(true)
            else {
                setWindowtype(false)
                change_OrderbookTab(true)
            }
            if (window.innerWidth >= 1000) {
                setWindowtypeM(true)
            }
            else setWindowtypeM(false)
        };

        window.addEventListener('resize', handleResize);
        handleResize()

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (instrumentsData) {
            let minsz = (Number(instrumentsData.minSz) * Number(instrumentsData.ctVal)).toFixed(5)
            let point = findSmallest(minsz)
            let min = findSmallest(Number(instrumentsData.minSz))

            setMinSz(min)
            setMinCoin(Number(minsz))
            setQtypoint(point)
        }
    }, [instrumentsData, symbol])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            OKX_First_PositionData(token)
        }
    }, [])

    useEffect(() => {
        let objString = localStorage.getItem("TradeVolume")
        if (objString) {
            setTradeVolume(objString)
        }
    }, [])

    const playAudio = () => {
        setIsPlaying(true);
        setCheckisPlaying(true)

        setTimeout(() => {
            setIsPlaying(false);
            setCheckisPlaying(false)
        }, 1000)
    };

    const playAudio1 = () => {
        setIsPlaying1(true);
        setCheckisPlaying(true)

        setTimeout(() => {
            setIsPlaying1(false);
            setCheckisPlaying(false)
        }, 1000)
    };

    useEffect(() => {
        if (!currentPrice || Number(currentPrice) === 0.00000000001) {
            document.title = `${data.lastPrice ? Number(data.lastPrice).toFixed(max) : "0.0000"} | ${data.ccy ? data.ccy + "USDT" : ""} | OKX`;
            setCurrentPrice(data.lastPrice ? data.lastPrice : "0.00000000001")
        }
        else {
            document.title = `${Number(currentPrice).toFixed(max)} | ${data.ccy ? data.ccy + "USDT" : ""} | OKX`;
        }
    }, [currentPrice, data]);

    useEffect(() => {
        setMaxCnt(true)
        FuturesInstruments()
        setCurrentPrice(0.00000000001)
    }, [symbol])

    useEffect(() => {
        let nextArr = []
        let cnt = false
        let t_tmm = 0
        let t_upnl = 0
        let t_price = 0
        let t_quantity = 0
        let t_leverage = 1

        const objString = localStorage.getItem('token');
        if (objString && OKX_position) {
            arr = OKX_position
            // arr.sort((a, b) => {
            //     if (a.instId > b.instId) return 1;
            //     if (a.instId < b.instId) return -1;
            // })

            arr.map(function (a, i) {
                if (symbol == a.instId) {
                    t_price = Number(a.avgPx)
                    t_quantity = Number((Number(a.pos) * Number(instrumentsData.ctVal)).toFixed(5))
                    t_leverage = Number(a.lever)
                    cnt = true
                }
                else {
                    t_tmm += Number(a.notionalUsd)
                    t_upnl += Number(a.uplLastPx)
                }
                nextArr.push({ symbol: a.instId, size: a.pos })
            })

            setCheckCurrentPosition(cnt)

            let ArraysEqual = isEqual(preArr, nextArr);

            if (!ArraysEqual) {
                setOrderReload(e => !e)
                if (Alert[1] == "ON") {
                    playAudio()
                }
            }

            preArr = nextArr
            setTMM(t_tmm)
            setUPNL(t_upnl)
            setPBoth([t_price, t_quantity, t_leverage])
            setPositionData(arr)
        }
    }, [OKX_position, symbol])

    useEffect(() => {
        let cnt = 0
        let marginType = ""
        let risk_cross = 0
        let risk_isolated = 0
        let risk_cross_WB = 0
        let risk_isolated_WB = 0

        if (positionData) {
            for (let i = 0; i < positionData.length; i++) {
                let tmp = positionData[i]
                if (symbol == tmp.instId) {
                    cnt = 1
                    setFundingFee(Math.abs(tmp.notionalUsd))
                    setPositionDirect(Number(tmp.pos))

                    marginType = tmp.mgnMode
                    risk_isolated = Math.abs(Number(tmp.notionalUsd))
                    risk_isolated_WB = Math.abs(Number(tmp.notionalUsd) / Number(tmp.lever))
                }
                if (tmp.mgnMode == "cross") {
                    risk_cross += Math.abs(Number(tmp.notionalUsd))
                }
                else {
                    risk_cross_WB += Math.abs(Number(tmp.notionalUsd) / Number(tmp.lever))
                }
            }
            if (cnt === 0) {
                setFundingFee(0)
                setPositionDirect(0)
                setRisk_balance(0)
                setRisk_position(0)
                setMargin_type("0")
            }
            else {
                setMargin_type(marginType)
                if (marginType == "isolated") {
                    setRisk_position(risk_isolated)
                    setRisk_balance(risk_isolated_WB)
                }
                else {
                    setRisk_position(risk_cross)
                    setRisk_balance(risk_cross_WB)
                }
            }
        }
    }, [positionData])

    let cnt_liq = 0

    useEffect(() => {
        if (cnt_liq > 0) {
            if (Alert[1] == "ON") {
                playAudio1()
            }
        }
        cnt_liq = 1
    }, [OKX_liquidation])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            FuturesLever(token)
            OKXMargin()
        }
    }, [marginbtn, symbol])

    const OKXMargin = async () => {
        let arr = symbol.split("-SWAP")
        await axios.get(`https://www.okx.com/api/v5/public/position-tiers`, {
            params: {
                instType: "SWAP",
                instId: symbol,
                tdMode: "cross",
                uly: arr && arr[0] ? arr[0] : "",
            },
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                setMarginList(tmp)
            }
        }).catch((error) => {
            console.log(error)
            // dispatch(check_Error({ "bol": true, "no": 100, "msg": error }))
        })
    }

    const FuturesLever = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/trade/okx/swap/leverage-info`, {
            params: {
                instId: symbol,
                mgnMode: marginbtn === 0 ? "cross" : "isolated"
            },
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp && tmp[0]) {
                setMarginbtn1(Number(tmp[0].lever))
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 101, "msg": error }))
        })
    }

    const FuturesInstruments = async () => {
        await axios.get(process.env.REACT_APP_API + "/api/v1/market/okx/instruments/swap", {
            params: {
                instId: symbol
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp && tmp[0]) {
                setInstrumentData(tmp[0])
                setFirstmargin(Number(tmp[0].lever))
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 102, "msg": error }))
        })
    }

    const OKX_First_PositionData = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1/trade/okx/swap/position`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data.data
            if (tmp) {
                let cnt = false
                for (let i = 0; i < tmp.length; i++) {
                    if (symbol == tmp[i].instId) {
                        cnt = true
                    }
                }
                setCheckCurrentPosition(cnt)
                setPositionData(tmp)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 103, "msg": error }))
        })
    }

    useEffect(() => {
        if (OKX_wallet_usdt && OKX_wallet_usdt[0] && OKX_wallet_usdt[0].availBal) {
            setCurrent(OKX_wallet_usdt[0].availBal)
            setFwallet(OKX_wallet_usdt[0])
        }
    }, [OKX_wallet_usdt])

    const changeTab = (e) => {
        setTab(e)
        localStorage.setItem('f_coinList', e)
    }

    const change_OrderbookTab = (e) => {
        setTab1(e)
        localStorage.setItem('f_orderbook', e)
    }

    return (
        <div className={`futures ${darkname}`}>
            {
                isPlaying
                    ?
                    <audio autoPlay={isPlaying}>
                        <source src="/audio/Sound.2.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                isPlaying1
                    ?
                    <audio autoPlay={isPlaying1}>
                        <source src="/audio/Sound.5.mp3" type="audio/mp3" />
                    </audio>
                    :
                    ""
            }
            {
                windowtype
                    ?
                    <div className='futures-box'>
                        <ErrorBoundary>
                            <Fsection1 tab={tab} changeTab={changeTab} USDTSymbolF={USDTSymbolF} symbol={symbol} />
                        </ErrorBoundary>
                        <div className={tab ? 'futures-section2' : 'futures-section2-check'}>
                            <div className='futures-section3'>
                                <div className='futures-section5'>
                                    <ErrorBoundary>
                                        <Fsection6 tab={tab} tab1={tab1} changeTab={changeTab} change_OrderbookTab={change_OrderbookTab} data={data} symbol={symbol} instrumentsData={instrumentsData} max={max} positionData={positionData} currentPrice={currentPrice} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} markPrice={markPrice} setMarkPrice={setMarkPrice} maxPosition={maxPosition} fundingFee={fundingFee} positionDirect={positionDirect} orderReload={orderReload} />
                                    </ErrorBoundary>
                                    <ErrorBoundary>
                                        <Fsection7 tab1={tab1} tab3={tab3} tab4={tab4} setTab4={setTab4} setTab3={setTab3} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} setSpeedorder={setSpeedorder} symbol={symbol} instrumentsData={instrumentsData} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} checkisPlaying={checkisPlaying} btnbtn={btnbtn} markPrice={markPrice} qtypoint={qtypoint} />
                                    </ErrorBoundary>
                                </div>
                                <ErrorBoundary>
                                    <Fsection8 tab5={tab5} setTab5={setTab5} symbol={symbol} windowtypeM={windowtypeM} USDTSymbolF={USDTSymbolF} setPositionAmount={setPositionAmount} positionData={positionData} setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} setCheckisPlaying={setCheckisPlaying} checkCurrentPosition={checkCurrentPosition} currentPrice={currentPrice} setOrderReload={setOrderReload} />
                                </ErrorBoundary>
                            </div>
                            <ErrorBoundary>
                                <Fsection4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} speedorder={speedorder} instrumentsData={instrumentsData} positionAmount={positionAmount} max={max} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} current={current} fwallet={fwallet} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} btnbtn={btnbtn} setBtnbtn={setBtnbtn} risk_balance={risk_balance} risk_position={risk_position} margin_type={margin_type} marginList={marginList} setMaxPosition={setMaxPosition} tmm={tmm} upnl={upnl} pBoth={pBoth} minCoin={minCoin} qtypoint={qtypoint} minSz={minSz} />
                            </ErrorBoundary>
                        </div>
                    </div>
                    :
                    <div className='futures-box'>
                        <ErrorBoundary>
                            <Fsection1 tab={tab} changeTab={changeTab} USDTSymbolF={USDTSymbolF} symbol={symbol} />
                        </ErrorBoundary>
                        <div className='futures-section9'>
                            <ErrorBoundary>
                                <Fsection6 tab={tab} tab1={tab1} changeTab={changeTab} change_OrderbookTab={change_OrderbookTab} data={data} symbol={symbol} instrumentsData={instrumentsData} max={max} positionData={positionData} currentPrice={currentPrice} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} markPrice={markPrice} setMarkPrice={setMarkPrice} maxPosition={maxPosition} fundingFee={fundingFee} positionDirect={positionDirect} orderReload={orderReload} />
                            </ErrorBoundary>
                            <ErrorBoundary>
                                <Fsection8 tab5={tab5} setTab5={setTab5} symbol={symbol} windowtypeM={windowtypeM} USDTSymbolF={USDTSymbolF} setPositionAmount={setPositionAmount} positionData={positionData} setTradeVolume={setTradeVolume} tradeVolume={tradeVolume} setCheckisPlaying={setCheckisPlaying} checkCurrentPosition={checkCurrentPosition} currentPrice={currentPrice} setOrderReload={setOrderReload} />
                            </ErrorBoundary>
                            <div className='futures-section10'>
                                <ErrorBoundary>
                                    <Fsection7 tab1={tab1} tab3={tab3} tab4={tab4} setTab4={setTab4} setTab3={setTab3} setLastBuy={setLastBuy} setLastSell={setLastSell} data={data} setCheckordertab={setCheckordertab} setSpeedorder={setSpeedorder} symbol={symbol} instrumentsData={instrumentsData} setMax={setMax} max={max} tradeVolume={tradeVolume} setCurrentPrice={setCurrentPrice} currentPrice={currentPrice} checkisPlaying={checkisPlaying} btnbtn={btnbtn} markPrice={markPrice} qtypoint={qtypoint} />
                                </ErrorBoundary>
                                <ErrorBoundary>
                                    <Fsection4 data={data} lastBuy={lastBuy} lastSell={lastSell} symbol={symbol} setCheckordertab={setCheckordertab} checkordertab={checkordertab} speedorder={speedorder} instrumentsData={instrumentsData} positionAmount={positionAmount} max={max} currentPrice={currentPrice} checkCurrentPosition={checkCurrentPosition} current={current} fwallet={fwallet} firstmargin={firstmargin} marginbtn={marginbtn} setMarginbtn={setMarginbtn} marginbtn1={marginbtn1} setMarginbtn1={setMarginbtn1} btnbtn={btnbtn} setBtnbtn={setBtnbtn} risk_balance={risk_balance} risk_position={risk_position} margin_type={margin_type} marginList={marginList} setMaxPosition={setMaxPosition} tmm={tmm} upnl={upnl} pBoth={pBoth} minCoin={minCoin} qtypoint={qtypoint} minSz={minSz} />
                                </ErrorBoundary>
                            </div>

                            <div style={{ padding: "5px 5px 0" }}>
                                <div className='futures-section4-top-9 display-none-700-ok'>
                                    <div className='futures-section4-top-9-1 red'>{t('futures_152')}</div>
                                    <div className='futures-section4-top-9-2 green'>{fwallet.eq ? changeNum((risk_balance * 100 / Number(fwallet.eq)).toFixed(2)) : "0.00"}%</div>
                                    <div className='futures-section4-top-9-2'>%</div>
                                </div>
                                <div className='futures-section4-top-10 display-none-700-block'>
                                    <div className='futures-section4-top-10-1'>
                                        <div className='futures-section4-top-10-1-1'>{t('futures_0')}</div>
                                        <div className='futures-section4-top-10-1-2'>{fwallet.eq ? changeNum(Number(fwallet.eq).toFixed(3)) : "0.000"}</div>
                                        <div className='futures-section4-top-10-1-2'>USDT</div>
                                    </div>
                                    <div className='futures-section4-top-10-1'>
                                        <div className='futures-section4-top-10-1-1'>{t('futures_1')}</div>
                                        <div className='futures-section4-top-10-1-2'>{fwallet.isoEq ? changeNum(Number(fwallet.isoEq).toFixed(3)) : "0.000"}</div>
                                        <div className='futures-section4-top-10-1-2'>USDT</div>
                                    </div>
                                    <div className='futures-section4-top-10-1'>
                                        <div className='futures-section4-top-10-1-1'>{t('futures_2')}</div>
                                        <div className='futures-section4-top-10-1-2'>{fwallet.availBal ? changeNum(Number(fwallet.availBal).toFixed(3)) : "0.000"}</div>
                                        <div className='futures-section4-top-10-1-2'>USDT</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default Futures;
import '../../css/wallet.css'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faCircleArrowRight, faXmark, faMagnifyingGlass, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, changeWalletPage, check_Error } from '../../../store';
import IMG from "../../img"
import { SyncLoader } from "react-spinners";
import Message from '../../message/message';
import Wallet_Message from '../../message/wallet_message';
import { useTranslation } from "react-i18next";
import { MakeNumber, changeNum, RoundDown } from "../../common/function"

function OKX_Exchange({ darkname, walletData, currentUSDT, setReload, fastCoin }) {
    const { t } = useTranslation();
    const timestamp1 = '' + Date.now();

    let OKXS = useSelector((state) => { return state.OKX_coinS });
    

    let dispatch = useDispatch();

    const [isToggled1, setIsToggled1] = useState(false);
    const [search, setSearch] = useState(fastCoin)
    const [selectSearch, setSelectSearch] = useState(fastCoin)
    const [modalbtn, setModalbtn] = useState(false)
    const [amount, setAmount] = useState("")
    const [balanceAmount, setBalanceAmount] = useState(0)
    const [direction, setDirection] = useState(false)
    const [markprice, setMarkprice] = useState(0)
    const [calAmount, setCalAmount] = useState("")
    const [sym, setSym] = useState("")
    const [tab, setTab] = useState(false)

    const [spinner, setSpinner] = useState(false)
    const [message, setMessage] = useState([])

    const toggleButton1 = () => {
        // setIsToggled1(!isToggled1);
    };

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    const onChangeAmount = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, "")
        setAmount(onlyNumber)
    }

    let BS_filterSymbol = []
    if (OKXS) {
        BS_filterSymbol = OKXS.filter((p) => {
            return (p.ccy.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        })
    }

    BS_filterSymbol.sort((a, b) => Number(b.volume) * Number(b.lastPrice) - Number(a.volume) * Number(a.lastPrice));

    useEffect(() => {
        if (walletData) {
            for (let i = 0; i < walletData.length; i++) {
                if (walletData[i].symbol === selectSearch) {
                    setBalanceAmount(Number(walletData[i].availBal))
                    setMarkprice(Number(walletData[i].lastPrice))
                    setSym(`${walletData[i].symbol}-USDT`)
                }
            }
        }
    }, [selectSearch])

    useEffect(() => {
        setCalAmount(direction ? (Number(amount) / Number(markprice)) : RoundDown(Number(markprice) * Number(amount), 8))
    }, [amount, selectSearch])

    const createPostData = (side, sz) => {
        return {
            instId: sym,
            tdMode: "cash",
            clOrdId: timestamp1,
            side: side,
            ordType: 'market',
            sz: sz,
            tag: "ff5b6baef008SCDE",
            tgtCcy: "base_ccy",
        };
    };

    const onSubmit = async (postData, url) => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(url, postData, { headers: { Authorization: `Bearer ${token}` } }
            ).then((response) => {
                if (Number(response?.data?.code) === 0) {
                    setTimeout(() => {
                        setSpinner(false);
                        setReload(e => !e)
                        setSearch("")
                        setAmount("")
                        setSelectSearch("")
                        setBalanceAmount(0)
                        setMessage({ type: 1, code: Number(response?.data?.code), msg: `${t('message_16')}` })
                    }, 2000);
                }
                else {
                    setTimeout(() => {
                        setSpinner(false);
                        setMessage({ type: 1, code: Number(response?.data?.code), msg: response.data.data[0].sMsg })
                    }, 1000);
                }
                setW_confirm(false)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 139, "msg": error }))
            })
        }
    }

    const onSubmitBuy = () => {
        setSpinner(true)

        let url = process.env.REACT_APP_API + '/api/v1/trade/okx/swap/order'
        let postData = direction ? createPostData("buy", calAmount) : createPostData("sell", amount);
        onSubmit(postData, url);
    };

    const [w_message, setW_message] = useState([])
    const [buyModal, setBuyModal] = useState(false);
    const [w_confirm, setW_confirm] = useState(false)

    useEffect(() => {
        if (w_confirm) {
            setBuyModal(false)
            onSubmitBuy()
        }
    }, [w_confirm])

    const Check_Modal = () => {
        let mes = []
        // mes.push(`Exchange: OKX`)
        // mes.push(`From: ${direction ? "USDT" : selectSearch}`)
        // mes.push(`To: ${direction ? selectSearch : "USDT"}`)
        // mes.push(`Amount: ${amount}`)

        setW_message({ code: 0, msg: mes })
    }

    return (
        <div className='history-right'>
            <Message message={message} />
            <Wallet_Message message={w_message} buyModal={buyModal} setBuyModal={setBuyModal} setW_confirm={setW_confirm} />
            {
                spinner
                    ?
                    <div className='dashboard-right-bottom1-modal'>
                        <SyncLoader color={"#F5AC1D"} />
                    </div>
                    :
                    ""
            }
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_35')}</div>
                <p className='history-right-top-2'>{t('header_36')}</p>
            </div>
            <div className='wallet-right-form'>
                <div className='wallet-right-form-1'>
                    <div className='wallet-right-form-1-box'>
                        <div className='wallet-right-form-1-box-1'>
                            <p>{t('wallet_10')}</p>
                            <div className={`wallet-right-form-1-box-1-label ${isToggled1 ? 'toggled' : ''} ${darkname}`} onClick={toggleButton1}>
                                <div className="wallet-right-form-1-box-1-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToggled1
                        ?
                        <div className='wallet-right-form-3'>
                            <div className='wallet-right-form-3-1'>{t('wallet_10')}</div>
                            <div className='wallet-right-form-3-2'>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_123')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_124')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_125')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_126')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_127')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_128')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_129')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} onClick={() => setTab(e => !e)}>
                            {t('exchange_1')}
                        </div>
                        {
                            tab
                                ?
                                <>
                                    <div className="header-modal" onClick={() => setTab(false)}></div>
                                    <div className={`wallet-right-form-4-3-box ${darkname}`}>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(1)); dispatch(changeWalletPage(1)); setTab(false) }}>{t('exchange_0')}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(0)); dispatch(changeWalletPage(0)); setTab(false) }}>{t('exchange_1')}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(2)); dispatch(changeWalletPage(2)); setTab(false) }}>{t('exchange_2')}</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_130')}</div>
                    <div className='wallet-right-form-4-2' onClick={() => { setModalbtn(true); setSearch("") }}>
                        <input type='text' className={`wallet-right-form-4-3 ${darkname}`} placeholder={`${t('wallet_54')}`} value={search} onChange={onChangeSearch} style={{ textTransform: "uppercase" }} />
                    </div>
                    {
                        modalbtn
                            ?
                            <div className='wallet-right-bottom1-modal'>
                                <div className='wallet-right-bottom1-modal-1'>
                                    <div className={`wallet-right-bottom1-modal-1-1 ${darkname}`}>
                                        <div className='wallet-right-bottom1-modal-1-1-tit'>{t('wallet_55')}
                                            <FontAwesomeIcon icon={faXmark} onClick={() => { setSearch(""); setModalbtn(false) }} /></div>
                                        <div className={`wallet-right-bottom1-modal-1-1-wrap ${darkname}`}>
                                            <div className={`wallet-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                <input placeholder={`${t('wallet_75')}`} onChange={onChangeSearch} value={search} style={{ textTransform: "uppercase" }} />
                                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                            </div>
                                            <div className='wallet-right-bottom1-modal-1-2'>
                                                {
                                                    BS_filterSymbol.map(function (a, i) {
                                                        let imgcheck = true
                                                        if (IMG && !IMG.includes(a.ccy)) {
                                                            imgcheck = false
                                                        }
                                                        return (
                                                            <div className='wallet-right-bottom1-modal-1-2-1' onClick={() => { setSelectSearch(a.ccy); setSearch(a.ccy); setModalbtn(false) }}>
                                                                <div className='wallet-right-bottom1-modal-1-2-2'>
                                                                    <div className='wallet-right-bottom1-modal-1-2-img'>
                                                                        <img src={imgcheck ? `/img/coin/${a.ccy}.png` : `/img/coin/origin.png`} />
                                                                    </div>
                                                                    <div className='wallet-right-bottom1-modal-1-2-name'>
                                                                        <p>{a.ccy}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                    {/* <br />
                    <span style={{ fontSize: "14px" }}>Easy Quick Choice</span>
                    <div className='wallet-right-form-4-4'>
                        <div className='wallet-right-form-4-5'>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("BTC"); setSelectSearch("BTC") }}>BTC</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("ETH"); setSelectSearch("ETH") }}>ETH</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("ETC"); setSelectSearch("ETC") }}>ETC</div>
                        </div>
                        <div className='wallet-right-form-4-5'>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("XRP"); setSelectSearch("XRP") }}>XRP</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("TRX"); setSelectSearch("TRX") }}>TRX</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("EOS"); setSelectSearch("EOS") }}>EOS</div>
                        </div>
                    </div> */}
                </div>
                <div className={`wallet-right-form-10 ${darkname}`}>
                    <div className='wallet-right-form-10-1'>
                        <p className='wallet-right-form-10-2'>{t('wallet_131')}<br /> <span>{changeNum(RoundDown(balanceAmount, (MakeNumber(balanceAmount))))}</span></p>
                        <p className='wallet-right-form-10-3'>{t('wallet_131')}<br /> <span>{currentUSDT && currentUSDT[0] && currentUSDT[0].availBal ? changeNum(RoundDown(currentUSDT[0].availBal, 6)) : "0.00000"}</span></p>
                    </div>
                    <div className='wallet-right-form-10-1' style={{ marginTop: "3px" }}>
                        <p className={`wallet-right-form-10-4 ${darkname}`}>{selectSearch ? selectSearch : '-'}</p>
                        <div className='wallet-right-form-10-5' onClick={() => { setDirection(e => !e); setAmount("") }}>
                            {
                                direction
                                    ?
                                    <FontAwesomeIcon icon={faCircleArrowLeft} />
                                    :
                                    <FontAwesomeIcon icon={faCircleArrowRight} />
                            }
                        </div>
                        <p className={`wallet-right-form-10-4 ${darkname}`}>USDT</p>
                    </div>
                    <div className='wallet-right-form-10-1' style={{ marginTop: "3px" }}>
                        <p className='wallet-right-form-10-3'>{t('wallet_132')}</p>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`} style={{ display: "flex", alignItems: "center" }}>
                    <div className='wallet-right-form-4-1'>{t('wallet_133')}</div>
                    <div className='wallet-right-form-4-1-1'>{changeNum(markprice)} USDT</div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_134')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`}>
                            <input className='wallet-right-form-4-3-input' value={amount} onChange={onChangeAmount} placeholder='0' />
                            <span className='wallet-right-form-4-3-span'>{direction ? "USDT" : selectSearch}</span>
                            <div className='wallet-right-form-4-3-btn' onClick={() => setAmount(direction ? currentUSDT && currentUSDT[0] && currentUSDT[0].availBal ? RoundDown(currentUSDT[0].availBal, 6) : "0" : balanceAmount ? RoundDown(balanceAmount, (MakeNumber(balanceAmount))) : 0)}>{t('wallet_108')}</div>
                        </div>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_135')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`}>{calAmount}</div>
                    </div>
                </div>
                <div className='wallet-right-form-9'>
                    <div className={`wallet-right-form-9-1 ${darkname}`} onClick={() => Check_Modal()}>{t('header_35')}</div>
                </div>
            </div>
        </div >
    )
}

export default OKX_Exchange;
import '../../css/wallet.css'
import axios from 'axios';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faCircleArrowRight, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, changeWalletPage, check_Error } from '../../../store';
import Message from '../../message/message';
import Wallet_Message from '../../message/wallet_message';
import { SyncLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { changeNum, RoundDown } from "../../common/function"

function Bybit_Transfer({ darkname, spotBalance, BcurrentUSDT, setReload }) {
    
    let By_apiKey = useSelector((state) => { return state.By_apiKey })
    const CryptoJS = require('crypto-js');
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [tab, setTab] = useState(false)
    const [isToggled1, setIsToggled1] = useState(false);
    const [direction, setDirection] = useState(false)
    const [amount, setAmount] = useState("")

    const [spinner, setSpinner] = useState(false)
    const [message, setMessage] = useState([])

    const [fund, setFund] = useState([])
    const [unified, setUnified] = useState([])
    const [relo, setRelo] = useState(false)

    const toggleButton1 = () => {
        // setIsToggled1(!isToggled1);
    };

    const onChangeAmount = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, "")
        setAmount(onlyNumber)
    }

    useEffect(() => {
        if (By_apiKey?.S_api?.length > 0) {
            Fund()
            Unified()
        }
    }, [relo, By_apiKey])

    const Fund = async () => {
        const by_timestamp = localStorage.getItem('bybit_timestamp');
        if (by_timestamp) {
            const paramStr = `${by_timestamp}${By_apiKey.S_api}${10000}accountType=FUND&coin=USDT`
            await axios.get(`https://api.bybit.com/v5/asset/transfer/query-account-coins-balance?accountType=FUND&coin=USDT`, {
                headers: {
                    "X-BAPI-SIGN": CryptoJS.HmacSHA256(paramStr, By_apiKey.S_sec).toString(CryptoJS.enc.Hex),
                    "X-BAPI-API-KEY": By_apiKey.S_api,
                    "X-BAPI-TIMESTAMP": by_timestamp,
                    "X-BAPI-RECV-WINDOW": 10000
                }
            }).then((response) => {
                let tmp = response.data
                if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                    Fund()
                }
                else if (tmp.retCode == 0) {
                    setFund(tmp.result.balance[0])
                }
            }).catch((error) => {
                console.log(error)
            })
        }
        else {
            setTimeout(() => {
                Fund()
            }, 1000)
        }
    }

    const Unified = async () => {
        const by_timestamp = localStorage.getItem('bybit_timestamp');
        if (by_timestamp) {
            const paramStr = `${by_timestamp}${By_apiKey.S_api}${10000}accountType=UNIFIED&coin=USDT`
            await axios.get(`https://api.bybit.com/v5/account/wallet-balance?accountType=UNIFIED&coin=USDT`, {
                headers: {
                    "X-BAPI-SIGN": CryptoJS.HmacSHA256(paramStr, By_apiKey.S_sec).toString(CryptoJS.enc.Hex),
                    "X-BAPI-API-KEY": By_apiKey.S_api,
                    "X-BAPI-TIMESTAMP": by_timestamp,
                    "X-BAPI-RECV-WINDOW": 10000
                }
            }).then((response) => {
                let tmp = response.data
                if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                    Unified()
                }
                else if (tmp.retCode == 0) {
                    setUnified(tmp.result.list[0].coin[0])
                }
            }).catch((error) => {
                console.log(error)
            })
        }
        else {
            setTimeout(() => {
                Unified()
            }, 1000)
        }
    }

    const onTransfer = async () => {
        const objString = localStorage.getItem('token');
        if (objString) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            setSpinner(true)

            await axios.post(process.env.REACT_APP_API + "/api/v1" + `/wallet/bybit/transfer`, {
                coin: "USDT",
                amount: amount,
                fromAccountType: direction ? "UNIFIED" : "FUND",
                toAccountType: direction ? "FUND" : "UNIFIED"
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                let tmp = response.data
                if (tmp?.code == 1000) {
                    setTimeout(() => {
                        setSpinner(false);
                        setRelo(e => !e)
                        // setReload(e => !e)
                        setAmount("")
                        setMessage({ type: 0, code: Number(tmp?.code), msg: `${t('message_17')}` })
                    }, 2000);
                }
                else {
                    setTimeout(() => {
                        setSpinner(false);
                        setMessage({ type: 0, code: Number(tmp?.code), msg: tmp?.msg })
                    }, 1000);
                }
                setW_confirm(false)
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 93, "msg": error }))
            })
        }
    }

    const [w_message, setW_message] = useState([])
    const [buyModal, setBuyModal] = useState(false);
    const [w_confirm, setW_confirm] = useState(false)

    useEffect(() => {
        if (w_confirm) {
            setBuyModal(false)
            setW_confirm(false)
            onTransfer()
        }
    }, [w_confirm])

    const Check_Modal = () => {
        let mes = []
        // mes.push(`Exchange: Binance`)
        // mes.push(`From: ${direction ? t('wallet_156') : t('wallet_155')}`)
        // mes.push(`To: ${direction ? t('wallet_155') : t('wallet_156')}`)
        // mes.push(`Amount: ${amount}`)

        setW_message({ code: 0, msg: mes })
    }

    return (
        <div className='history-right'>
            <Message message={message} />
            <Wallet_Message message={w_message} buyModal={buyModal} setBuyModal={setBuyModal} setW_confirm={setW_confirm} />
            {
                spinner
                    ?
                    <div className='dashboard-right-bottom1-modal'>
                        <SyncLoader color={"#F5AC1D"} />
                    </div>
                    :
                    ""
            }
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_37')}</div>
                <p className='history-right-top-2'>{t('header_38')}</p>
            </div>
            <div className='wallet-right-form'>
                <div className='wallet-right-form-1'>
                    <div className='wallet-right-form-1-box'>
                        <div className='wallet-right-form-1-box-1'>
                            <p>{t('wallet_10')}</p>
                            <div className={`wallet-right-form-1-box-1-label ${isToggled1 ? 'toggled' : ''} ${darkname}`} onClick={toggleButton1}>
                                <div className="wallet-right-form-1-box-1-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToggled1
                        ?
                        <div className='wallet-right-form-3'>
                            <div className='wallet-right-form-3-1'>{t('wallet_10')}</div>
                            <div className='wallet-right-form-3-2'>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_144')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_145')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_146')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_147')}<br />
                                        {t('wallet_148')}<br />
                                        {t('wallet_149')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_150')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_151')}<br />
                                        {t('wallet_152')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_153')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} onClick={() => setTab(e => !e)}>
                            {t('exchange_2')}
                        </div>
                        {
                            tab
                                ?
                                <>
                                    <div className="header-modal" onClick={() => setTab(false)}></div>
                                    <div className={`wallet-right-form-4-3-box ${darkname}`}>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(1)); dispatch(changeWalletPage(1)); setTab(false) }}>{t('exchange_0')}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(2)); dispatch(changeWalletPage(2)); setTab(false) }}>{t('exchange_2')}</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_154')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} style={{ cursor: "auto" }} >USDT</div>
                    </div>
                </div>
                <div className={`wallet-right-form-10 ${darkname}`}>
                    <div className='wallet-right-form-10-1'>
                        <p className='wallet-right-form-10-2'>{t('wallet_131')}<br /> <span>{fund?.transferBalance ? changeNum(RoundDown(fund.transferBalance, 4)) : "0.00000"}</span></p>
                        <p className='wallet-right-form-10-3'>{t('wallet_131')}<br /> <span>{unified?.availableToWithdraw ? changeNum(RoundDown(unified.availableToWithdraw, 4)) : "0.00000"}</span></p>
                    </div>
                    <div className='wallet-right-form-10-1' style={{ marginTop: "3px" }}>
                        <p className={`wallet-right-form-10-4 ${darkname}`}>Fund Balance</p>
                        <div className='wallet-right-form-10-5' onClick={() => setDirection(e => !e)}>
                            {
                                direction
                                    ?
                                    <FontAwesomeIcon icon={faCircleArrowLeft} />
                                    :
                                    <FontAwesomeIcon icon={faCircleArrowRight} />
                            }
                        </div>
                        <p className={`wallet-right-form-10-4 ${darkname}`}>Unified Balance</p>
                    </div>
                    <div className='wallet-right-form-10-1' style={{ marginTop: "3px" }}>
                        <p className='wallet-right-form-10-3'>{t('wallet_157')}</p>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_158')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`}>
                            <input type='number' className='wallet-right-form-4-3-input' value={amount} onChange={onChangeAmount} placeholder='0' />
                            <span className='wallet-right-form-4-3-span'>USDT</span>
                            <div className='wallet-right-form-4-3-btn' onClick={() => setAmount(
                                direction ? unified?.availableToWithdraw ? RoundDown(unified.availableToWithdraw, 4) : 0 :
                                    fund?.transferBalance ? RoundDown(fund.transferBalance, 4) : 0)}>{t('wallet_108')}</div>
                        </div>
                    </div>
                </div>
                <div className='wallet-right-form-9'>
                    <div className={`wallet-right-form-9-1 ${darkname}`} onClick={() => Check_Modal()}>{t('header_37')}</div>
                </div>
            </div>
        </div >
    )
}

export default Bybit_Transfer;
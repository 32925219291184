import '../../css/wallet.css'
import axios from 'axios';
import Page from '../../Page';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faCircleArrowRight, faEllipsis, faXmark, faMagnifyingGlass, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, checkLogin, addImg, changeWalletPage, check_Error } from '../../../store';
import IMG from "../../img"
import Message from '../../message/message';
import Wallet_Message from '../../message/wallet_message';
import { SyncLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import { findSamllest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "../../common/function"

let fee = 0;

function Bybit_Withdrawal({ darkname, walletData, setReload, fastCoin }) {
    const CryptoJS = require('crypto-js');

    let By_apiKey = useSelector((state) => { return state.By_apiKey })
    
    const { t } = useTranslation();
    let dispatch = useDispatch();

    const [isToggled1, setIsToggled1] = useState(false);
    const [search, setSearch] = useState(fastCoin)
    const [selectSearch, setSelectSearch] = useState(fastCoin)
    const [selectedOption, setSelectedOption] = useState("");
    const [selectedOption_kor, setSelectedOption_kor] = useState("")
    const [modalbtn, setModalbtn] = useState(false)
    const [currency, setCurrency] = useState([])
    const [currency_kor, setCurrency_kor] = useState([])
    const [whistory, setWhistory] = useState([])
    const [address, setAddress] = useState("")
    const [tag, setTag] = useState("")
    const [amount, setAmount] = useState("")
    const [balanceAmount, setBalanceAmount] = useState(0)
    const [tab, setTab] = useState(false)
    const [resetHistory, setResetHistory] = useState(false)
    const [coinTotalUSDT, setCoinTotalUSDT] = useState("0");
    const [checked, setChecked] = useState(false);

    const [first_name, setFirst_name] = useState("")
    const [last_name, setLast_name] = useState("")

    const [spinner, setSpinner] = useState(false)
    const [message, setMessage] = useState([])

    const [code, setCode] = useState(['', '', '', '', '', '']); // 초기값으로 6개의 빈 문자열을 가진 배열 생성

    const handleInputChange = (e, index) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 1) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            // 다음 입력란으로 이동
            if (index < code.length - 1 && value) {
                const nextInput = e.target.parentNode.nextSibling.querySelector('input');
                if (nextInput) {
                    nextInput.focus();
                }
            }

            // 이전 입력란으로 이동
            if (index > 0 && !value) {
                const prevInput = e.target.parentNode.previousSibling.querySelector('input');
                if (prevInput) {
                    prevInput.focus();
                }
            }
        }
    };

    const toggleButton1 = () => {
        // setIsToggled1(!isToggled1);
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSelectChange_KOR = (event) => {
        setSelectedOption_kor(event.target.value);
    };

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    const onChangeAddress = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9]/g, "")
        setAddress(onlyNumber)
    }

    const onChangeTag = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9]/g, "")
        setTag(onlyNumber)
    }


    const onChangeFirstName = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z]/g, "")
        setFirst_name(onlyNumber)
    }


    const onChangeLastName = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z]/g, "")
        setLast_name(onlyNumber)
    }

    const onChangeAmount = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9.]/g, "")
        setAmount(onlyNumber)
    }

    let BS_filterSymbol = []
    if (walletData) {
        BS_filterSymbol = walletData.filter((p) => {
            return (p.symbol.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        })
    }

    useEffect(() => {
        if (walletData) {
            for (let i = 0; i < walletData.length; i++) {
                if (walletData[i].symbol === selectSearch) {
                    setBalanceAmount(Number(walletData[i].availBal))
                }
            }
        }
    }, [selectSearch])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            WithdrawalHistory(token)
        }
    }, [resetHistory])

    const CheckGoogleOTP = async () => {
        let objString = localStorage.getItem('token');
        if (objString != null) {
            let obj = JSON.parse(objString)
            let jwt = obj.value;

            setSpinner(true)

            await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/verify/otp", {
                params: { otp: code.join('') },
                headers: { Authorization: `Bearer ${jwt}` }
            }).then((response) => {
                if (response?.data?.code > 0) {
                    B_WithdrawLink()
                }
                else {
                    alert(response?.data?.msg)
                    setW_confirm(false)
                    setSpinner(false);
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 94, "msg": error }))
            })
        }
    }

    const B_WithdrawLink = async () => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            await axios.post(process.env.REACT_APP_API + `/api/v1` + '/wallet/bybit/withdrawal', {
                coin: selectSearch,
                chain: selectedOption,
                address: address,
                tag: tag,
                amount: amount,
                vaspEntityId: checked ? selectedOption_kor : "",
                beneficiaryName: checked && selectedOption_kor != "" && selectedOption_kor != "others" ? `${first_name} ${last_name}` : ""
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                if (response?.data?.code >= 0) {
                    setTimeout(() => {
                        setSpinner(false);
                        setReload(e => !e)
                        setAddress("")
                        setSearch("")
                        setTag("")
                        setAmount("")
                        setCode(['', '', '', '', '', ''])
                        setMessage({ type: 0, code: Number(response?.data?.code), msg: `${t('message_15')}` })
                    }, 2000);
                }
                else {
                    setTimeout(() => {
                        setSpinner(false);
                        setMessage({ type: 0, code: Number(response?.data?.code), msg: response?.data?.msg })
                    }, 1000);
                }
            }).catch((error) => {
                dispatch(check_Error({ "bol": true, "no": 95, "msg": error }))
            })
        }
    }


    const [by_timestamp, SetBy_timestamp] = useState("")

    useEffect(() => {
        By_TimeStamp()
    }, [selectSearch])

    useEffect(() => {
        if (by_timestamp?.length > 0 && By_apiKey?.S_api?.length > 0) {
            WithdrawalCurrency()
        }
    }, [by_timestamp, By_apiKey])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            WithdrawalCurrency_KOR(token)
        }
    }, [])

    const By_TimeStamp = async () => {
        await axios.get(`https://api.bybit.com/v5/market/time`, {
        }).then((response) => {
            let tmp = response.data
            if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                By_TimeStamp()
            }
            else {
                SetBy_timestamp(tmp.time.toString())
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const WithdrawalCurrency = async () => {
        const paramStr = `${by_timestamp}${By_apiKey.S_api}${10000}coin=${selectSearch}`
        await axios.get(`https://api.bybit.com/v5/asset/coin/query-info?coin=${selectSearch}`, {
            headers: {
                "X-BAPI-SIGN": CryptoJS.HmacSHA256(paramStr, By_apiKey.S_sec).toString(CryptoJS.enc.Hex),
                "X-BAPI-API-KEY": By_apiKey.S_api,
                "X-BAPI-TIMESTAMP": by_timestamp,
                "X-BAPI-RECV-WINDOW": 10000
            }
        }).then((response) => {
            let tmp = response.data
            if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                By_TimeStamp()
            }
            else {
                if (selectSearch.length > 0) {
                    setCurrency(tmp?.result?.rows[0].chains ?? [])
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const WithdrawalCurrency_KOR = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1` + '/wallet/bybit//withdrawal/vasp-list', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            let tmp = response.data
            if (tmp.code === 1000) {
                setCurrency_kor(tmp?.data?.result?.vasp ?? [])
            }
            console.log(tmp)
        }).catch((error) => {
            console.log(error)
        })
    }

    const WithdrawalHistory = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1` + '/wallet/bybit/withdrawal/history', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data
            if (tmp?.code === 1000) {
                setWhistory(tmp.data)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 97, "msg": error }))
        })
    }

    const [w_message, setW_message] = useState([])
    const [buyModal, setBuyModal] = useState(false);
    const [w_confirm, setW_confirm] = useState(false)

    useEffect(() => {
        if (w_confirm) {
            setBuyModal(false)
            CheckGoogleOTP()
        }
    }, [w_confirm])

    const Check_Modal = () => {
        let mes = []
        // mes.push(`Exchange: Binance`)
        // mes.push(`Coin: ${selectSearch}`)
        // mes.push(`Network: ${selectedOption}`)
        // mes.push(`Address: ${address}`)
        // mes.push(`Memo/Tag: ${tag}`)
        // mes.push(`Amount: ${amount}`)

        setW_message({ code: 0, msg: mes })
    }

    const [page, setPage] = useState(1)
    let filter_whistory = whistory.slice((page - 1) * 10, (page) * 10)

    return (
        <div className='history-right'>
            <Message message={message} />
            <Wallet_Message message={w_message} buyModal={buyModal} setBuyModal={setBuyModal} setW_confirm={setW_confirm} />
            {
                spinner
                    ?
                    <div className='dashboard-right-bottom1-modal'>
                        <SyncLoader color={"#F5AC1D"} />
                    </div>
                    :
                    ""
            }
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_33')}</div>
                <p className='history-right-top-2'>{t('header_34')}</p>
            </div>
            <div className='wallet-right-form'>
                <div className='wallet-right-form-1'>
                    <div className='wallet-right-form-1-box'>
                        <div className='wallet-right-form-1-box-1'>
                            <p>{t('wallet_10')}</p>
                            <div className={`wallet-right-form-1-box-1-label ${isToggled1 ? 'toggled' : ''}  ${darkname}`} onClick={toggleButton1}>
                                <div className="wallet-right-form-1-box-1-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToggled1
                        ?
                        <div className='wallet-right-form-3'>
                            <div className='wallet-right-form-3-1'>{t('wallet_10')}</div>
                            <div className='wallet-right-form-3-2'>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_84')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        1. {t('wallet_85')} <br />
                                        2. {t('wallet_86')} <br />
                                        3. {t('wallet_87')} <br />
                                        4. {t('wallet_88')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_89')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_90')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_91')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_92')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_93')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_94')} <br />
                                        1. {t('wallet_95')} <br />
                                        2. {t('wallet_96')} <br /> <br />

                                        {t('wallet_34')} <br />
                                        - {t('wallet_97')} <br />
                                        - {t('wallet_98')} <br />
                                        - {t('wallet_99')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_100')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_101')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_102')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} onClick={() => setTab(e => !e)}>
                            {t('exchange_2')}
                        </div>
                        {
                            tab
                                ?
                                <>
                                    <div className="header-modal" onClick={() => setTab(false)}></div>
                                    <div className={`wallet-right-form-4-3-box ${darkname}`}>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(1)); dispatch(changeWalletPage(1)); setTab(false) }}>{t('exchange_0')}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(0)); dispatch(changeWalletPage(0)); setTab(false) }}>{t('exchange_1')}</div>
                                        {/* <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(2)); dispatch(changeWalletPage(2)); setTab(false) }}>{t('exchange_2')}</div> */}
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_53')}</div>
                    <div className='wallet-right-form-4-2' onClick={() => { setModalbtn(true); setSearch("") }}>
                        <input type='text' className={`wallet-right-form-4-3 ${darkname}`} placeholder={t('wallet_54')} value={search} onChange={onChangeSearch} style={{ textTransform: "uppercase" }} />
                    </div>
                    {
                        modalbtn
                            ?
                            <div className='wallet-right-bottom1-modal'>
                                <div className='wallet-right-bottom1-modal-1'>
                                    <div className={`wallet-right-bottom1-modal-1-1 ${darkname}`}>
                                        <div className='wallet-right-bottom1-modal-1-1-tit'>{t('wallet_55')}
                                            <FontAwesomeIcon icon={faXmark} onClick={() => { setSearch(""); setModalbtn(false) }} /></div>
                                        <div className={`wallet-right-bottom1-modal-1-1-wrap ${darkname}`}>
                                            <div className={`wallet-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                <input placeholder={`${t('wallet_75')}`} onChange={onChangeSearch} value={search} style={{ textTransform: "uppercase" }} />
                                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                            </div>
                                            <div className='wallet-right-bottom1-modal-1-2'>
                                                {
                                                    BS_filterSymbol.map(function (a, i) {
                                                        let imgcheck = true
                                                        if (IMG && !IMG.includes(a.symbol)) {
                                                            imgcheck = false
                                                        }
                                                        return (
                                                            <div className='wallet-right-bottom1-modal-1-2-1' onClick={() => { setSelectSearch(a.symbol); setSearch(a.symbol); setCoinTotalUSDT(a.lastPrice); setModalbtn(false) }}>
                                                                <div className='wallet-right-bottom1-modal-1-2-2'>
                                                                    <div className='wallet-right-bottom1-modal-1-2-img'>
                                                                        <img src={imgcheck ? `/img/coin/${a.symbol}.png` : `/img/coin/origin.png`} />
                                                                    </div>
                                                                    <div className='wallet-right-bottom1-modal-1-2-name'>
                                                                        <p>{a.symbol}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                    {/* <br />
                    <span style={{ fontSize: "14px" }}>Easy Quick Choice</span>
                    <div className='wallet-right-form-4-4'>
                        <div className='wallet-right-form-4-5'>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("USDT"); setSelectSearch("USDT") }}>USDT</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("BTC"); setSelectSearch("BTC") }}>BTC</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("ETH"); setSelectSearch("ETH") }}>ETH</div>
                        </div>
                        <div className='wallet-right-form-4-5'>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("XRP"); setSelectSearch("XRP") }}>XRP</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("TRX"); setSelectSearch("TRX") }}>TRX</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("EOS"); setSelectSearch("EOS") }}>EOS</div>
                        </div>
                    </div> */}
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_57')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3-1 ${darkname}`}>
                            <select
                                value={selectedOption}
                                onChange={handleSelectChange}
                            >
                                <option value="">{t('wallet_58')}</option>
                                {
                                    currency.map((option, index) => (
                                        <option key={index} value={option.chain}>
                                            {option.chainType} ({option.chain})
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <p className='wallet-right-form-5'>
                    {t('wallet_103')}<br />
                    {t('wallet_104')}
                </p>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_105')}</div>
                    <div className='wallet-right-form-4-2'>
                        <input className={`wallet-right-form-4-3 ${darkname}`} value={address} onChange={onChangeAddress} placeholder='-' />
                    </div>
                    <div style={{ paddingTop: "12px" }}>
                        <label className='regist-box-left-form-3-label' >
                            <span className={checked ? 'regist-box-left-form-3-span1 checked' : 'regist-box-left-form-3-span1'} onClick={() => setChecked(checked => !checked)}></span>
                            <span className='regist-box-left-form-3-span2'>{t('wallet_242')}</span>
                        </label>
                    </div>
                </div>
                {
                    checked
                        ?
                        <div className={`wallet-right-form-4 ${darkname}`}>
                            <div className='wallet-right-form-4-1'>{t('wallet_243')}</div>
                            <div className='wallet-right-form-4-2'>
                                <div className={`wallet-right-form-4-3-1 ${darkname}`}>
                                    <select
                                        value={selectedOption_kor}
                                        onChange={handleSelectChange_KOR}
                                    >
                                        <option value="">{t('wallet_58')}</option>
                                        {
                                            currency_kor.map((option, index) => (
                                                <option key={index} value={option.vaspEntityId}>
                                                    {option.vaspName}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    selectedOption_kor != "" && selectedOption_kor != "others"
                        ?
                        <div className={`wallet-right-form-4 ${darkname}`}>
                            <div className='wallet-right-form-4-1'>{t('wallet_244')}</div>
                            <div className='wallet-right-form-4-2'>
                                <input className={`wallet-right-form-4-3 ${darkname}`} value={first_name} onChange={onChangeFirstName} placeholder={`${t('wallet_245')}`} />
                                <input className={`wallet-right-form-4-3 ${darkname}`} value={last_name} onChange={onChangeLastName} placeholder={`${t('wallet_246')}`} />
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_63')}</div>
                    <div className='wallet-right-form-4-2'>
                        <input className={`wallet-right-form-4-3 ${darkname}`} value={tag} onChange={onChangeTag} placeholder='-' />
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`} style={{ display: "flex", alignItems: "center" }}>
                    <div className='wallet-right-form-4-1'>{t('wallet_106')}</div>
                    <div className='wallet-right-form-4-1-1'>{changeNum(RoundDown(balanceAmount, 6))}
                        <span className='wallet-right-form-4-1-1-span'>{selectSearch}</span>
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_107')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`}>
                            <input className='wallet-right-form-4-3-input' value={amount} onChange={onChangeAmount} placeholder='0' />
                            <span className='wallet-right-form-4-3-span'>{selectSearch}</span>
                            <div className='wallet-right-form-4-3-btn' onClick={() => setAmount(RoundDown(balanceAmount, 6))}>{t('wallet_108')}</div>
                        </div>
                    </div>
                </div>
                {
                    Number(coinTotalUSDT) * Number(amount) >= 730
                        ?
                        <p className='wallet-right-form-5 red' style={{ opacity: "1" }}>
                            (!!) {t('wallet_241')}
                        </p>
                        :
                        ""
                }
                {
                    currency.map(function (a, i) {
                        if (a.chain == selectedOption) {
                            fee = a.withdrawFee
                            return (
                                <div className={`wallet-right-form-4 ${darkname}`}>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div className='wallet-right-form-4-1' style={{ fontWeight: "500", opacity: ".5" }}>{t('wallet_109')}</div>
                                        <div className='wallet-right-form-4-1-1'>{a.withdrawMin}</div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div className='wallet-right-form-4-1' style={{ fontWeight: "500", opacity: ".5" }}>{t('wallet_110')}</div>
                                        <div className='wallet-right-form-4-1-1' >{a.withdrawFee}</div>
                                    </div>
                                </div>
                            )
                        }
                    })
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_111')}</div>
                    <div className='wallet-right-form-4-2' style={{ opacity: ".5" }}>
                        {t('wallet_112')}
                    </div>
                    <div className='wallet-right-form-8'>
                        {code.map((digit, index) => (
                            <div className={`wallet-right-form-8-1 ${darkname}`}>
                                <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={digit}
                                    onChange={(e) => handleInputChange(e, index)}
                                    className={`wallet-right-form-8-2 ${darkname}`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='wallet-right-form-9'>
                    <div className={`wallet-right-form-9-1 ${darkname}`} onClick={() => Check_Modal()}>{t('wallet_113')}</div>
                </div>
                <div className='wallet-right-form-7'>
                    <div className='wallet-right-form-7-1'>{t('wallet_114')}</div>
                    <div className='wallet-right-form-7-2'><FontAwesomeIcon icon={faRotate} onClick={() => setResetHistory(e => !e)} /></div>
                    <div className='wallet-right-form-7-3'>
                        <div className={`wallet-right-form-7-3-top ${darkname}`}>
                            <div className='wallet-right-form-7-3-top-1'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_68')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_69')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-1'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_70')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_71')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-3'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_72')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_73')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-4 wallet-display-none-800'>{t('wallet_74')}</div>
                        </div>
                        <div className='wallet-right-form-7-3-bottom'>
                            {
                                whistory && whistory.length === 0
                                    ?
                                    <div className='history-right-bottom-4-bottom-1'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>{t('message_0')}</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            filter_whistory.map(function (a, i) {
                                                return (
                                                    <WithdrawItem key={i} a={a} darkname={darkname} />
                                                )
                                            })
                                        }
                                        <Page initialData={whistory} page={page} setPage={setPage} maxFirstPage={10} />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

function WithdrawItem({ a, darkname }) {
    const { t } = useTranslation();
    const date = a.create_time.split('T');
    const date1 = date[1].split('.000Z')

    const [mouseState, setMouseState] = useState(false)
    const [mouseState1, setMouseState1] = useState(false)

    const onMouseEnter = () => {
        setMouseState(true)
    }
    const onMouseLeave = () => {
        setMouseState(false)
    }
    const onMouseEnter1 = () => {
        setMouseState1(true)
    }
    const onMouseLeave1 = () => {
        setMouseState1(false)
    }

    return (
        <div className={`wallet-right-form-7-3-bottom-1 ${darkname}`}>
            <div className='wallet-right-form-7-3-bottom-2'>
                <div className='wallet-right-form-7-3-bottom-3'>{date[0]} {date1[0]}</div>
                <div className='wallet-right-form-7-3-bottom-3'>{t('exchange_2')}</div>
            </div>
            <div className='wallet-right-form-7-3-bottom-2'>
                <div className='wallet-right-form-7-3-bottom-3' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ cursor: "pointer" }}>
                    {a.tx_id}
                    {
                        mouseState
                            ?
                            <div className={`wallet-right-form-7-3-bottom-3-1 ${darkname}`}>{a.tx_id}</div>
                            :
                            ""
                    }
                </div>
                <div className='wallet-right-form-7-3-bottom-3'>{a.amount}</div>
            </div>
            <div className='wallet-right-form-7-3-bottom-4'>
                <div className='wallet-right-form-7-3-bottom-3'>{a.coin}</div>
                <div className='wallet-right-form-7-3-bottom-3'>{a.chain}</div>
            </div>
            <div className='wallet-right-form-7-3-top-5 wallet-display-none-800' onMouseEnter={onMouseEnter1} onMouseLeave={onMouseLeave1} style={{ cursor: "pointer" }}>
                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{a.status}</div>
                {
                    mouseState1
                        ?
                        <div className={`wallet-right-form-7-3-bottom-3-1 ${darkname}`}>{a.status}</div>
                        :
                        ""
                }
            </div>
        </div>
    )
}

export default Bybit_Withdrawal;
import './css/wallet.css'
import axios from 'axios';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleCheck, faEyeSlash, faEye, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux'
import { checkLogin, check_Error } from '../store';
import { useTranslation } from "react-i18next";

function ForgotPassword_B() {
    const { t } = useTranslation();
    let dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    let dark = useSelector((state) => { return state.Darkmode.value });
    
    const darkname = dark ? 'dark' : "";

    const [btn, setBtn] = useState(location.state === null ? 0 : location.state.btn);
    const [email, setEmail] = useState(location.state === null ? 0 : location.state.email);
    const [phone, setPhone] = useState(location.state === null ? 0 : location.state.phone);
    const [tel, setTel] = useState(location.state === null ? 0 : location.state.tel)
    const [password, setPassword] = useState("")
    const [passwordcheck, setPasswordcheck] = useState("")
    const [certifyNum, setCertifyNum] = useState("")

    const [eye, setEye] = useState(false);
    const [checkpassword, setCheckPassword] = useState(false)
    const [checkpasswordcheck, setCheckPasswordcheck] = useState(false)
    const [passwordOK, setPasswordOK] = useState(false);

    const [minCharacters, setMinCharacters] = useState(false);
    const [leastLetter, setLeastLetter] = useState(false);
    const [upperLetter, setUpperLetter] = useState(false);
    const [leastNumber, setleastNumber] = useState(false);
    const [specialCharacter, setspecialCharacter] = useState(false);

    const passwordRegEx = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?'":{}|<>`;/])[A-Za-z\d!@#$%^&*(),.?'":{}|<>`;/]{6,15}$/;
    const regex = /[!@#$%^&*(),.?'":{}|<>`;/]/;
    const regex1 = /[A-Z]/;
    const regex3 = /[a-z]/;
    const regex4 = /[0-9]/;


    useEffect(() => {
        if (location.state === null) {
            navigate('/')
        }
        else {
            setBtn(location.state.btn)
            setEmail(location.state.email)
            setPhone(location.state.phone)
            setTel(location.state.tel)
        }
    }, [location])

    const onCertifyNum = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^0-9]/g, "")
        setCertifyNum(onlyNumber)
    }

    const onChangePassword = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9!@#$%^&*()]/g, "")
        setPassword(onlyNumber)

        if (onlyNumber && onlyNumber.length >= 6 && onlyNumber.length <= 15) setMinCharacters(true)
        else setMinCharacters(false)

        if (regex3.test(onlyNumber)) setLeastLetter(true)
        else setLeastLetter(false)

        if (regex1.test(onlyNumber)) setUpperLetter(true)
        else setUpperLetter(false)

        if (regex4.test(onlyNumber)) setleastNumber(true)
        else setleastNumber(false)

        if (regex.test(onlyNumber)) setspecialCharacter(true)
        else setspecialCharacter(false)

        passwordCheck(onlyNumber)

        if (onlyNumber && onlyNumber === passwordcheck && onlyNumber.length > 0) setCheckPasswordcheck(true)
        else setCheckPasswordcheck(false)
    }

    const onChangePasswordCheck = (e) => {
        const { value } = e.target
        const onlyNumber = value.replace(/[^a-zA-Z0-9!@#$%^&*()]/g, "")
        setPasswordcheck(onlyNumber)

        if (onlyNumber && onlyNumber === password && onlyNumber.length > 0) setCheckPasswordcheck(true)
        else setCheckPasswordcheck(false)
    }

    const passwordCheck = (username) => {
        if (passwordRegEx.test(username)) setCheckPassword(true)
        else setCheckPassword(false)
    }

    const passwordMouseEnter = () => {
        setPasswordOK(true);
    };

    const passwordMouseLeave = () => {
        setPasswordOK(false);
    };

    const onSendCertify = async () => {
        await axios.get(process.env.REACT_APP_API + "/api/v1/account/user/reset/code", {
            params: {
                email: btn === 0 ? email : "",
                countryCode: btn === 0 ? "" : phone,
                mobile: btn === 0 ? "" : tel
            }
        }).then((response) => {
            alert(response?.data?.msg)
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 153, "msg": error }))
        })
    }

    const onSubnitCheck = () => {
        if (!checkpassword) alert(`${t('message_23')}`)
        else if (!checkpasswordcheck) alert(`${t('message_23')}`)
        else if (certifyNum.length != 6) alert(`${t('message_30')}`)
        else onSubmit()
    }

    const onSubmit = async () => {
        await axios.post(process.env.REACT_APP_API + "/api/v1/account/user/reset/code", {
            email: btn === 0 ? email : "",
            mobile: btn === 0 ? "" : tel,
            code: certifyNum,
            password: passwordcheck,
        }).then((response) => {
            if (response?.data?.code === 1000) {
                alert(response?.data?.msg)
                navigate('/login')
            }
            else {
                alert(response?.data?.msg)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 154, "msg": error }))
        })
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            onSubmit()
        }
    };

    const inputRef = useRef(null);
    useEffect(() => {
        document.title = `Bitket│One-Stop Trading Platform - Crypto Trading Universe`;
        inputRef.current.focus();
    }, []);

    return (
        <div className={`otp ${darkname}`}>
            <div className={`otp-1 ${darkname}`}>
                <div className='otp-2'>
                    <div className='otp-h4'>{t('password_1')}</div>
                    <div className='otp-2-wrap'>
                        <p className='otp-2-wrap-p'>
                            <span style={{ opacity: ".5" }}>{btn === 0 ? `Email: ${email}` : `Mobile: ${tel}`}</span>
                            <br />
                        </p>
                        <br />

                        <div style={{ opacity: ".6", fontSize: "13px", paddingBottom: "3px" }}>{t('regist_7')}</div>
                        <div className='regist-box-left-form-1-1' onMouseEnter={passwordMouseEnter} onMouseLeave={passwordMouseLeave} style={{ fontSize: "14px" }}>
                            <div className={`regist-box-left-form-1-tit ${darkname}`}>
                                {
                                    checkpassword
                                        ?
                                        <div className='regist-box-left-form-1-ico-1'></div>
                                        :
                                        <div className='regist-box-left-form-1-ico'></div>
                                }
                                <input className='regist-box-left-form-1-input' type={eye ? 'text' : 'password'} value={password} onChange={onChangePassword} placeholder={`${t('regist_7')}`} autoComplete='new-password' ref={inputRef} />
                                {
                                    passwordOK
                                        ?
                                        <div className={`signup_password_modal ${darkname}`}>
                                            <div className="signup_password_condition">
                                                <FontAwesomeIcon icon={faCheck} className={minCharacters ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                <div className="signup_password_condition_txt">{t('regist_8')}</div>
                                            </div>
                                            <div className="signup_password_condition">
                                                <FontAwesomeIcon icon={faCheck} className={leastNumber ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                <div className="signup_password_condition_txt">{t('regist_9')}</div>
                                            </div>
                                            <div className="signup_password_condition">
                                                <FontAwesomeIcon icon={faCheck} className={leastLetter ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                <div className="signup_password_condition_txt">{t('regist_10')}</div>
                                            </div>
                                            <div className="signup_password_condition">
                                                <FontAwesomeIcon icon={faCheck} className={upperLetter ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                <div className="signup_password_condition_txt">{t('regist_11')}</div>
                                            </div>
                                            <div className="signup_password_condition">
                                                <FontAwesomeIcon icon={faCheck} className={specialCharacter ? 'signup_password_condition_check on' : 'signup_password_condition_check'} />
                                                <div className="signup_password_condition_txt">
                                                    {t('regist_12')}<br />
                                                    (!@#$%^&*())
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                                {
                                    eye
                                        ?
                                        <FontAwesomeIcon icon={faEye} className='regist-eye' onClick={() => setEye(eye => !eye)} />
                                        :
                                        <FontAwesomeIcon icon={faEyeSlash} className='regist-eye' onClick={() => setEye(eye => !eye)} />
                                }
                            </div>
                        </div>
                        <div style={{ opacity: ".6", fontSize: "13px", paddingBottom: "3px" }}>{t('regist_13')}</div>
                        <div className='regist-box-left-form-1-1'>
                            <div className={`regist-box-left-form-1-tit ${darkname}`} style={{ fontSize: "14px" }}>
                                {
                                    checkpasswordcheck
                                        ?
                                        <div className='regist-box-left-form-1-ico-1'></div>
                                        :
                                        <div className='regist-box-left-form-1-ico'></div>
                                }
                                <input className='regist-box-left-form-1-input' type={eye ? 'text' : 'password'} value={passwordcheck} onChange={onChangePasswordCheck} placeholder={`${t('regist_13')}`} />
                                {
                                    checkpasswordcheck
                                        ?
                                        <FontAwesomeIcon icon={faCircleCheck} style={{ fontSize: "16px", color: "green" }} />
                                        :
                                        ""
                                }
                                {
                                    eye
                                        ?
                                        <FontAwesomeIcon icon={faEye} className='regist-eye' onClick={() => setEye(eye => !eye)} />
                                        :
                                        <FontAwesomeIcon icon={faEyeSlash} className='regist-eye' onClick={() => setEye(eye => !eye)} />
                                }
                            </div>
                        </div>

                        <div style={{ opacity: ".6", fontSize: "13px", paddingBottom: "3px" }}>{btn === 0 ? `${t('otp_1')}` : `${t('otp_2')}`}</div>
                        <div className={`otp-2-3 ${darkname}`} style={{ marginBottom: "10px" }}>
                            <input type='text' placeholder={`${t('otp_7')}`} maxLength={6} value={certifyNum} onKeyDown={handleKeyPress} onChange={onCertifyNum} />
                        </div>
                        <div className='otp-2-4'>
                            <div className='otp-2-4-1'>{t('otp_8')}</div>
                            <p>|</p>
                            <div className='otp-2-4-2' onClick={() => onSendCertify()}>{t('otp_9')}</div>
                        </div>
                    </div>
                    <div className='otp-2-5'>
                        <div className='otp-2-5-btn' onClick={() => onSubnitCheck()}>{t('otp_10')}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword_B;
import "./css/spot.css";
import React, { useEffect, useRef, useState, memo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkLogin } from "../store";
import base64 from 'base-64';
import { he } from "date-fns/locale";

let tvScriptLoadingPromise;

function Chart({ exchange, market, windowtype }) {
  let dark = useSelector((state) => { return state.Darkmode.value; });
  let Languages = useSelector((state) => { return state.Languages.value; });

  let { symbol } = useParams();
  let arr = [];
  if (exchange === "okx") {
    arr = symbol.split("-");
  } else {
    arr = symbol.split("USDT");
  }

  let lang = Languages.split("-");

  const onLoadScriptRef = useRef();

  useEffect(
    () => {
      onLoadScriptRef.current = createWidget;

      if (!tvScriptLoadingPromise) {
        tvScriptLoadingPromise = new Promise((resolve) => {
          const script = document.createElement('script');
          script.id = 'tradingview-widget-loading-script';
          script.src = 'https://s3.tradingview.com/tv.js';
          script.type = 'text/javascript';
          script.onload = resolve;

          document.head.appendChild(script);
        });
      }

      tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

      return () => onLoadScriptRef.current = null;

      function createWidget() {
        if (document.getElementById('tradingview_b6728') && 'TradingView' in window) {
          new window.TradingView.widget({
            autosize: true,
            symbol: `${exchange}:${arr[0]}USDT${market == "futures" ? ".P" : ""}`,
            interval: "D",
            timezone: "Etc/UTC",
            theme: dark ? "dark" : "light",
            style: "1",
            locale: "en",
            toolbar_bg: "#f1f3f6",
            enable_publishing: false,
            allow_symbol_change: true,
            container_id: "tradingview_b6728",
            fullscreen: false,
            autosize: true,
          });
        }
      }
    }, [dark, windowtype, symbol]);

  return (
    <div className='tradingview-widget-container'>
      <div id='tradingview_b6728' style={{ height: windowtype ? "610px" : "400px" }} />
    </div>
  );
}

export default Chart;
import '../../css/wallet.css'
import axios from 'axios';
import Page from '../../Page';
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotate, faCircleArrowRight, faEllipsis, faXmark, faMagnifyingGlass, faCircleArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from 'react-redux'
import { checkSelectType, checkLogin, addImg, changeWalletPage, check_Error } from '../../../store';
import IMG from "../../img"
import { QRCodeCanvas } from 'qrcode.react';
import { CopyToClipboard } from "react-copy-to-clipboard/src";
import { useTranslation } from "react-i18next";
import { findSamllest, MakeNumber, changeNum, RoundDown, changeTime, padZero, makeSamllest, changeUnits, UTCTimestamp } from "../../common/function"
import { filter } from 'lodash';


function Bybit_Deposit({ darkname, walletData, fastCoin }) {
    const { t } = useTranslation();
    const CryptoJS = require('crypto-js');

    let dispatch = useDispatch();
    let By_apiKey = useSelector((state) => { return state.By_apiKey })

    const [isToggled1, setIsToggled1] = useState(false);
    const [search, setSearch] = useState(fastCoin)
    const [selectSearch, setSelectSearch] = useState(fastCoin)
    const [BselectCoin, SetBSelectCoin] = useState([])
    const [selectedOption, setSelectedOption] = useState("");
    const [modalbtn, setModalbtn] = useState(false)
    const [currency, setCurrency] = useState([])
    const [dhistory, setDhistory] = useState([])
    const [tab, setTab] = useState(false)
    const [resetHistory, setResetHistory] = useState(false)

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const toggleButton1 = () => {
        // setIsToggled1(!isToggled1);
    };

    const onChangeSearch = (e) => {
        setSearch(e.target.value)
    }

    let BS_filterSymbol = []
    if (walletData) {
        BS_filterSymbol = walletData.filter((p) => {
            return (p.symbol.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
        })
    }


    const [by_timestamp, SetBy_timestamp] = useState("")
    const [by_timestamp1, SetBy_timestamp1] = useState("")

    useEffect(() => {
        By_TimeStamp()
    }, [selectSearch])

    useEffect(() => {
        By_TimeStamp1()
    }, [selectedOption])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null && by_timestamp?.length > 0 && By_apiKey?.S_api?.length > 0) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            DepositCurrency(token)
        }
    }, [by_timestamp, By_apiKey])

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null && by_timestamp1?.length > 0) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            B_DepositLink(token)
        }
    }, [by_timestamp1])

    const By_TimeStamp = async () => {
        await axios.get(`https://api.bybit.com/v5/market/time`, {
        }).then((response) => {
            let tmp = response.data
            if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                By_TimeStamp()
            }
            else {
                SetBy_timestamp(tmp.time.toString())
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const By_TimeStamp1 = async () => {
        await axios.get(`https://api.bybit.com/v5/market/time`, {
        }).then((response) => {
            let tmp = response.data
            if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                By_TimeStamp1()
            }
            else {
                SetBy_timestamp1(tmp.time.toString())
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    useEffect(() => {
        const objString = localStorage.getItem('token');
        if (objString != null) {
            const obj = JSON.parse(objString);
            let token = obj.value;

            DepositHistory(token)
        }
    }, [resetHistory])

    const B_DepositLink = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1` + '/wallet/bybit/deposit/address', {
            params: {
                coin: selectSearch,
                chainType: selectedOption
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }).then((response) => {
            let tmp = response.data
            if (tmp.code === 1000) {
                if (selectedOption.length > 0) {
                    SetBSelectCoin(tmp?.data?.result?.chains)
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const DepositCurrency = async () => {
        const paramStr = `${by_timestamp}${By_apiKey.S_api}${10000}coin=${selectSearch}`
        await axios.get(`https://api.bybit.com/v5/asset/coin/query-info?coin=${selectSearch}`, {
            headers: {
                "X-BAPI-SIGN": CryptoJS.HmacSHA256(paramStr, By_apiKey.S_sec).toString(CryptoJS.enc.Hex),
                "X-BAPI-API-KEY": By_apiKey.S_api,
                "X-BAPI-TIMESTAMP": by_timestamp,
                "X-BAPI-RECV-WINDOW": 10000
            }
        }).then((response) => {
            let tmp = response.data
            if (tmp.retCode == 10004 || tmp.retCode == 10001) {
                By_TimeStamp()
            }
            else {
                if (selectSearch.length > 0) {
                    setCurrency(tmp?.result?.rows[0].chains)
                }
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const DepositHistory = async (token) => {
        await axios.get(process.env.REACT_APP_API + `/api/v1` + '/wallet/bybit/deposit/history', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response) => {
            let tmp = response.data
            if (tmp?.code === 1000) {
                setDhistory(tmp.data)
            }
        }).catch((error) => {
            dispatch(check_Error({ "bol": true, "no": 82, "msg": error }))
        })
    }

    const [page, setPage] = useState(1)
    let filter_dhistory = dhistory.slice((page - 1) * 10, (page) * 10)


    return (
        <div className='history-right'>
            <div className='history-right-top'>
                <div className='history-right-top-1'>{t('header_31')}</div>
                <p className='history-right-top-2'>{t('header_32')}</p>
            </div>
            <div className='wallet-right-form'>
                <div className='wallet-right-form-1'>
                    <div className='wallet-right-form-1-box'>
                        <div className='wallet-right-form-1-box-1'>
                            <p>{t('wallet_10')}</p>
                            <div className={`wallet-right-form-1-box-1-label ${isToggled1 ? 'toggled' : ''} ${darkname}`} onClick={toggleButton1}>
                                <div className="wallet-right-form-1-box-1-btn slider"></div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isToggled1
                        ?
                        <div className='wallet-right-form-3'>
                            <div className='wallet-right-form-3-1'>{t('wallet_10')}</div>
                            <div className='wallet-right-form-3-2'>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_20')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        1. {t('wallet_21')}<br />
                                        2. {t('wallet_22')}<br />
                                        3. {t('wallet_23')}<br />
                                        4. {t('wallet_24')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_26')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_27')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_28')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_29')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_30')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_31')} <br />
                                        1. {t('wallet_32')}<br />
                                        2. {t('wallet_33')}<br /><br />

                                        {t('wallet_34')}<br />
                                        - {t('wallet_35')}<br />
                                        - {t('wallet_36')}<br />
                                        - {t('wallet_37')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_38')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_39')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_40')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_41')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_42')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_43')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_44')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_45')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_46')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_47')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_48')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_49')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div className={`wallet-right-form-3-2-1 ${darkname}`}>
                                    <p className='wallet-right-form-3-2-2'>{t('wallet_50')}</p>
                                    <p className='wallet-right-form-3-2-3'>
                                        {t('wallet_51')}
                                    </p>
                                    <div className='wallet-right-form-3-2-4'>
                                        <div className='wallet-right-form-3-2-5'>
                                            {t('wallet_25')}
                                            <svg viewBox="0 0 9.87 9.74">
                                                <path class="st0" d="M9,8.99l0.12-8.24L0.75,0.86"></path>
                                                <path class="st0" d="M8.99,0.86L0.75,8.99"></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        ""
                }
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_52')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3 ${darkname}`} onClick={() => setTab(e => !e)}>{t('exchange_2')}</div>
                        {
                            tab
                                ?
                                <>
                                    <div className="header-modal" onClick={() => setTab(false)}></div>
                                    <div className={`wallet-right-form-4-3-box ${darkname}`}>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(1)); dispatch(changeWalletPage(1)); setTab(false) }}>{t('exchange_0')}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(0)); dispatch(changeWalletPage(0)); setTab(false) }}>{t('exchange_1')}</div>
                                        <div className={`wallet-right-form-4-3-box-1 ${darkname}`} onClick={() => { dispatch(checkSelectType(2)); dispatch(changeWalletPage(2)); setTab(false) }}>{t('exchange_2')}</div>
                                    </div>
                                </>
                                :
                                ""
                        }
                    </div>

                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_53')}</div>
                    <div className='wallet-right-form-4-2' onClick={() => { setModalbtn(true); setSearch("") }}>
                        <input type='text' className={`wallet-right-form-4-3 ${darkname}`} placeholder={`${t('wallet_54')}`} value={search} onChange={onChangeSearch} style={{ textTransform: "uppercase" }} />
                    </div>
                    {
                        modalbtn
                            ?
                            <div className='wallet-right-bottom1-modal'>
                                <div className='wallet-right-bottom1-modal-1'>
                                    <div className={`wallet-right-bottom1-modal-1-1 ${darkname}`}>
                                        <div className='wallet-right-bottom1-modal-1-1-tit'>{t('wallet_55')}
                                            <FontAwesomeIcon icon={faXmark} onClick={() => { setSearch(""); setModalbtn(false) }} /></div>
                                        <div className={`wallet-right-bottom1-modal-1-1-wrap ${darkname}`}>
                                            <div className={`wallet-right-bottom1-modal-1-1-txt ${darkname}`}>
                                                <input placeholder={`${t('wallet_75')}`} onChange={onChangeSearch} value={search} style={{ textTransform: "uppercase" }} />
                                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                            </div>
                                            <div className='wallet-right-bottom1-modal-1-2'>
                                                {
                                                    BS_filterSymbol.map(function (a, i) {
                                                        let imgcheck = true
                                                        if (IMG && !IMG.includes(a.symbol)) {
                                                            imgcheck = false
                                                        }

                                                        return (
                                                            <div className='wallet-right-bottom1-modal-1-2-1' onClick={() => { setSelectSearch(a.symbol); setSearch(a.symbol); setModalbtn(false) }} key={i}>
                                                                <div className='wallet-right-bottom1-modal-1-2-2'>
                                                                    <div className='wallet-right-bottom1-modal-1-2-img'>
                                                                        <img src={imgcheck ? `/img/coin/${a.symbol}.png` : `/img/coin/origin.png`} />
                                                                    </div>
                                                                    <div className='wallet-right-bottom1-modal-1-2-name'>
                                                                        <p>{a.symbol}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            ""
                    }
                    {/* <br />
                    <span style={{ fontSize: "14px" }}>Easy Quick Choice</span>
                    <div className='wallet-right-form-4-4'>
                        <div className='wallet-right-form-4-5'>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("USDT"); setSelectSearch("USDT") }}>USDT</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("BTC"); setSelectSearch("BTC") }}>BTC</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("ETH"); setSelectSearch("ETH") }}>ETH</div>
                        </div>
                        <div className='wallet-right-form-4-5'>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("XRP"); setSelectSearch("XRP") }}>XRP</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("TRX"); setSelectSearch("TRX") }}>TRX</div>
                            <div className={`wallet-right-form-4-6 ${darkname}`} onClick={() => { setSearch("EOS"); setSelectSearch("EOS") }}>EOS</div>
                        </div>
                    </div> */}
                </div>
                <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('wallet_57')}</div>
                    <div className='wallet-right-form-4-2'>
                        <div className={`wallet-right-form-4-3-1 ${darkname}`}>
                            <select
                                value={selectedOption}
                                onChange={handleSelectChange}
                            >
                                <option value="">{t('wallet_58')}</option>
                                {
                                    currency.map((option, index) => (
                                        <option key={index} value={option.chain}>
                                            {option.chainType} ({option.chain})
                                        </option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <p className='wallet-right-form-5'>
                    {t('wallet_59')}<br />
                    {t('wallet_60')}
                </p>
                <div className='wallet-right-form-6'>
                    <div className={`wallet-right-form-4 ${darkname}`}>
                        <div className='wallet-right-form-4-1'>{t('wallet_61')}</div>
                        {

                            BselectCoin.addressDeposit
                                ?
                                <>
                                    <div className='wallet-right-form-4-7'>
                                        <div className='wallet-right-form-4-7-1'>
                                            <QRCodeCanvas value={BselectCoin.addressDeposit} />
                                        </div>
                                    </div>
                                    <div className='wallet-right-form-4-8'>
                                        <div>{BselectCoin.addressDeposit}</div>
                                        <CopyToClipboard text={BselectCoin.addressDeposit}>
                                            <text className='wallet-right-form-4-8-1' onClick={() => alert(`${t('wallet_62')}`)}>{t('wallet_62')}</text>
                                        </CopyToClipboard>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='wallet-right-form-4-7'>
                                        <div className='wallet-right-form-4-7-1'>
                                            <div style={{ height: "128px" }}></div>
                                        </div>
                                    </div>
                                    <div className='wallet-right-form-4-8'>
                                        <div className='wallet-right-form-4-8-1'>{t('wallet_62')}</div>
                                    </div>
                                </>
                        }
                    </div>
                    <div className={`wallet-right-form-4 ${darkname}`}>
                        <div className='wallet-right-form-4-1'>{t('wallet_63')}</div>
                        {

                            BselectCoin.tagDeposit
                                ?
                                <>
                                    <div className='wallet-right-form-4-7'>
                                        <div className='wallet-right-form-4-7-1'>
                                            <QRCodeCanvas value={BselectCoin.tagDeposit} />
                                        </div>
                                    </div>
                                    <div className='wallet-right-form-4-8'>
                                        <div>{BselectCoin.tagDeposit}</div>
                                        <CopyToClipboard text={BselectCoin.tagDeposit}>
                                            <text className='wallet-right-form-4-8-1' onClick={() => alert(`${t('wallet_62')}`)}>{t('wallet_62')}</text>
                                        </CopyToClipboard>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='wallet-right-form-4-7-no'>
                                        <div className='wallet-right-form-4-7-1-no'>
                                            <div>{t('wallet_64')}</div>
                                        </div>
                                    </div>
                                    <div className='wallet-right-form-4-8'>
                                        <div className='wallet-right-form-4-8-1'>{t('wallet_62')}</div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
                <div className={`wallet-right-form-4 ${darkname}`} style={{ display: "flex", alignItems: "center" }}>
                    <div className='wallet-right-form-4-1'>{t('wallet_65')}</div>
                    {
                        currency.map(function (a, i) {
                            if (a.chain == selectedOption) {
                                return (
                                    <div>{a.confirmation}</div>
                                )
                            }
                        })
                    }
                </div>
                {/* <div className={`wallet-right-form-4 ${darkname}`}>
                    <div className='wallet-right-form-4-1'>{t('')}</div>
                    <div className='wallet-right-form-4-2' style={{ opacity: ".5" }}>
                        {
                            currency.map(function (a, i) {
                                if (a.network == selectedOption) {
                                    return (
                                        <div>{a.specialWithdrawTips}</div>
                                    )
                                }
                            })
                        }
                    </div>
                </div> */}
                <div className='wallet-right-form-7'>
                    <div className='wallet-right-form-7-1'>{t('wallet_67')}</div>
                    <div className='wallet-right-form-7-2'><FontAwesomeIcon icon={faRotate} onClick={() => setResetHistory(e => !e)} /></div>
                    <div className='wallet-right-form-7-3'>
                        <div className={`wallet-right-form-7-3-top ${darkname}`}>
                            <div className='wallet-right-form-7-3-top-1'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_68')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_69')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-1'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_70')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_71')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-3'>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_72')}</div>
                                <div className='wallet-right-form-7-3-top-2'>{t('wallet_73')}</div>
                            </div>
                            <div className='wallet-right-form-7-3-top-4 wallet-display-none-800'>{t('wallet_74')}</div>
                        </div>
                        <div className='wallet-right-form-7-3-bottom'>
                            {
                                dhistory && dhistory.length === 0
                                    ?
                                    <div className='history-right-bottom-4-bottom-1'>
                                        <img src='/img/img_nofound.png'></img>
                                        <img src='/img/img_nofound_dark.svg' style={{ display: "none" }}></img>
                                        <p>{t('message_0')}</p>
                                    </div>
                                    :
                                    <>
                                        {
                                            filter_dhistory.map(function (a, i) {
                                                return (
                                                    <DepositItem key={i} a={a} darkname={darkname} />
                                                )
                                            })
                                        }
                                        <Page initialData={dhistory} page={page} setPage={setPage} maxFirstPage={10} />
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

let state = ""
function DepositItem({ a, darkname }) {
    const { t } = useTranslation();

    const [mouseState, setMouseState] = useState(false)
    const [mouseState1, setMouseState1] = useState(false)

    const onMouseEnter = () => {
        setMouseState(true)
    }
    const onMouseLeave = () => {
        setMouseState(false)
    }
    const onMouseEnter1 = () => {
        setMouseState1(true)
    }
    const onMouseLeave1 = () => {
        setMouseState1(false)
    }

    if (a.status == "0") {
        state = "unknown"
    } else if (a.status == "1") {
        state = "toBeConfirmed"
    } else if (a.status == "2") {
        state = "processing"
    } else if (a.status == "3") {
        state = "success"
    } else if (a.status == "4") {
        state = "deposit failed"
    } else if (a.status == "10011") {
        state = "pending to be credited to funding pool"
    } else if (a.status == "10012") {
        state = "Credited to funding pool successfully"
    }

    return (
        <div className={`wallet-right-form-7-3-bottom-1 ${darkname}`}>
            <div className='wallet-right-form-7-3-bottom-2'>
                <div className='wallet-right-form-7-3-bottom-3'>{a.time}</div>
                <div className='wallet-right-form-7-3-bottom-3'>{t('exchange_2')}</div>
            </div>
            <div className='wallet-right-form-7-3-bottom-2'>
                <div className='wallet-right-form-7-3-bottom-3' onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ cursor: "pointer" }}>
                    {a.txId}
                    {
                        mouseState
                            ?
                            <div className={`wallet-right-form-7-3-bottom-3-1 ${darkname}`}>{a.txId}</div>
                            :
                            ""
                    }
                </div>
                <div className='wallet-right-form-7-3-bottom-3'>{a.amount}</div>
            </div>
            <div className='wallet-right-form-7-3-bottom-4'>
                <div className='wallet-right-form-7-3-bottom-3'>{a.coin}</div>
                <div className='wallet-right-form-7-3-bottom-3'>{a.chain}</div>
            </div>
            <div className='wallet-right-form-7-3-top-5 wallet-display-none-800' onMouseEnter={onMouseEnter1} onMouseLeave={onMouseLeave1} style={{ cursor: "pointer" }}>
                <div style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{state}</div>
                {
                    mouseState1
                        ?
                        <div className={`wallet-right-form-7-3-bottom-3-1 ${darkname}`}>{state}</div>
                        :
                        ""
                }
            </div>
        </div>
    )
}

export default Bybit_Deposit;